import React from 'react'
import { Grid, Popover, Typography } from '@mui/material';

const MenuPanel = (props) => {
    const panel = props.panel;
    if (panel) {
        console.log(`panel: ${JSON.stringify(panel)}`)
        const sections = panel.sections;
        const pushes = panel.pushes;
        const links = panel.links;
        console.log(`no of sections: ${sections ? sections.length : 0}`);
        console.log(`no of pushes: ${pushes ? pushes.length : 0}`);
        console.log(`no of links: ${links ? links.length : 0}`);
    }
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'panel-popover' : undefined;
    return (
        <Popover
            id={props.id}
            open={props.open}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'left',
            }}
        >
            <Grid container spacing={2} >
                <Grid item xs={9}>
                    <Typography sx={{ p: 2 }}>The content of the sections.</Typography>
                </Grid>
                <Grid item xs={3}>
                    <Typography sx={{ p: 2 }}>The content of the pushes.</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography sx={{ p: 2 }}>The content of the extra links.</Typography>
                </Grid>
            </Grid>
        </Popover>
    )
}

export default MenuPanel
