import { useState, form, Fragment } from 'react';
import { Paper, Box, Grid, Button, Typography, } from '@mui/material';
import { InputLabel, Select, MenuItem } from '@mui/material';
import FormLabel from "@mui/material/FormLabel";
import FormControl from "@mui/material/FormControl";
import { FormControlUnstyled } from "@mui/material";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormHelperText from "@mui/material/FormHelperText";
import InputAdornment from '@mui/material/InputAdornment';
import { TextField, RadioGroup, Radio, Checkbox, Switch } from '@mui/material';
import OutlinedInput from '@mui/material/OutlinedInput';
import IconButton from '@mui/material/IconButton';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import CallingSelector from './CallingSelector';
import DateSelector from './DateSelector';
import BorderedTextLabel from './BorderedTextLabel';

const Person = (props) => {
  const [suffix, setSuffix] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [dateCalled, setDateCalled] = useState('');
  const [age, setAge] = useState("");

  //password mgmt
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };


  const handleSuffixChange = (event) => {
    setSuffix(event.target.value);
  };

  const handleCatChange = (event) => {
    setAge(event.target.value);
  };

  const suffixes = [
    "",
    "Jr",
    "Sr",
    "I",
    "II",
    "III"
  ];

  const firstLogin = '03/14/2023 14:23:38';
  const lastLogin = '08/23/2023 08:03:56';
  const dateEmailConfirmed = '03/14/2023 14:28:56';

  return (
    <>
      <form>
        <Paper p={2} m={2} elevation={4} sx={{ minWidth: 300 }}>
          <FormLabel sx={{ fontSize: '2rem' }}>Person</FormLabel>
          <Grid container>

          </Grid>
        </Paper>
      </form>
      <Fragment>
        <Paper elevation={3} sx={{ marginRight: "15%", marginLeft: "15%" }}>
          <Box sx={{ padding: 5 }}>
            <Typography variant="h6" gutterBottom sx={{ color: '#000', paddingBottom: 5 }}>
              Person
            </Typography>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={3}>
                <TextField
                  required
                  id="firstname"
                  name="firstname"
                  label="First Name"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  sx={{ color: '#000' }}
                />
              </Grid>
              <Grid item xs={12} sm={3}>
                <TextField
                  id="middlename"
                  name="middlename"
                  label="Middle Name"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={4}>
                <TextField
                  required
                  id="lastname"
                  name="lastname"
                  label="Last Name"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={2}>
                <FormControl fullWidth size="small">
                  <InputLabel id="suffix-simple-select-label" sx={{ color: '#000' }}>Suffix</InputLabel>
                  <Select
                    labelId="suffix-simple-select-label"
                    id="suffix"
                    value={suffix}
                    label="Suffix"
                    onChange={handleSuffixChange}
                  >
                    {suffixes.map((item, i) => (
                      <MenuItem key={i} value={item}>{item}</MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  required
                  id="email"
                  name="email"
                  label="Email"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                  sx={{ color: '#000' }}
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <TextField
                  id="phone"
                  name="phone"
                  label="Phone"
                  fullWidth
                  size="small"
                  autoComplete="off"
                  variant="outlined"
                />
              </Grid>
              <Grid item xs={12} sm={6}>
                <FormControl sx={{ width: '35ch' }} size="small" variant="outlined">
                  <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                  <OutlinedInput
                    id="outlined-adornment-password"
                    type={showPassword ? 'text' : 'password'}
                    disabled
                    endAdornment={
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="toggle password visibility"
                          onClick={handleClickShowPassword}
                          onMouseDown={handleMouseDownPassword}
                          disabled
                          edge="end"
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    }
                    label="Password"
                  />
                </FormControl>
              </Grid>
              <Grid item xs={12} sm={8}>
                <CallingSelector />
              </Grid>
              <Grid item xs={12} sm={4}>
                <DateSelector label='Date Set Apart' value={dateCalled} setValue={setDateCalled} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BorderedTextLabel title="First Login">
                  <div>{firstLogin}</div>
                </BorderedTextLabel>
              </Grid>
              <Grid item xs={12} sm={3}>
                <BorderedTextLabel title="Last Login" children={lastLogin} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <BorderedTextLabel title="Email Confirmed" children={dateEmailConfirmed} />
              </Grid>
              <Grid item xs={12} sm={3}>
                <Button variant="contained" sx={{ color: "#777777" }}>
                  <UploadFileIcon />
                </Button>
              </Grid>
              <Grid item mt={2} xs={12} sm={12} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Button variant="contained" sx={{ color: "#777777" }}>
                  Save
                </Button>
              </Grid>
              <Grid item xs={12} sm={5} />
            </Grid>
          </Box>
        </Paper>
      </Fragment>
    </>
  )
}

export default Person
