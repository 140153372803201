import { useState, useContext } from 'react'
import { useQuery } from '@tanstack/react-query';
import { fetchCallingList } from '../../utils/http-common';
import DataContext from '../../context/DataContext';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const bkgSelectColor = '#aaa';
const bkgHoverColor = '#ccc';

const callingStyle = {
    menu: {
        "&& .Mui-selected": {
            backgroundColor: bkgSelectColor
        },
        "& .MuiMenuItem-root:hover": {
            backgroundColor: bkgHoverColor
        },
        "& .MuiList-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover": {
            backgroundColor: bkgHoverColor
        },
    },
    menuItem: {
        color: '#333',
        backgroundColor: 'white',
    }
}

function compareRank(a, b) {
    if (a.rank < b.rank) {
        return -1;
    }
    if (a.rank > b.rank) {
        return 1;
    }
    return 0;
}

const CallingSelector = () => {
    const { callingsList, setCallingsList, callingId, setCallingId } = useContext(DataContext);

    const { isLoading, isError, data } = useQuery(['callingsList'], () => fetchCallingList());
    if (isLoading) {
        console.log(`Loading calling list...`)
        return
        (
            <FormControl fullWidth size="small">
                <InputLabel id="calling-simple-select-label" sx={{ color: '#000' }}>Calling</InputLabel>
                <Select
                    labelId="calling-simple-select-label"
                    id="calling"
                    value={0}
                    label="Calling"
                >
                    <MenuItem key={0} value={0}>Loading...</MenuItem>
                </Select>
            </FormControl>)
    }
    if (isError) {
        console.log('Error loading calling list');
        return <></>;
        /* (
            <FormControl fullWidth size="small">
                <InputLabel id="calling-simple-select-label" sx={{ color: '#000' }}>Calling</InputLabel>
                <Select
                    labelId="calling-simple-select-label"
                    id="calling"
                    value={0}
                    label="Calling"
                >
                    <MenuItem key={0} value={0}>Error loading callings</MenuItem>
                </Select>
            </FormControl>
        ) */
    } else {
        //console.log(JSON.stringify(data?.data.data))
        const handleCallingChange = (event) => {
            console.log(`change: ${event.target.value}`)
            setCallingId(event.target.value);
        };
        //console.log(`length: ${callingsList.length}`)

        if (callingsList.length === 0) {
            const callingListArray = data?.data.data.map((calling, i) => {
                const callingObj = {};
                callingObj.id = calling.id;
                callingObj.rank = calling.attributes.rank;
                callingObj.name = calling.attributes.name;
                callingObj.title = calling.attributes.title;
                //console.log(`calling: ${JSON.stringify(callingObj)}`);
                return callingObj;
            })
            callingListArray.sort(compareRank);
            setCallingsList(callingListArray);
            console.log('console list loaded');
        }
        return (
            <FormControl fullWidth size="small">
                <InputLabel id="suffix-simple-select-label" sx={{ color: '#000' }}>Calling</InputLabel>
                <Select
                    labelId="suffix-simple-select-label"
                    id="calling"
                    value={callingId}
                    label="Calling"
                    onChange={handleCallingChange}
                    MenuProps={{ sx: callingStyle.menu }}
                >
                    {callingsList.map((calling, i) => (
                        <MenuItem key={i} value={calling.id}
                            sx={callingStyle.menuItem}>
                            {calling.name}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        )
    }
}

export default CallingSelector
