import { useState, useEffect } from 'react';
import axios from 'axios';
//import api from '../api/azcc-cms';


//const controller = new AbortController();

const useAxiosFetch = (dataUrl) => {
    const [data, setData] = useState({});
    const [fetchError, setFetchError] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    //console.log(dataUrl);

    useEffect(() => {
        let isMounted = true;
        //const controller = new AbortController();
        const source = axios.CancelToken.source();

        const fetchData = async (url) => {
            setIsLoading(true);
            //console.log('Is loading');
            try {
                const response = await axios.get(url,
                    // { signal: controller.signal }
                    {
                        cancelToken: source.token,
                        headers: {
                            Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
                            /* "Content-type": "application/json" */
                        }
                    },
                );
                if (isMounted) {
                    //console.log(`First item: ${response.data.data[0].attributes.name}`);
                    setData(response.data.data);
                    setFetchError(null);
                }
            } catch (err) {
                console.log(`Error: ${err.message}`);
                if (isMounted) {
                    setFetchError(err.message);
                    setData([]);
                }
            } finally {
                isMounted && setIsLoading(false);
            }
        }

        fetchData(dataUrl);

        const cleanup = () => {
            //console.log('clean up conn');
            isMounted = false;
            source.cancel();
            //controller.abort();
        }

        return cleanup;
    }, [dataUrl]);

    return { data, fetchError, isLoading }
}

export default useAxiosFetch;