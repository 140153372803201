import { useState, useContext, useRef } from 'react';

import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Box, Typography, Menu, MenuItem } from '@mui/material';
import logo from '../../logo-white_640.png';
import AccountBoxIcon from '@mui/icons-material/AccountBox';

import { fetchMenubar } from "../../utils/http-common";
import { useQuery } from '@tanstack/react-query';

import ButtonPanelMenu from './ButtonPanelMenu';
import { Link } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import useLogout from '../../hooks/useLogout_old';
import ProfileMenu from './ProfileMenu';

const DEBUG_ON = false;

const Menubar = () => {
    const { smallTitle, menubarRef } = useContext(DataContext);
    const [anchorElNav, setAnchorElNav] = useState(null);
    const [isShowSubmenu, setShowSubmenu] = useState(true);
    const [anchorElSub, setAnchorElSub] = useState(null);

    const handleSubmenuToggle = (event) => {
        setShowSubmenu(!isShowSubmenu);
        if (isShowSubmenu) setAnchorElSub(event.currentTarget)
    };

    //const menubar = useRef([]);
    //const menuids = useRef([]);


    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleOpenSubMenu = (event) => {
        setAnchorElSub(event.currentTarget);
    };


    const { isLoading, isError, data } = useQuery(['menubar'], fetchMenubar);
    if (isLoading) {
        return <h2>Loading...</h2>
    }
    if (isError) {
        console.log('Error loading menus');
        return <></>
    } else {
        const theme = data?.data.data.attributes.navigation.theme;
        //console.log(`theme: ${theme}`);
        const menubarArray = data?.data.data.attributes.navigation.panels;
        //console.log(`menubar: ${JSON.stringify(menubarArray)}`)
        //menuids.current = [];
        //menubarArray.sort((a, b) => a.attributes.rank - b.attributes.rank);
        //console.log(menuCatArray);
        const menuArray = menubarArray?.map((item, i) => {
            //menuids.current.push(item.id);
            return { key: i + 1, name: item.link.label, href: item.link.href };
        })
        DEBUG_ON && console.log(`Menu Array: ${JSON.stringify(menuArray)}`);
        //menu.length > 0 ? console.log('menu loaded.)') : setMenu(menuArray);
        menubarRef.current = menuArray;

        return (
            <>
                <Box sx={{ height: { xs: 30, lg: 64 }, mt: 1 }}>
                    <Box >
                        <Link to="/" >
                            <Box
                                component="img"
                                sx={{
                                    height: 36,
                                    display: { xs: 'none', lg: 'flex' },
                                    mr: 1,
                                    mt: 1.4,
                                    position: 'fixed', top: 6, left: 10
                                }}
                                alt="AZCC logo."
                                src={logo}
                            />
                        </Link>
                        <Link to="/" >
                            <Box
                                component="img"
                                sx={{
                                    height: 32,
                                    display: { xs: 'flex', lg: 'none' },
                                    mr: 2,
                                    position: 'fixed', top: 20, left: 10
                                }}
                                alt="AZCC logo"
                                href="/"
                                src={logo}
                            />
                        </Link>
                        <Box sx={{ flexGrow: 1, display: { xs: 'none', lg: 'flex' }, position: 'fixed', top: 6, left: 140, }}>
                            {
                                menubarRef.current.map((menu) => (
                                    <Box key={menu.key}>
                                        <ButtonPanelMenu menuid={menu.key} name={menu.name} panel={menubarArray[menu.key - 1]} variant="outlined" color="info"
                                            onClick={handleSubmenuToggle}
                                            sx={{ my: 2, mx: 2, display: 'block' }}
                                        >
                                            {menu.name}
                                        </ButtonPanelMenu>
                                    </Box>
                                ))
                            }
                        </Box>
                        <Box sx={{ flexGrow: 1, display: { xs: 'flex', lg: 'none' }, position: 'fixed', top: 18, left: 150, }}>
                            <Typography variant='h5'>{smallTitle}</Typography>
                        </Box>
                        <ProfileMenu />
                    </Box>
                </Box >
            </>
        )
    }
}

export default Menubar
