//import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import SlideListItem from './SlideListItem';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

//import { FixedSizeList, ListChildComponentProps } from 'react-window';

/* function renderRow(slideinfo) {
    const { index, title } = slideinfo;
    console.log(`index: ${index}`);
    console.log(`title: ${title}`);

    return (
        <ListItem key={index} component="div" disablePadding>
            <ListItemButton>
                <ListItemText primary={`${index} ${title}`} />
            </ListItemButton>
        </ListItem>
    );
} */

export default function SlideList(props) {
    const { listtitle, list } = props;

    //console.log(`list title: ${JSON.stringify(listtitle)}`);
    //console.log(`slide list: ${JSON.stringify(list[0])}`);

    return (
        <Box
            sx={{ width: '100%', height: 770, maxWidth: 360, bgcolor: '#42648b' }}
        >
            <List
                disablePadding
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#42648b',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 770
                }}
            >
                <ListSubheader disableGutters={true} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    bgcolor: '#42648b',
                    color: 'ffffff'
                }}>
                    <Box height={55} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' >{`${listtitle}`}</Typography>
                    </Box>
                </ListSubheader>
                {list.map((slide) => <SlideListItem key={slide.id} slide={slide}
                   /*  sx={{
                        '& hover': {
                            color: '#ff0000',
                            backgroundColor: '#ffffff',
                        }
                    }} */ />)}
            </List>
        </Box >
    );
}