import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import { BottomNavigation, BottomNavigationAction } from '@mui/material';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import ContactsOutlinedIcon from '@mui/icons-material/ContactsOutlined';
import LocalLibraryOutlinedIcon from '@mui/icons-material/LocalLibraryOutlined';
import OfflineShareOutlinedIcon from '@mui/icons-material/OfflineShareOutlined';

const AZCCBottomNavigator = () => {

  const [value, setValue] = useState(0);
  const navigate = useNavigate()
  return (
    <BottomNavigation showLabels={true} sx={{ width: '100%', position: 'fixed', bottom: 0 }}
      //value={value}
      onChange={(event, newValue) => { setValue(newValue) }
      }
    >
      <BottomNavigationAction label='Home' icon={<HomeOutlinedIcon />} value='home'
        onClick={() => navigate('/')}
        sx={{ color: "white" }} />
      <BottomNavigationAction label='Directory' icon={<ContactsOutlinedIcon />} value='directory'
        onClick={() => navigate('/directory')}
        sx={{ color: "white" }} />
      <BottomNavigationAction label='Learn Center' icon={<LocalLibraryOutlinedIcon />} value='lessons'
        onClick={() => navigate('/lessons')}
        sx={{ color: "white" }} />
      <BottomNavigationAction label='Resources' icon={<OfflineShareOutlinedIcon />} value='resources'
        onClick={() => navigate('/resources')}
        sx={{ color: "white" }} />
    </BottomNavigation>
  )
}

export default AZCCBottomNavigator
