import { useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

const DateSelector = (props) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DatePicker label={props.label}
                value={props.value}
                onChange={(newValue) => props.setValue(newValue)}
                sx={{ padding: 0, marginTop: 0 }} />
        </LocalizationProvider>
    )
}

export default DateSelector
