import { useParams } from "react-router-dom";
import ResourceDocViewer from './ResourceDocViewer';

const DocViewer = () => {
    const { id, no } = useParams();
    return (
        <ResourceDocViewer id={id} no={no} />
    )
}

export default DocViewer
