import { useContext } from 'react'
import { useParams } from "react-router-dom";
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import Carousel from 'nuka-carousel/lib/carousel';

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from "react-slick";
// Import Swiper React components
import { Navigation, Pagination, Scrollbar, A11y } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css/bundle';
//import 'swiper/swiper.css'

import Slide from './Slide';
import SmSlide from './SmSlide';
import {
    Container, Grid, Button, Typography,
    Card, CardMedia, CardContent, CardActions,
    Stack
} from '@mui/material'
import { Box } from '@mui/system'
import SlideList from './SlideList';
import DataContext from '../../context/DataContext';
import { CMSHOST } from '../../utils/http-common';
import { fetchTutorial } from '../../utils/http-common';
import { useQuery } from '@tanstack/react-query';


//import logo from '../logo-white_512.png';

function Tutorial() {
    const { id, no } = useParams();
    console.log(`id: ${id}`);
    console.log(`no: ${no}`);
    const { selectedLessonIndex, setSelectedLessonIndex, lessonSlideRef } = useContext(DataContext);
    const lessonTitle = 'Giving Re-entry Help';
    const lessonSubtitle = 'Getting started - The Key Success Factors';
    const listTitle = 'Getting Started';
    const imgHeight = '320';
    const image1url = CMSHOST + '/uploads/parole_probation2_14ae369ba5.png';
    const image2url = CMSHOST + '/uploads/resources1_110d9750b7.jpg';
    const settings = {
        dots: false,
        infinite: false,
        slidesToShow: 2,
        slidesToScroll: 1,
        vertical: true,
        verticalSwiping: true,
        beforeChange: function (currentSlide, nextSlide) {
            console.log("before change", currentSlide, nextSlide);
        },
        afterChange: function (currentSlide) {
            console.log("after change", currentSlide);
        }
    };
    var slides = [
        {
            index: 1,
            title: "Transition Success Factors",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide1.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 2,
            title: "Transitioning to a Home Ward or Branch",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide2.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 3,
            title: "The Challenges in Between",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide3.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 4,
            title: "The 3 Toxic Emotions",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide4.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 5,
            title: "Unmet Emotional Needs Results",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide5.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 6,
            title: "Before and After",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide6.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
        {
            index: 7,
            title: "The 3 Pillars of Success",
            description: "",
            body: "",
            image: `url("https://www.whamcast.com/assets/images/lessons/txgs/Slide7.PNG")`,
            color: "white",
            link_title: "Details",
            link: ``
        },
    ]

    const setSlide = (index) => {
        console.log(`setSlide: ${index}`);
        lessonSlideRef.current = index;
    }
    const { isLoading, isError, data } = useQuery(['tutorial', id], () => fetchTutorial(id));
    if (isLoading) {
        return <h2>Loading...</h2>
    }
    if (isError) {
        console.log('Error loading tutorial');
        return <></>
    } else {
        const title = data?.data.data.attributes.title;
        const subtitle = data?.data.data.attributes.subtitle;
        const selectortitle = data?.data.data.attributes.selectortitle;
        const author = data?.data.data.attributes.author;

        console.log(`tutorial title: ${title}`);
        const slideArray = data?.data.data.attributes.slides;
        //console.log(`slides: ${slideArray}`)
        /*         const menuArray = slideArray?.map((item) => {
                    //menuids.current.push(item.id);
                    return { key: item.id, name: item.link.label, href: item.link.href };
                }) */
        //menubar.current = menuArray;
        //console.log(menus.current);

        return (
            <><Box sx={{ height: '88svh', width: '100%', display: { xs: 'block', md: 'none' } }} >
                <Box mt={6.5} p={1} /* sx={{ height: '11svh', width: 'auto' }} */>
                    <Typography variant='h5' >{title}</Typography>
                    <Typography variant='h6' mt={0.5}>{subtitle}</Typography>
                </Box>
                <Swiper
                    modules={[Navigation, Pagination, Scrollbar, A11y]}
                    spaceBetween={50}
                    slidesPerView={1}
                    navigation
                    pagination={{ clickable: true }}
                    scrollbar={{ draggable: true }}
                    onSlideChange={() => console.log('slide change')}
                    onSwiper={(swiper) => console.log(swiper)}
                >
                    {slideArray.map((slide, i) => <SwiperSlide><SmSlide key={i} slide={slide} /></SwiperSlide>)}
                </Swiper>
            </Box>
                <Box sx={{ p: 5, display: { xs: 'none', md: 'flex' } }}>
                    <Grid container rowSpacing={2}
                        columnSpacing={2}>
                        <Grid mt={5} item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant='h3' >{title}</Typography></Grid>
                        <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant='h5'>{subtitle}</Typography></Grid>
                        <Grid container item xs={12} rowSpacing={1}
                            columnSpacing={1}>
                            <Grid item tm={4} sx={{ display: { xs: 'none', md: 'flex' } }} lg={2}>
                                <Box width='300px'>
                                    <SlideList listtitle={selectortitle} list={slideArray} />
                                </Box>
                            </Grid>
                            <Grid item md={12} lg={10}>
                                <Box >
                                    <Container fixed maxWidth='lg' ml={0}
                                        sx={{ backgroundColor: "#7a9e70" }}>
                                        <Carousel
                                            height={400}
                                            width={'100%'}
                                            autoPlay={false}
                                            animation={'slide'}
                                            speed={500}
                                            slideIndex={lessonSlideRef.current}
                                            afterSlide={(index) => setSelectedLessonIndex(index + 1)}
                                            /* renderCenterLeftControls={({ previousDisabled, previousSlide }) => (
                                                <button onClick={setSlide(lessonSlideRef.current--)} disabled={previousDisabled}>
                                                    Previous
                                                </button>
                                            )}
                                            renderCenterRightControls={({ nextDisabled, nextSlide }) => (
                                                <button onClick={setSlide(lessonSlideRef.current++)} disabled={nextDisabled}>
                                                    Next
                                                </button>
                                            )} */
                                            navButtonsAlwaysVisible={true}
                                            next={(next, active) => {
                                                setSelectedLessonIndex(next + 1);
                                                console.log(`we left ${active}, and are now at ${next}`)
                                            }}
                                            prev={(prev, active) => {
                                                setSelectedLessonIndex(prev + 1);
                                                console.log(`we left ${active}, and are now at ${prev}`)
                                            }}
                                        >
                                            {
                                                slideArray.map((slide, i) => <Slide key={i} slide={slide} />)
                                            }
                                        </Carousel>
                                        {/* <Button onClick={setSlide(1)}>Test</Button> */}
                                    </Container>
                                </Box>
                            </Grid>
                            {/* <Grid item tm={4} sx={{ display: { xs: 'none', md: 'flex' } }} lg={2}>
                                <Stack spacing={2}>
                                    <Box width='300px' height='270px'>
                                        <Card sx={{ maxWidth: 240 }}>
                                            <CardMedia
                                                component='img'
                                                height={imgHeight}
                                                alt='Inmate Technology photo'
                                                sx={{ padding: "0em 0em 0 0em", objectFit: "contain" }}
                                                image={image2url}
                                                title="Inmate Technology"
                                            />
                                            <CardContent sx={{ height: 100 }}>
                                                <Typography variant="h6" component="div" color='black'>
                                                    Resources
                                                </Typography>
                                                {/*                                     <Typography variant="subtitle2" component="div" color='black'>
                                        Enriching the Incarcerated
                                    </Typography>
                                    }
                                                <Typography variant="body2" color="text.secondary">
                                                    Pretium quam vulputate dignissim suspendisse in.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" color='primary'>Details</Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                    <Box width='300px' height='230px'>
                                        <Card sx={{ maxWidth: 240 }}>
                                            <CardMedia
                                                component='img'
                                                height={imgHeight}
                                                alt='Inmate Technology photo'
                                                sx={{ padding: "0em 0em 0 0em", objectFit: "contain" }}
                                                image={image1url}
                                                title="Inmate Technology"
                                            />
                                            <CardContent sx={{ height: 100 }}>
                                                <Typography variant="h6" component="div" color='black'>
                                                    Parole Offices
                                                </Typography>
                                                {/*                                     <Typography variant="subtitle2" component="div" color='black'>
                                        Enriching the Incarcerated
                                    </Typography>
                                        }
                                                <Typography variant="body2" color="text.secondary">
                                                    Pretium quam vulputate dignissim suspendisse in.
                                                </Typography>
                                            </CardContent>
                                            <CardActions>
                                                <Button size="small" color='primary'>Details</Button>
                                            </CardActions>
                                        </Card>
                                    </Box>
                                </Stack>
                            </Grid> */}
                        </Grid>
                    </Grid >
                </Box >
            </>
        )
    }
}

export default Tutorial
