import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate";
import useAuth from "./useAuth";
import { useContext } from 'react';
import DataContext from "../context/DataContext";
import { useNavigate, useLocation } from "react-router-dom";

const DEBUG_ON = true;

const useGetUser = (email) => {
    const { auth } = useAuth();
    const { user, setUser, setAuthState } = useContext(DataContext);
    const axiosPrivate = useAxiosPrivate();
    const navigate = useNavigate();
    const location = useLocation();
    DEBUG_ON && console.log(`auth: ${JSON.stringify(auth)}`);
    DEBUG_ON && console.log(email);
    if (!email) {
        console.error(`Error fetching user`);
        setAuthState('RegEmailNotFound');
        navigate('/login', { state: { from: location }, replace: true });
    }
    return useQuery({
        queryKey: ['personfromemail'],
        queryFn: () => axiosPrivate.get(`/people/email/${email}`),
        enabled: true,
    });
}

export default useGetUser
