import { useRef, useState, useEffect, useContext, forwardRef, useMemo, useCallback } from "react";
import Avatar from '@mui/material/Avatar';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import MuiAlert from '@mui/material/Alert';
import CssBaseline from '@mui/material/CssBaseline';
import { Paper, Box, Stack, Typography, TextField, Button } from '@mui/material';
import FormControl, { useFormControl } from "@mui/material/FormControl";
import CallingSelector from "../Forms/CallingSelector";
import { FormHelperText, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link } from "react-router-dom";
import DataContext from "../../context/DataContext";
//import useRegisterChk from "../../hooks/useRegisterChk";
import { validateRegister, registerNewUser } from "../../utils/cms-common";
import { useNavigate, useLocation } from "react-router-dom";

const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;
const USER_REGEX = /^[A-z][A-z0-9-_]{3,23}$/;
const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@&#<>;()^$%]).{8,24}$/;
const PWD_MED_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[^A-Za-z0-9]).{6,}$/
const PWD_MED2_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[^A-Za-z0-9]).{8,}$/
const PWD_HELPER_TEXT = 'Minimum eight characters, at least one upper case letter, one lower case letter, one number and one special character'
const REGISTER_URL = '/register';

const styles = {
    email: {
        color: '#000',
        "& .MuiFormHelperText-root": {
            color: 'red'
        },
    },
    password: { width: '35ch' }
}

const Alert = forwardRef(function Alert(props, ref) {
    return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const Register = () => {
    const emailRef = useRef();
    const errRef = useRef();

    const [validated, setValidated] = useState(false);

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [emailFocus, setEmailFocus] = useState(false);

    const { setAuthState, callingId, username, setUsername, user, setUser,
        errorMsg, setErrorMsg, errorCode, setErrorCode } = useContext(DataContext);
    //const [titleId, setTitleId] = useState('');
    const [titleFocus, setTitleFocus] = useState(false);

    const [validAccount, setValidAccount] = useState(false);

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [showPwd, setShowPwd] = useState(false);


    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [showMatchPwd, setShowMatchPwd] = useState(false);

    const [success, setSuccess] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    useEffect(() => {
        if (!username) return;
        async function check() {
            const { person, passed, errCode, errMsg } = await validateRegister(email, callingId);
            console.log(`regchk: ${passed} ${errCode} ${errMsg}`)
            //alert(`regchk failed: ${passed} ${errCode} ${errMsg}`);

            if (passed) {
                setUser(person);
                setValidated(true);
            } else if (errCode && errCode !== '') {
                //alert(`regchk failed: ${passed} ${errCode} ${errMsg}`);
                setErrorCode(errCode);
                setErrorMsg(errMsg);
                setUsername('');
                console.log('regerror');
                if (errCode === 404) { // already registered
                    setAuthState('RegExists');
                }
                else
                    setAuthState('RegError');
            }
        }
        check();
    }, [username])

    useEffect(() => {
        if (!validated) return;
        async function registerUser() {
            const { success, errCode, errMsg } = await registerNewUser(user.id, pwd);
            console.log(`regchk: ${success} ${errCode} ${errMsg}`)
            //alert(`regchk failed: ${success} ${errCode} ${errMsg}`);

            if (success) {
                setAuthState('RegSuccess');
            } else if (errCode && errCode !== '') {
                //alert(`regchk failed: ${passed} ${errCode} ${errMsg}`);
                setErrorCode(errCode);
                setErrorMsg(errMsg);
                setUsername('');
                console.log('regerror');
                setAuthState('RegError');
            }
        }
        registerUser();
    }, [validated])

    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        //const result = EMAIL_REGEX.test(email);
        //console.log(`Valid Email: ${result}`);
        //console.log(email);
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        console.log(`Title Id: ${callingId}`);
    }, [callingId])

    useEffect(() => {
        const result = PWD_REGEX.test(pwd);
        //console.log(`Valid Password: ${result}`);
        //console.log(`Valid Matching Password: ${pwd === matchPwd}`);
        //console.log(pwd);
        setValidPwd(result);
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrorMsg('');
    }, [email, pwd, matchPwd])

    function PasswordHelperText() {
        const { focused } = useFormControl() || {};

        const helperText = useMemo(() => {
            if (focused && !validPwd) {
                return PWD_HELPER_TEXT;
            }
            return '';
        }, [focused, validPwd]);

        return <FormHelperText sx={{ color: '#f00' }}>{helperText}</FormHelperText>;
    }


    //password mgmt
    const handleClickShowPwd = () => setShowPwd((show) => !show);
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };
    const handleClickShowMatch = () => setShowMatchPwd((show) => !show);
    const handleMouseDownMatch = (event) => {
        event.preventDefault();
    };

    /* const handleRegisterCheck = useCallback(() => {
        
        const { passed, errCode, errMsg } = registerChk(username, callingId);
        
    }, [username]); */

    const handleErrorClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }

    }
    return (
        <>
            <Box
                sx={{
                    marginTop: { xs: -2, lg: 8 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AppRegistrationOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" color="common.white">
                    Register
                </Typography>
                <Box component="form" p={3} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                    <Stack spacing={2}>
                        <TextField
                            required
                            id="email"
                            name="email"
                            label="Email"
                            inputRef={emailRef}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                            error={!validEmail}
                            helperText={email !== '' && !validEmail ? "Invalid email address" : ""}
                            sx={styles.email}
                        />
                        <CallingSelector />
                        <FormControl sx={styles.password} size="small" variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showPwd ? 'text' : 'password'}
                                onChange={(e) => setPwd(e.target.value)}
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                required={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPwd}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end"
                                        >
                                            {showPwd ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                            <PasswordHelperText />
                        </FormControl>
                        <FormControl sx={styles.password} size="small" variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                            <OutlinedInput
                                id="outlined-adornment-password"
                                type={showMatchPwd ? 'text' : 'password'}
                                onChange={(e) => setMatchPwd(e.target.value)}
                                required={true}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle match password visibility"
                                            onClick={handleClickShowMatch}
                                            onMouseDown={handleMouseDownMatch}
                                            edge="end"
                                        >
                                            {showMatchPwd ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Confirm Password"
                            />
                            <FormHelperText id="match-helper-text" sx={{ color: '#f00' }}>{!validMatch ? "Does not match password" : " "}</FormHelperText>
                        </FormControl>
                        <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                            <Button
                                //type="submit"
                                variant="contained"
                                disabled={!validEmail || callingId === '' || !validPwd || !validMatch ? true : false}
                                onClick={() => { { setUsername(email) } }}
                                //component={Link}
                                //to={`/regres/${callingId}/${email}/${pwd}/${Date.now()}`}
                                sx={{ mt: 3, ml: 6, mb: 2 }}
                            >
                                Sign Up
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Login') } }}
                                sx={{ mt: 3, ml: 6, mb: 2 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: '#0f456f' }}>
            </Box>
        </>
    )
}

export default Register
