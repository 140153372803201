import { Box, Card, Typography } from '@mui/material'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { fetchCard, fetchMemo } from "../utils/http-common";
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { CMSHOST } from '../utils/http-common';

const Push = ({ id }) => {
    console.log(`push id: ${id}`);
    const { isLoading, isError, error, data } = useQuery(['push', id], () => fetchCard(id));
    if (isLoading) {
        console.log('loading card request...')
        return [];
    }
    if (isError) {
        console.log(`Error loading card: ${error.message}`);
        return [];
    } else {
        const imgHeight = '95px';
        const pushdata = data.data.data;
        const push = pushdata.attributes;
        const title = push.title;
        const body = push.body;
        const label = push.actionlabel;
        const linkto = push.link;
        const alt = push.photo.data.attributes.alternativeText;
        const url = CMSHOST + push.photo.data.attributes.formats.thumbnail.url;

        console.log(`card title: ${title}`);
        console.log(`card body: ${body}`);

        return (
            <Box width='245px' height='300px'>
                <Card sx={{ maxWidth: 245 }}>
                    <CardMedia
                        component='img'
                        height={imgHeight}
                        alt={alt}
                        sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                        image={url}
                        title={alt}
                    />
                    <CardContent sx={{ height: 85 }}>
                        <Typography variant="h6" component="div" color='black'>
                            {title}
                        </Typography>
                        <Typography variant="body2" color="text.secondary">
                            {body}
                        </Typography>
                    </CardContent>
                    <CardActions>
                        <Button size="small" color='primary'
                            component={Link}
                            to={linkto}
                        >{label}</Button>
                    </CardActions>
                </Card>
            </Box>
        )
    }
}

export default Push
