import { createTheme } from '@mui/material/styles';
import { grey } from '@mui/material/colors';

const Colors = {
    primary: "#0e3b5f",
    secondary: "#10456F",
    success: "#456F10",
    info: "#106F6A",
    error: "#6F1045",
    warning: "#7eff00",
    dark: "#0e3b5f",
    light: "#aaa",
    muted: "#abafb3",
    border: "#ffffff",
    inverse: "#2F3D4A",
    shaft: "#333",
    dove_gray: "#d5d5d5",
    body_bg: "#f3f6f9",
    ///////////////
    // Solid Color
    ///////////////
    white: "#fff",
    black: "#000",
};

const theme = createTheme({
    palette: {
        primary: {
            light: '#0f456f',
            main: Colors.primary,
            dark: '#0f3b5f'
        },
        secondary: {
            light: '#89cf44',
            main: Colors.secondary,
            dark: '#1f6e00'
        },
        success: {
            light: '#cb4679',
            main: Colors.success,
            dark: '#620025'
        },
        info: {
            light: '#5a9bf4',
            main: grey[50],
            dark: '#004390'
        },
        error: {
            light: '#ff953e',
            main: Colors.error,
            dark: '#be3200'
        },
        warning: {
            light: '#b7ff57',
            main: Colors.warning,
            dark: '#3fcb00'

        }
    },
    components: {
        MuiBox: {
            styleOverrides: {
                root: {
                    '&.WithRoundBorder': {
                        borderRadius: '10px',
                        borderColor: 'white',
                        display: 'flex',
                        flexWrap: 'wrap',
                        m: 1
                    },
                }
            }
        },
        MuiButton: {
            defaultProps: {
                variant: 'outlined',
                color: 'info',
                disableRipple: true
            },
            styleOverrides: {
                root: {
                    '&.Mui-selected,  && .Mui-selected:hover': {
                        backgroundColor: '#82aadb',
                    },
                    '&:hover': {
                        backgroundColor: '#fff',
                        color: '#3c52b2',
                    },
                    '&.Mui-disabled': {
                        backgroundColor: '#0e3b5f',
                        color: '#ccc',
                    }
                }
            }
        },
        MuiLink: {
            defaultProps: {
                underline: 'none'
            },
            styleOverrides: {
                root: {
                    color: 'white',
                }
            }
        },
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    color: 'white',
                    backgroundColor: '#10456F',
                }
            }
        },
        MuiList: {
            styleOverrides: {
                root: {
                    backgroundColor: '#10456F',
                    color: 'white',
                    '&.Mui-selected,  && .Mui-selected:hover': {
                        backgroundColor: '#82aadb',
                    },
                },
            }
        },
        MuiListItem: {
            styleOverrides: {
                root: {
                    backgroundColor: '#10456F',
                    color: 'white'
                }
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    backgroundColor: '#10456F',
                    color: 'black',
                    '&.Mui-selected,  && .Mui-selected:hover': {
                        backgroundColor: '#0e3b5f',
                        color: '333333'
                    }
                }
            }
        },
        MuiListItemText: {
            styleOverrides: {
                root: {
                    color: 'white',
                    '&.MuiListItemText-secondary': {
                        color: 'white',
                    }
                }
            }
        },
        MuiDivider: {
            styleOverrides: {
                root: {
                    borderColor: 'white',
                    "&::before, &::after": {
                        borderColor: 'white'
                    }
                },
            },
        },
        MuiAccordianDetails: {
            styleOverrides: {
                root: {
                    width: "100%"
                },
                accordion: {
                    minHeight: 150, //ugly but works
                    height: "100%"
                },
                details: {
                    borderColor: '#00f',
                    backgroundColor: '#777'
                },
            },
        },
        MuiChip: {
            styleOverrides: {
                root: {
                    backgroundColor: 'white',
                    fontSize: '0.95rem'
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                label: {
                    fontSize: '0.9rem',
                }
            }
        },
        MuiFormLabel: {
            styleOverrides: {
                root: {
                    color: "#333",
                },
                label: {
                    fontSize: '0.9rem',
                }
            }
        },
        /*         MuiInputBase: {
                    styleOverrides: {
                        root: {
                            backgroundColor: '#10456F',
                            color: 'white',
                            '&& .Mui-selected,  && .Mui-selected:hover': {
                                backgroundColor: 'white',
                                color: '#333333'
                            }
                        },
                        input: {
                            backgroundColor: 'white',
                            color: 'black',
                            '&& .Mui-selected,  && .Mui-selected:hover': {
                                backgroundColor: 'white',
                                color: '#333333'
                            },
                            '&:focus': {
                                backgroundcolor: 'yellow'
                            }
                        }
                    }
                },
                */
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    /*                     backgroundColor: '#10456F', */
                    color: 'white',
                    '&.Mui-checked': {
                        color: "white"
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: '1.1rem'
                    },
                    "&.control-light": {
                        color: '#777'
                    }
                },
            },
        },
        MuiRadio: {
            styleOverrides: {
                root: {
                    /*                     backgroundColor: '#10456F', */
                    color: 'white',
                    '&.Mui-checked': {
                        color: "white"
                    },
                    "& .MuiSvgIcon-root": {
                        fontSize: '1.1rem'
                    }
                },
            },
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    backgroundColor: '#fff',
                    color: '#000',
                    "& .MuiOutlinedInput-root": {
                        "& > fieldset": {
                            borderColor: "#ccc",
                        },
                    },
                    "& .MuiOutlinedInput-root.Mui-focused": {
                        "& > fieldset": {
                            borderColor: "#777",
                            color: "green"
                        }
                    },
                    "& .MuiOutlinedInput-root:hover": {
                        "& > fieldset": {
                            borderColor: "#10456F",
                            color: "green"
                        }
                    }

                },
            },
        },
    },
    typography: {
        title: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 36,
            fontWeight: 300,
        },
        subtitle: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 24,
            fontWeight: 500,
        },
        subtitle1: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 20,
            fontWeight: 400,
        },
        subtitle2: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 18,
            fontWeight: 400,
        },
        subtitle3: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 16,
            fontWeight: 400,
        },
        body: {
            lineHeight: 1.6,
            fontFamily: `"Lora", "Helvetica", "Arial", sans-serif`,
            fontSize: 16,
            fontWeight: 400,
        },
        body1: {
            lineHeight: 1.6,
            fontFamily: `"Lora", "Helvetica", "Arial", sans-serif`,
            fontSize: 16,
            fontWeight: 400,
        },
        body2: {
            lineHeight: 1.3,
            fontFamily: `"Lora", "Helvetica", "Arial", sans-serif`,
            fontSize: 14,
            fontWeight: 400,
        },
        footnote: {
            lineHeight: 1.0,
            fontFamily: `"Lora", "Helvetica", "Arial", sans-serif`,
            fontSize: 12,
            fontWeight: 400,
        },
        menu: {
            lineHeight: 1.6,
            fontFamily: `"Lato", "Helvetica", "Arial", sans-serif`,
            fontSize: 16,
            fontWeight: 500,
        },
        allVariants: {
            color: 'white'
        },
        button: {
            fontSize: '0.8rem',
            // background: Colors.info,
            opacity: '100%'
        },
    },
});

export default theme;