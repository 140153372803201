import './App.css';
import { Route, Routes } from 'react-router-dom';
import PersistLogin from './components/authentication/PersistLogin';
import RequireAuth from './components/authentication/RequireAuth';
import { Box } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import theme from './styles/Styles';
import { DataProvider } from './context/DataContext';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Layout from './components/Layout';
import Home from './components/Home';
import PageMemo from './components/PageMemo';
import Missing from './components/Missing';
import Tutorial from './components/tutorial/Tutorial';
import DirectoryList from './components/DirectoryList';
import LessonList from './components/LessonList';
import CardGrid from './components/CardGrid';
import TestMap2 from './components/TestMap2';
import TestMap3 from './components/TestMap3';
import SignIn from './components/authentication/SignIn';
import Register from './components/authentication/Register';
import CurriculaDocViewer from './components/docviewer/CurriculaDocViewer';
import ResourceDocViewer from './components/docviewer/ResourceDocViewer';
import DocViewer from './components/docviewer/DocViewer';
import Webview from './components/webviewer/Webview';
import Push from './components/Push';
import Person from './components/Forms/Person';
import RegisterChk from './components/authentication/RegisterChk';
import Branch from './components/directory/Branch';
import WelcomeMsg from './components/communication/WelcomeMsg';
import ResetPwd from './components/authentication/ResetPwd';
import NotificationsViewer from './components/notification/NotificationsViewer';

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnmount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000,
    },
  },
})

function App() {

  return (
    <>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <DataProvider>
            <Box sx={{
              backgroundColor: "#10456F",
              display: 'block',
              overflow: 'auto'
            }}>
              <Routes>
                <Route path="/" element={<Layout />}>

                  {/* protected routes */}
                  <Route element={<PersistLogin />}>
                    <Route element={<RequireAuth />}>
                      <Route index element={<Home />} />
                      <Route path="page">
                        <Route index element={<Missing />} />
                        <Route path=":id" element={<PageMemo />} />
                      </Route>
                      <Route path="docview">
                        <Route index element={<Missing />} />
                        <Route path=":id/" element={<DocViewer />} />
                        <Route path=":id/:no" element={<DocViewer />} />
                      </Route>
                      <Route path="lessonview">
                        <Route index element={<Missing />} />
                        <Route path=":id/" element={<Tutorial />} />
                        <Route path=":id/:no" element={<Tutorial />} />
                      </Route>
                      <Route path="lesson" element={<Tutorial id={1} />} />
                      <Route path="lesson2" element={<Tutorial id={2} />} />
                      <Route path="lessons" element={<LessonList />} />
                      <Route path="curriculaview" element={<CurriculaDocViewer id={1} />} />
                      <Route path="reentryresourceview" element={<ResourceDocViewer id={1} />} />
                      <Route path="notificationview" element={<ResourceDocViewer id={2} />} />
                      <Route path="notifications" element={<NotificationsViewer />} />
                      <Route path="glossary" element={<ResourceDocViewer id={11} />} />
                      <Route path="webview" element={<Webview />} />
                      <Route path="study">
                        <Route index element={<Missing />} />
                        <Route path=":id" element={<Tutorial />} />
                      </Route>
                      <Route path="directory" element={<DirectoryList />} />
                      <Route path="branch">
                        <Route index element={<Missing />} />
                        <Route path=":id/" element={<Branch />} />
                      </Route>
                      <Route path="committee" element={<CardGrid />} />
                      <Route path="person" element={<Person />} />
                    </Route>
                  </Route>

                  {/* catch all routes */}
                  <Route path="*" element={<Missing />} />
                </Route>
                {/* public routes */}
                <Route path="push1" element={<Push id={1} />} />
                <Route path="login" element={<SignIn />} />
                <Route path="register" element={<Register />} />
                <Route path="welcome" element={<WelcomeMsg />} />
                <Route path="resetpwd" element={<ResetPwd />} />
                <Route path="regres">
                  <Route index element={<Missing />} />
                  <Route path=":id/:email/:pwd/:ts" element={<RegisterChk />} />
                </Route>
                <Route path="map" element={<TestMap2 />} />
                <Route path="map3" element={<TestMap3 />} />
                {/*                 
                <header className="App-header">
                  <img src={logo} className="App-logo" alt="logo" />
                  <p>
                    Edit <code>src/App.js</code> and save to reload.
                  </p>
                  <a
                    className="App-link"
                    href="https://reactjs.org"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Learn React
                  </a>
                </header>
 */}
              </Routes>
            </Box>
          </DataProvider>
        </ThemeProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
