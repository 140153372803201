import { useState, useEffect, useContext } from 'react';
import { Box, Grid, Stack } from '@mui/material';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import DirectoryFilter from './DirectoryFilter';
//import { fetchBranches } from "../utils/http-common";
//import { useQuery } from '@tanstack/react-query';
import DataContext from '../context/DataContext';

import { useQuery, gql } from '@apollo/client';
import { branches_gql } from '../utils/gql-queries';

//const BRANCHES = gql(branches_gql);

const DirectoryContent = () => {
  const { directory, setDirectory, filteredDir, setFilteredDir } = useContext(DataContext);
  const [expanded, setExpanded] = useState('');

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <Stack>
      <Typography variant='h6' color="common.white">Total: {filteredDir.length}</Typography>
      <Box sx={{ height: { xs: '60vh', md: '60vh' }, backgroundColor: 'white', overflow: 'hidden', overflowY: 'scroll', minHeight: '75vh' }}>
        {filteredDir.map((branch, index) => (
          <Box mb={0.3} key={branch.id} sx={{ backgroundColor: '#666' }}>
            <Accordion expanded={expanded === branch.id} onChange={handleChange(branch.id)}>
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-controls="panel1a-content"
                id="panel1a-header"
              >
                <Grid container spacing={1}>
                  <Grid item xs={12} md={3}>
                    {
                      branch.attributes.prisonbranch ?
                        <Typography variant='h6' color="common.black">{branch.attributes.name}</Typography>
                        :
                        <Typography variant='h6' color="common.black" sx={{ fontStyle: 'italic' }}>{branch.attributes.name}</Typography>
                    }
                  </Grid>
                  <Grid item xs={12} md={3}>
                    <Grid container>
                      <Box sx={{ display: { xs: 'none', md: 'block' } }} >
                        <Grid item xs={12}>
                          <Typography variant='subtitle3' color="common.black">
                            {branch.attributes.president.data?.attributes.calling.data?.attributes.title} {branch.attributes.president.data?.attributes.fullname}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle3' color="common.black">{branch.attributes.president.data?.attributes.phone}</Typography>
                        </Grid>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }} >
                          <Grid item xs={12}>
                            <Typography variant='subtitle1' color="common.black">{branch.attributes.agentStake.data.attributes.name}</Typography>
                          </Grid>
                        </Box>
                      </Box>
                      <Box sx={{ display: { xs: 'block', md: 'none' } }} >
                        <Grid item xs={12}>
                          <Typography variant='subtitle3' color="common.black" gutterBottom={true}>
                            {branch.attributes.president.data?.attributes.calling.data?.attributes.title} {branch.attributes.president.data?.attributes.fullname}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='subtitle3' color="common.black">{branch.attributes.president.data?.attributes.phone}</Typography>
                        </Grid>
                        <Box sx={{ display: { xs: 'block', md: 'none' } }} >
                          <Grid item xs={12}>
                            <Typography variant='subtitle3' color="common.black">{branch.attributes.agentStake.data.attributes.name}</Typography>
                          </Grid>
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                  <Box mt={1} sx={{ display: { xs: 'none', md: 'block' } }} >
                    <Grid item xs={0} md={12}>
                      <Grid container>
                        <Grid item xs={12}>
                          <Typography variant='body1' color="common.black"><Box sx={{ fontWeight: 'bold', display: 'inline' }}>Agent Stake: </Box>{branch.attributes.agentStake.data.attributes.name}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                          <Typography variant='body1' color="common.black">President  - {branch.attributes.agentStake.data.attributes.president.data?.attributes.fullname}</Typography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Box>
                </Grid>
              </AccordionSummary>
              <AccordionDetails>
                <Box p={1} sx={{
                  backgroundColor: '#eee',
                  borderRadius: '13px',
                  borderStyle: 'solid',
                  borderWidth: '2px'
                }}>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={4}>
                      <Grid container direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "110px", backgroundColor: 'white' }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' color="common.black">Branch Presidency:</Typography>
                        </Grid>
                        <Grid container>
                          <Grid item xs={12}>
                            <Typography variant='body' color="common.black">Pres: {branch.attributes.president.data?.attributes.fullname}, {branch.attributes.president.data?.attributes.phone}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body' color="common.black">1st: {branch.attributes.firstCounselor.data?.attributes.fullname}, {branch.attributes.firstCounselor.data?.attributes.phone}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body' color="common.black">2nd: {branch.attributes.secondCounselor.data?.attributes.fullname}, {branch.attributes.secondCounselor.data?.attributes.phone}</Typography>
                          </Grid>
                        </Grid>
                      </Grid>
                      <Box sx={{ display: { xs: 'block', lg: 'none' }, backgroundColor: 'white' }} >
                        <Grid container mt={2} direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "60px" }}>
                          <Grid item xs={12}>
                            <Typography variant='subtitle1' color="common.black">Agent Stake:</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body' color="common.black">{branch.attributes.agentStake.data.attributes.name}</Typography>
                          </Grid>
                          <Grid item xs={12}>
                            <Typography variant='body' color="common.black">President  - {branch.attributes.agentStake.data.attributes.president.data?.attributes.fullname}</Typography>
                          </Grid>
                        </Grid>
                      </Box>
                      <Grid container mt={2} direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "60px", backgroundColor: 'white' }}>
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' color="common.black">Supporting Stakes:</Typography>
                        </Grid>
                        <Grid container>
                          {branch.attributes.agentStake.data.attributes.supportingStakes.data.map((stake) => (
                            <Grid key={stake.id} item xs={12}>
                              <Typography variant='body' color="common.black">{stake.attributes.name}</Typography>
                            </Grid>
                          ))
                          }
                        </Grid>
                      </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                      <Grid container sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "110px", backgroundColor: 'white' }} >
                        <Grid item xs={12}>
                          <Typography variant='subtitle2' color="common.black">Correctional Facilities:</Typography>
                        </Grid>
                        {branch.attributes.correctionFacilities.data.map((facility) => (
                          <Box key={facility.id}>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#ddd" }}>
                              <Typography variant='subtitle2' color="common.black">{facility.attributes.name} - {facility.attributes.address}, {facility.attributes.city}, AZ {facility.attributes.zipcode}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                              <Typography variant='body' color="common.black">Description: {facility.attributes.description}</Typography>
                            </Grid>
                            <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                              <Typography variant='body' color="common.black">Housing Type: {' '}</Typography>
                              {facility.attributes.housing_types.data.map((housing, index) => (
                                <Typography key={housing.id} variant='body' color="common.black">{
                                  (facility.attributes.housing_types.data).length > (index + 1) ? housing.attributes.name + "," : housing.attributes.name} </Typography>
                              ))
                              }
                            </Grid>
                          </Box>
                        ))
                        }
                      </Grid>
                    </Grid>
                  </Grid>
                </Box>
              </AccordionDetails>
            </Accordion>
          </Box>
        ))}
      </Box>
    </Stack>
  )
}

export default DirectoryContent
