//import { useContext } from 'react';
import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import DocListItem from './DocListItem';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import ListSubheader from '@mui/material/ListSubheader';

//import { FixedSizeList, ListChildComponentProps } from 'react-window';

/* function renderRow(slideinfo) {
    const { index, title } = slideinfo;
    console.log(`index: ${index}`);
    console.log(`title: ${title}`);

    return (
        <ListItem key={index} component="div" disablePadding>
            <ListItemButton>
                <ListItemText primary={`${index} ${title}`} />
            </ListItemButton>
        </ListItem>
    );
} */

export default function DocList(props) {
    const { listtitle, list } = props;

    //console.log(`list title: ${JSON.stringify(listtitle)}`);
    //console.log(`slide list: ${JSON.stringify(list[0])}`);

    return (
        <Box
            sx={{ width: '100%', height: '50vh', maxWidth: 400, bgcolor: '#42648b' }}
        >
            <List
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#42648b',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 1384,

                }}
            >
                <ListSubheader sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    bgcolor: '#42648b',
                    color: 'ffffff'
                }}>
                    <Box height={40}>
                        <Typography variant='h6' nowrap={'true'} >{`${listtitle}`}</Typography>
                    </Box>
                </ListSubheader>
                <Box  >
                    {list.map((doc, i) => <DocListItem key={i} index={i} doc={doc}
                   /*  sx={{
                        '& hover': {
                            color: '#ff0000',
                            backgroundColor: '#ffffff',
                        }
                    }} */ />)}
                </Box>
            </List>
        </Box >
    );
}