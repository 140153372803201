import React from 'react'
import { Box, Card, CardMedia, CardContent, CardActions, Typography, Button } from '@mui/material';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';

const Profile = (props) => {
    return (
        <Box width={props.width} height={props.height}>
            <Card sx={{ maxWidth: props.maxWidth }}>
                <CardMedia
                    component='img'
                    height={props.imgHeight}
                    alt={props.alt}
                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                    image={props.image}
                    title={props.name}
                />
                <CardContent>
                    <Typography variant="h6" component="div" color='black'>
                        {props.name}
                    </Typography>
                    <Typography variant="subtitle2" component="div" color='black'>
                        {props.title}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.body}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default Profile; 