import { createContext, useState, useRef, useEffect } from "react";
//import { useNavigate } from 'react-router-dom';
//import { format } from 'date-fns';
//import api from '../api/azcc-cms';
//import useWindowSize from '../hooks/useWindowSize';
import useAxiosFetch from '../hooks/useAxiosFetch';
//import { gql } from '@apollo/client';
//import { branches_gql } from '../utils/gql-queries';
import { CMSHOST } from "../utils/http-common";


//const BRANCHES = gql(branches_gql);

const DataContext = createContext({});

export const DataProvider = ({ children }) => {
    const [authState, setAuthState] = useState('Login');
    const [username, setUsername] = useState('');
    const [errorMsg, setErrorMsg] = useState('');
    const [errorCode, setErrorCode] = useState('');

    const [user, setUser] = useState({});
    //const [menu, setMenu] = useState([]);
    const [branch, setBranch] = useState({});
    const [memos, setMemos] = useState([]);  // Added for using the axios way
    const [directory, setDirectory] = useState([]);
    //  const [directoryStatus, setDirectoryStatus] = useState(null);
    const [filteredDir, setFilteredDir] = useState([]);
    const [glossary, setGlossary] = useState([]);
    const [filteredGlossary, setFilteredGlossary] = useState([]);
    const [callingsList, setCallingsList] = useState([]);
    const [callingId, setCallingId] = useState('');
    const [selectedLessonIndex, setSelectedLessonIndex] = useState(1);
    const lessonSlideRef = useRef(0);
    const [selectedNotificationIndex, setSelectedNotificationIndex] = useState(1);
    const NotificationRef = useRef(0);
    const [selectedDocIndex, setSelectedDocIndex] = useState(1);
    const [smallTitle, setSmallTitle] = useState('');
    const docViewRef = useRef(0);
    const menubarRef = useRef([]);

    /*     const [search, setSearch] = useState('');
    const [searchResults, setSearchResults] = useState([]);
 */
    //const navigate = useNavigate();
    //const { width } = useWindowSize();

    //const { data, fetchError, isLoading } = useAxiosFetch(CMSHOST + '/api/menu-categories');
    const { memodata, memoFetchError, isLoadingMemo } = useAxiosFetch(CMSHOST + '/api/memos');

    /* const formatResponse = (res) => {
        return JSON.stringify(res, null, 2);
    };
 */
    /*     useEffect(() => {
            if (Array.isArray(data)) {
                data.sort((a, b) => a.attributes.ranking - b.attributes.ranking);
                const newMenu = data.map(menu => { return menu.attributes.name });
                setMenus(newMenu);
                //console.log(`First item: ${data ? newMenu[1] : 'none'}`);
            }
        }, [data])
    
     */
    useEffect(() => {
        setMemos(memodata);
    }, [memodata])

    /*     useEffect(() => {
             const filteredResults = posts.filter((post) =>
                 ((post.body).toLowerCase()).includes(search.toLowerCase())
                 || ((post.title).toLowerCase()).includes(search.toLowerCase()));
      
             //console.log(`UseEffect window width: ${width}`);
             setSearchResults(filteredResults.reverse());
         }, [posts, search])
      
         const handleSubmit = async (e) => {  //added async for axios
             e.preventDefault();
             const id = posts.length ? posts[posts.length - 1].id + 1 : 1;
             const datetime = format(new Date(), 'MMMM dd, yyyy pp');
             const newPost = { id, title: postTitle, datetime, body: postBody };
             //Axios way
             try {
                 const response = await api.post('/posts', newPost);
                 const allPosts = [...posts, response.data];
                 setPosts(allPosts);
                 setPostTitle('');
                 setPostBody('');
                 navigate('/');
             } catch (err) {
                 console.log(`Error: ${err.message}`);
             }
         }
      
         const handleEdit = async (id) => {
             const datetime = format(new Date(), 'MMMM dd, yyyy pp');
             const updatedPost = { id, title: editTitle, datetime, body: editBody };
             try {
                 const response = await api.put(`/posts/${id}`, updatedPost);
                 setPosts(posts.map(post => post.id === id ? { ...response.data } : post));
                 setEditTitle('');
                 setEditBody('');
                 navigate('/');
             } catch (err) {
                 console.log(`Error: ${err.message}`);
             }
      
         }
      
         const handleDelete = async (id) => { //added async for axios
             // axios way
             try {
                 await api.delete(`/posts/${id}`);
                 const postList = posts.filter(post => post.id !== id)
                 setPosts(postList);
                 navigate('/');
             } catch (err) {
                 console.log(`Error: ${err.message}`);
             }
         } */

    return (
        <DataContext.Provider value={{
            // width, search, setSearch, searchResults,
            authState, setAuthState, username, setUsername,
            errorMsg, setErrorMsg, errorCode, setErrorCode,
            user, setUser, menubarRef, branch, setBranch,
            memos, memoFetchError, isLoadingMemo,
            directory, setDirectory, filteredDir, setFilteredDir,
            glossary, setGlossary, filteredGlossary, setFilteredGlossary,
            callingsList, setCallingsList, callingId, setCallingId,
            selectedLessonIndex, setSelectedLessonIndex,
            lessonSlideRef,
            selectedNotificationIndex, setSelectedNotificationIndex,
            NotificationRef,
            selectedDocIndex, setSelectedDocIndex,
            smallTitle, setSmallTitle,
            docViewRef,
            //handleSubmit, handleDelete, handleEdit
        }}>
            {children}
        </DataContext.Provider>
    )
}

export default DataContext;