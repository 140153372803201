import React from 'react'
import Iframe from 'react-iframe'

const Webview = () => {
    return (
        <Iframe url="https://des.az.gov/services/basic-needs/community-action-agencies"
            width="1000px"
            height="1000px"
            id=""
            className=""
            display="block"
            position="relative" />
    )
}

export default Webview
