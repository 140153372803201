import React from 'react'
import { Box } from '@mui/material'
import Image from 'mui-image'

const ImageCenter = (props) => {
    return (
        <Box mt={1} display="flex" justifyContent={'center'} alignItems={'center'}
        /*             component="img"
                    sx={{
                        height: props.height,
                        width: props.width,
                        maxHeight: { xs: props.maxHeight, md: props.maxHeight },
                        maxWidth: { xs: props.maxWidth, md: props.maxWidth },
                    }}
                    alt={props.alt}
                    src={props.src} */
        >
            <Image src={props.src}
                showloading
                width={props.width}
                height={props.height}
            />
        </Box>
    )
}

export default ImageCenter
