import React from 'react'
import { Grid, IconButton, Typography, Stack, Container } from '@mui/material'
import { Box } from '@mui/system'
import DownloadIcon from '@mui/icons-material/Download';
import Iframe from 'react-iframe'
import DocViewer, { DocViewerRenderers } from '@cyntler/react-doc-viewer'
import Memo from '../Memo';
import { CMSHOST } from '../../utils/http-common';

const Doc = (props) => {
    const docData = props.doc.doc;
    //console.log(`doc viewer: ${JSON.stringify(docData)}`);

    const ext = docData.data?.attributes.file.data?.attributes.ext;
    const name = docData.data?.attributes.file.data?.attributes.name;
    const type = docData.data?.attributes.doctype;
    //console.log(`ext: ${ext}`);

    if (type === 'Website') {
        if (docData.data?.attributes.link === null) {
            return <></>
        }
        const link = docData.data?.attributes.link;
        return (
            <Box mx={7} sx={{
                // display: 'block',
                height: '80dvh'

            }}>
                <Iframe url={link}
                    width="100%"
                    height="100%"
                    id=""
                    className=""
                    display="block"
                    position="relative" />
            </Box>
        )
    } else if (type === 'Memo') {
        if (docData.data?.attributes.memo?.data === null) {
            return <></>
        }
        const memoid = docData.data?.attributes.memo?.data.id;
        //console.log(`memo id: ${memoid}`);
        return (
            <Box mx={7} >
                <Memo memoid={memoid} />
            </Box>
        )
    } else if (ext === '.doc' || ext === '.docx') {
        if (docData.data?.attributes.link === null) {
            return <></>
        }

        const link = process.env.REACT_APP_DOCX_URL + docData.data?.attributes.link;
        //console.log(link);
        const docxs = [
            { uri: link }
        ]
        const onDownload = () => {
            const alink = document.createElement("a");
            alink.download = name;
            alink.href = link;
            alink.click();
        };
        return (
            <Box sx={{
                // display: 'block',
                height: '1064px'
            }}>

                <Stack
                    direction="row"
                    justifyContent="space-between"
                    alignItems="center"
                    spacing={2}
                >
                    <Typography variant="h5" mt={3} color="white" >{name}</Typography>

                    <IconButton onClick={onDownload} aria-label="download" sx={{
                        color: "white",
                        backgroundColor: "secondary",
                        borderRadius: "50%"
                    }}>
                        <DownloadIcon />
                    </IconButton>
                </Stack>
                <DocViewer documents={docxs}
                    prefetchMethod='GET'
                    //requestHeaders={headers}
                    config={{
                        header: {
                            disableHeader: false,
                            disableFileName: true,
                            retainURLParams: false,
                        },
                        csvDelimiter: ",",
                        pdfZoom: {
                            defaultZoom: 1,
                            zoomJump: 0.1,
                        },
                        pdfVerticalScrollByDefault: false,
                    }}
                    pluginRenderers={DocViewerRenderers}
                />
            </Box>

        )
    } else {
        const docUrl = `${CMSHOST + docData.data?.attributes.file.data.attributes.url}`;
        //console.log(`url: ${docUrl}`);
        const docs = [
            { uri: docUrl }
        ]
        return (
            <DocViewer documents={docs}
                config={{
                    header: {
                        disableHeader: false,
                        disableFileName: true,
                        retainURLParams: false,
                    },
                    csvDelimiter: ",",
                    pdfZoom: {
                        defaultZoom: 1,
                        zoomJump: 0.1,
                    },
                    pdfVerticalScrollByDefault: false,
                }}
                pluginRenderers={DocViewerRenderers} />
            /*         <Container maxWidth={false} mb={10}
                        sx={{
                            maxWidth: '1000px',
                            display: 'flex',
                            flexDirection: 'row',
                            backgroundRepeat: "no-repeat",
                            backgroundColor: "white",
                            backgroundPosition: "center",
                            backgroundSize: "contain",
                            height: 768,
                            width: "100%",
                            overflow: "hidden",
                            overflowY: "scroll",
                            color: 'black',
            
                        }}
                    >
                        <Grid container mt={10} spacing={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
                            <Grid item xs={12} sx={{ maxHeight: '14px' }}>
                                <div>
                                    <Typography variant="body" mt={3} color="black" >{docUrl}</Typography>
                                </div>
                            </Grid>
                            <Grid item xs={12}>
                               
                            </Grid>
                            <Grid item xs={12} mt={5} >
                                <Grid 
                                // xs={12} 
                                 container direction="column" >
                                    <Box>
                                        {
                                            // <Button color="primary" variant="contained" className="CheckButton">{props.slide.link_title}</Button> }
                                    </Box>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Container> */
        )

    }
}

export default Doc
