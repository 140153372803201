import { useContext } from 'react'
import { Box, Stack, Typography, Button } from '@mui/material'
import Avatar from '@mui/material/Avatar';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import DataContext from '../../context/DataContext';
import { useNavigate, useLocation } from "react-router-dom";


const RegisterSuccess = (props) => {
    const { user, setAuthState } = useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();

    const title = user.attributes.calling.data.attributes.title;
    const fullname = user.attributes.middlename === '' ?
        user.attributes.firstname + ' ' + user.attributes.lastname :
        user.attributes.firstname + ' ' + user.attributes.middlename + ' ' + user.attributes.lastname;
    const callingName = user.attributes.calling.data.attributes.name;
    const callingId = user.attributes.calling.id;
    const handleLogin = () => {
        setAuthState('login');
        navigate('/login', { state: { from: location }, replace: true });
    }

    return (
        <>
            <Box
                sx={{
                    marginTop: { xs: -2, lg: 8 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AppRegistrationOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" color="common.white">
                    Registration Success!
                </Typography>
                <Box component="form" p={3} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                    <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box minHeight={180} p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <Stack direction={'column'}>
                                <img src={'/images/checklist-complete.jpg'} style={{ borderRadius: "3px", width: 170, marginLeft: '60px' }} />
                                <Typography variant="h6" gutterBottom sx={{ color: '#555', paddingBottom: 5 }}>
                                    {`Welcome ${title} ${fullname} a ${callingName}`}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Login') } }}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Login
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Login') } }}
                                sx={{ mt: 3, ml: 6, mb: 2 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Stack>
                </Box>

                {/*                 <Stack spacing={2}>
                    <Typography variant="body2" gutterBottom sx={{ color: '#000', paddingBottom: 5 }}>
                        {`You gave calling id: ${props.id}. Your real calling id is: ${callingId}.`}
                    </Typography>
                    <Typography variant="body2" gutterBottom sx={{ color: '#000', paddingBottom: 5 }}>
                        {`${props.pwd}`}
                    </Typography>
                    <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                        <Button
                            type="submit"
                            variant="contained"
                            onClick={handleLogin}
                            sx={{ mt: 3, ml: 6, mb: 2 }}
                        >
                            Login
                        </Button>
                    </Box>
                </Stack> */}
            </Box>
        </>
    )
}

export default RegisterSuccess
