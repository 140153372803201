import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
//import { AuthProvider } from './context/AuthProvider';
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client';
import { CMSHOST } from './utils/http-common';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './context/AuthProvider';

// apollo graphql client
const client = new ApolloClient({
  uri: CMSHOST + '/graphql',
  cache: new InMemoryCache()
})

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
      {/*    <ApolloProvider client={client}> */}
      <AuthProvider>
        <Routes>
          <Route path='/*' element={<App />} />
        </Routes>
      </AuthProvider>
      {/* </ApolloProvider> */}
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals(console.log);
