import { useState, useEffect, useRef, useMemo } from 'react';
import { useParams } from "react-router-dom";
import { Container, Grid, Stack, Box, Typography, Button } from '@mui/material';
import axios from '../../api/axios';
import Avatar from '@mui/material/Avatar';
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { FormHelperText, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const NEWPWD_URL = '/newpwd';
const VALIDATE_URL = '/validate';
const DEBUG_ON = true;

const PWD_REGEX = /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@&#<>;()^$%]).{8,24}$/;
const PWD_HELPER_TEXT = 'Minimum eight characters, at least one upper case letter, one lower case letter, one number and one special character'

const styles = {
    password: { width: '35ch' }
}

function Copyright(props) {
    return (
        <Typography variant="body2" color="white" align="center" {...props}>
            {'azcrm.org - Copyright © 2021-'}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const ResetPwd = () => {
    const { email, token } = useParams();

    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [showPwd, setShowPwd] = useState(false);


    const [matchPwd, setMatchPwd] = useState('');
    const [validMatch, setValidMatch] = useState(false);
    const [matchFocus, setMatchFocus] = useState(false);
    const [showMatchPwd, setShowMatchPwd] = useState(false);
    const [errMsg, setErrMsg] = useState('');

    const pwdRef = useRef();
    const errRef = useRef();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    useEffect(() => {
        if (!email || !token) navigate('/', { state: { from: location }, replace: true });
        async function validateToken() {
            try {
                console.log(JSON.stringify({ email, token }));
                const response = await axios.get(VALIDATE_URL,
                    JSON.stringify({ email, token }),
                    {
                        headers: { 'Content-Type': 'application/json' },
                        withCredentials: false
                    }
                );
                console.log(JSON.stringify(response));

                //navigate(from, { replace: true });
            } catch (err) {
                if (!err?.response) {
                    setErrMsg('No Server Response');
                } else if (err.response?.status === 400) {
                }
            }
        }
        //validateToken();
        pwdRef.current.focus();
    }, [])

    useEffect(() => {
        const result = PWD_REGEX.test(pwd);
        //console.log(`Valid Password: ${result}`);
        //console.log(`Valid Matching Password: ${pwd === matchPwd}`);
        //console.log(pwd);
        setValidPwd(result);
        setValidMatch(pwd === matchPwd);
    }, [pwd, matchPwd])

    useEffect(() => {
        setErrMsg('');
    }, [email, pwd, matchPwd])

    function PasswordHelperText() {
        const { focused } = useFormControl() || {};

        const helperText = useMemo(() => {
            if (focused && !validPwd) {
                return PWD_HELPER_TEXT;
            }
            return '';
        }, [focused, validPwd]);

        return <FormHelperText sx={{ color: '#f00' }}>{helperText}</FormHelperText>;
    }

    //password mgmt
    const handleClickShowPwd = () => setShowPwd((show) => !show);
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };
    const handleClickShowMatch = () => setShowMatchPwd((show) => !show);
    const handleMouseDownMatch = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        console.log(`Email before fetch: ${email}`);
        try {
            console.log(JSON.stringify({ email, pwd }));
            const response = await axios.post(NEWPWD_URL,
                JSON.stringify({ email, token, pwd }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false
                }
            );
            console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.accessToken;
            const accessLevel = response?.data?.accessLevel;

            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(`Login Failed : ${err.message}`);
            }
            errRef.current.focus();
        }
    }
    return (
        <>
            <Grid container sx={{ height: '88vh', mt: { xs: 0, md: 2 } }}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ p: 2, mt: { xs: 0, md: 3 } }}>
                        <img src={'/images/acrm-title.png'}
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '80%'
                            }} />
                        {/* <Typography sx={{ typography: { xs: 'h6', md: 'h4' } }}>{title}</Typography> */}
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'flex', md: 'flex' }, flexDirection: "column", justifyContent: "left", alignItems: "center" }}>
                    <Container component="main" maxWidth="xs">
                        <Box sx={{
                            marginTop: { xs: -2, lg: 8 },
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                        }} >
                            <Typography variant="h6" ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</Typography>
                            <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                                <LockOutlinedIcon />
                            </Avatar>
                            <Typography component="h1" variant="h5" color="common.white">
                                Change Password
                            </Typography>
                        </Box>
                        <Box component="form" p={3} onSubmit={handleSubmit} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                            <Stack spacing={2}>
                                <FormControl sx={styles.password} size="small" variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showPwd ? 'text' : 'password'}
                                        inputRef={pwdRef}
                                        onChange={(e) => setPwd(e.target.value)}
                                        onFocus={() => setPwdFocus(true)}
                                        onBlur={() => setPwdFocus(false)}
                                        required={true}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPwd}
                                                    onMouseDown={handleMouseDownPwd}
                                                    edge="end"
                                                >
                                                    {showPwd ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Password"
                                    />
                                    <PasswordHelperText />
                                </FormControl>
                                <FormControl sx={styles.password} size="small" variant="outlined">
                                    <InputLabel htmlFor="outlined-adornment-password">Confirm Password</InputLabel>
                                    <OutlinedInput
                                        id="outlined-adornment-password"
                                        type={showMatchPwd ? 'text' : 'password'}
                                        onChange={(e) => setMatchPwd(e.target.value)}
                                        required={true}
                                        endAdornment={
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle match password visibility"
                                                    onClick={handleClickShowMatch}
                                                    onMouseDown={handleMouseDownMatch}
                                                    edge="end"
                                                >
                                                    {showMatchPwd ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        }
                                        label="Confirm Password"
                                    />
                                    <FormHelperText id="match-helper-text" sx={{ color: '#f00' }}>{!validMatch ? "Does not match password" : " "}</FormHelperText>
                                </FormControl>
                                <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                                    <Button
                                        //type="submit"
                                        variant="contained"
                                        disabled={!validPwd || !validMatch ? true : false}
                                        //onClick={() => { { setUsername(email) } }}
                                        //component={Link}
                                        //to={`/regres/${callingId}/${email}/${pwd}/${Date.now()}`}
                                        sx={{ mt: 3, ml: 3, mb: 2 }}
                                    >
                                        Reset Password
                                    </Button>
                                    <Button
                                        variant="contained"
                                        onClick={() => navigate('/', { state: { from: location }, replace: true })}
                                        sx={{ mt: 3, ml: 6, mb: 2 }}
                                    >
                                        Cancel
                                    </Button>
                                </Box>
                            </Stack>
                        </Box>
                    </Container>
                </Grid>
            </Grid>
            <Box>
                <Copyright sx={{ mt: { xs: 2, lg: 8 }, mb: 4 }} />
            </Box>
        </>
    );
}

export default ResetPwd
