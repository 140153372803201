import { useContext } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DataContext from '../../context/DataContext';

const SlideListItem = (slide) => {
    const index = slide.slide.sequenceNo;
    const label = slide.slide.memo.data.attributes.label !== null ?
        slide.slide.memo.data.attributes.label :
        slide.slide.memo.data.attributes.title;
    const { selectedLessonIndex, setSelectedLessonIndex, lessonSlideRef } = useContext(DataContext);

    //console.log(`Slideinfo: ${JSON.stringify(slide)}`)
    console.log(`index: ${index}`);
    console.log(`title: ${label}`);

    const handleListItemClick = (event, index) => {
        console.log(`clicked: ${index}`);
        setSelectedLessonIndex(index);
        lessonSlideRef.current = index - 1;
    };

    return (
        <ListItem key={index} component="div" disablePadding>
            <ListItemButton
                selected={selectedLessonIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
            >
                <ListItemText primary={`${index}. ${label}`} />
            </ListItemButton>
        </ListItem>
    );
}

export default SlideListItem
