import React from 'react'
import { Box } from '@mui/material'
import DirectoryFilter from './DirectoryFilter'
import DrawerMenu from './navigation/DrawerMenu'

const DirectoryFilterPanel = (props) => {
    return (
        <>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <DirectoryFilter list={props.list} />
            </Box>
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                <DrawerMenu list={props.list} />
            </Box>
        </>
    )
}

export default DirectoryFilterPanel
