import { useContext } from 'react'
import { Grid, Box, Container, Paper, Typography } from '@mui/material';
import useGetNotifications from '../../hooks/useGetNotifications';
import DataContext from '../../context/DataContext';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import NotificationList from './NotificationList';
import Notification from './Notification';

const DEBUG_ON = false;

const NotificationsViewer = () => {
    const { selectedNotificationIndex, setSelectedNotificationIndex, notificationRef } = useContext(DataContext);
    const { isLoading, isError, data } = useGetNotifications();
    if (isLoading) {
        console.log(`Loading notifications data...`)
        return <></>;
    }
    if (isError) {
        console.log('Error loading notifications');
        return <></>;
    } else {
        DEBUG_ON && console.log(JSON.stringify(data))
        const notificationObj = data?.data.Notifications.notification;
        const notificationAttr = notificationObj.attributes;
        const notificationsArray = notificationAttr.notification;
        const title = notificationAttr.title;
        const memo = notificationsArray[0].memo.data;
        const body1 = memo.attributes.body1;

        return (
            <>
                <Box sx={{ mt: 5, p: 5 }}>
                    <Grid container rowSpacing={2}
                        columnSpacing={2}>
                        {/* <Grid item xs={12} mt={3.5} display="flex" justifyContent="center" alignItems="center">
                            <Typography variant='h4' >{title}</Typography>
                        </Grid> */}
                        <Grid container item xs={12} rowSpacing={1}
                            columnSpacing={1}>
                            <Grid item tm={4} sx={{ display: { xs: 'none', md: 'flex' } }} lg={2}>
                                <Box width='300px'>
                                    <NotificationList listtitle={title} list={notificationsArray} />
                                </Box>
                            </Grid>
                            <Grid item md={12} lg={10}>
                                <Box >
                                    <Container fixed maxWidth='lg'
                                        sx={{ backgroundColor: "#7a9e70" }}>
                                        <Paper>
                                            <Notification notification={notificationsArray[selectedNotificationIndex - 1]} />
                                        </Paper>
                                        {/* <Button onClick={setSlide(1)}>Test</Button> */}
                                    </Container>
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Box>
            </>
        )
    }
}

export default NotificationsViewer
