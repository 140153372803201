import { useState, useEffect, useRef, useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from '../../api/axios';
import { Box, Stack, Typography, Button, TextField } from '@mui/material'
import DataContext from "../../context/DataContext";

const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;
const FORGOT_PWD_URL = '/forgotpwd';

const styles = {
    email: {
        color: '#000',
        "& .MuiFormHelperText-root": {
            color: 'red'
        },
    }
}

const ForgotPwd = () => {
    const { setAuthState, errorMsg } = useContext(DataContext);
    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);

    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        //const result = EMAIL_REGEX.test(email);
        //console.log(`Valid Email: ${result}`);
        //console.log(email);
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
        });
        console.log(`Email before fetch: ${email}`);
        try {
            console.log(JSON.stringify({ email }));
            const response = await axios.post(FORGOT_PWD_URL,
                JSON.stringify({ email }),
                {
                    headers: { 'Content-Type': 'application/json' },
                    withCredentials: false
                }
            );
            console.log(JSON.stringify(response?.data));

        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 403) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(`Submit Failed : ${err.message}`);
            }
            errRef.current.focus();
        }
    }

    return (
        <>
            <Box
                sx={{
                    marginTop: { xs: -2, lg: 8 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" color="common.white">
                    Request New Password
                </Typography>
                <Box component="form" p={3} onSubmit={handleSubmit} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                    <Stack spacing={2}>
                        <Box minHeight={90} p={0} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <Stack direction={'column'}>
                                {/* <img src={'/images/forgot-password.jpg'} style={{ borderRadius: "3px", width: 170, marginLeft: '0px' }} /> */}
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                                    <Typography variant="h6" color='primary'>
                                        {/* {errorMsg} */}
                                        Forgot Password
                                    </Typography>
                                </Box>
                                <Typography variant="body2" color='black'>
                                    {/* {errorMsg} */}
                                    Please enter your login email address below and click the Change Password button.
                                </Typography>
                            </Stack>
                        </Box>
                        <Box component="form" px={3} onSubmit={handleSubmit} noValidate sx={{ mt: 0, backgroundColor: '#fff' }}>
                            <TextField
                                required
                                id="email"
                                name="email"
                                label="Email"
                                inputRef={emailRef}
                                fullWidth
                                size="small"
                                variant="outlined"
                                onChange={(e) => setEmail(e.target.value)}
                                error={!validEmail}
                                helperText={email !== '' && !validEmail ? "Invalid email address" : ""}
                                sx={styles.email}
                            />
                        </Box>
                        <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                            <Button
                                type="submit"
                                disabled={!validEmail ? true : false}
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Reset My Password
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Login') } }}
                                sx={{ mt: 3, ml: 6, mb: 2 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: '#0f456f' }}>
            </Box>
        </>
    )
}

export default ForgotPwd
