import React from 'react'
import { Box, Card, CardMedia, CardContent, CardActions, Button } from '@mui/material';
import { Typography } from '@mui/material';
import { Link } from 'react-router-dom';

const PushCard = (props) => {
    const MAX_WIDTH = 300;
    const CONTENT_HEIGHT = 140;
    const IMAGE_HEIGHT = props.imgHeight;
    //const IMAGE_HEIGHT = '30vh';
    const IMG_BACKGROUND_COLOR = props.imgBackgroundColor === undefined || props.imgBackgroundColor === '' ? '#555555' : props.imgBackgroundColor;
    return (
        <Box pb={3} width='100%' height='50vh' sx={{ display: 'flex', justifyContent: 'center' }}>
            <Card sx={{ maxWidth: MAX_WIDTH }}>
                <CardMedia
                    component='img'
                    height={IMAGE_HEIGHT}
                    alt={props.imgAlt}
                    sx={{
                        padding: "0em 0em 0 0em",
                        objectFit: "contain",
                        backgroundColor: IMG_BACKGROUND_COLOR
                    }}
                    image={props.imgUrl}
                    title={props.imgTitle}
                />
                <CardContent sx={{ height: CONTENT_HEIGHT }}>
                    <Typography variant="subtitle2" component="div" color='black'>
                        {props.title}
                    </Typography>
                    <Typography variant="subtitle3" component="div" color='black'>
                        {props.subtitle}
                    </Typography>
                    <Typography variant="body2" color="text.secondary">
                        {props.body}
                    </Typography>
                </CardContent>
                <CardActions >
                    <Button size="small" color='primary'
                        component={Link}
                        to={props.link}>
                        {props.actionLabel}</Button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default PushCard
