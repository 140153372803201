import * as React from 'react';
import MenuList from '@mui/material/MenuList';
import { Stack, Typography } from '@mui/material';
import SectionMenuItem from './SectionMenuItem';

const DEBUG_ON = false;

export default function SectionMenu(props) {
    DEBUG_ON && console.log(`section menu props: ${JSON.stringify(props)}`);
    return (
        <Stack direction="column" spacing={0}
            sx={{
                width: 250,
                maxHeight: 220,
                margin: 1,
                padding: 2,
                border: '1px solid black',
                borderRadius: '12px',
                backgroundColor: '#ffffff'
            }}>
            <Typography color='black' mt={-1} sx={{ p: 0 }}>{props.section.title}</Typography>
            <MenuList dense sx={{ backgroundColor: "white" }}>
                {props.section.links.map((link, i) => {
                    return (<SectionMenuItem key={i} link={link} />)
                })}
            </MenuList>
        </Stack>
    );
}