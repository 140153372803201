import { Grid } from "@mui/material";
import { Paper } from "@mui/material";

export default function MenuImageGrid(props) {
    const scnWidth = 160;
    const scnHeight = scnWidth * 1.1;
    console.log(`props: ${JSON.stringify(props)}`);
    return (
        <Grid container justifyContent="center" spacing={2} sx={{ flexGrow: 1, paddingTop: { xs: 10, lg: 2 }, paddingBottom: { xs: 10, lg: 2 } }} >
            {/* <Grid item xs={12}>
                <Grid container justifyContent="center" spacing={2}> */}
            {props.imageList.map((image, i) => (
                <Grid key={i} item >
                    <Paper
                        sx={{
                            height: scnHeight,
                            width: scnWidth,
                            margin: "auto",
                            backgroundColor: (theme) =>
                                theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
                        }}
                        variant={"elevation"}
                        elevation={1}
                    >
                        <a href={image.redirectUrl}><img src={image.src} style={{ borderRadius: "3px", width: scnWidth }} /></a>
                    </Paper>
                    <div>
                        <div style={{ textAlign: "center" }}>
                            <p style={{ fontSize: "15px", width: scnWidth, color: '#fff' }}>{image.title}</p>
                        </div>
                    </div>
                </Grid>
            ))}
            {/*          </Grid>
            </Grid> */}
        </Grid>
    );
}