import React from 'react'
import { Box } from '@mui/material';
import SvgIcon from "@mui/material/SvgIcon";

const BorderedTextLabel = ({ icon, title, children }) => {
    const borderColor = '#b2b2b2';
    return (
        <Box className='mainContainer' sx={{
            display: 'flex',
            flexDirection: 'column',
            maxWidth: '100%',

            borderLeft: `1px solid ${borderColor}`,
            borderBottom: `1px solid ${borderColor}`,
            borderRight: `1px solid ${borderColor}`,
            borderRadius: '5px',
            margin: '0em'
        }}>
            <Box className='header' sx={{
                display: 'flex',
                flexDirection: 'row',
                width: '100% !important'
            }}>
                <Box className='headerBorderBefore' sx={{
                    borderTop: `1px solid ${borderColor}`,
                    width: '0.5em',
                    borderTopLeftRadius: '5px'
                }}></Box>
                {(icon || title) && (
                    <Box className='headerTitle' sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        flexWrap: 'nowrap',
                        alignItems: 'center',
                        gap: '0.25em',
                        width: 'fit-content',
                        height: '2em',
                        margin: '-1em 0.5em 0em 0.5em',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        fontSize: '0.8em',
                        fontWeight: 400
                    }}>
                        {icon && <SvgIcon component={icon} />}
                        {title && <span className={'title'} sx={{

                        }}>{title}</span>}
                    </Box>
                )}
                <Box className={'headerBorderAfter'} sx={{
                    borderTop: `1px solid ${borderColor}`,
                    width: '1em',
                    flexGrow: 2,
                    borderTopRightRadius: '5px'
                }}></Box>
            </Box>
            <Box className='childrenContainer' sx={{
                display: 'block',
                pb: '10px',
                ml: '17px'
            }}>{children}</Box>
        </Box>
    )
}

export default BorderedTextLabel
