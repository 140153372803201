import { useState } from "react";
import { styled, useTheme } from '@mui/material/styles';
import { Chip, Stack, Drawer, SwipeableDrawer, Box, Typography } from "@mui/material";
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import DirectoryFilter from "../DirectoryFilter";

const drawerWidth = 270;

const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(
    ({ theme, open }) => ({
        flexGrow: 1,
        padding: theme.spacing(3),
        transition: theme.transitions.create('margin', {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
        }),
        marginLeft: `-${drawerWidth}px`,
        ...(open && {
            transition: theme.transitions.create('margin', {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
            }),
            marginLeft: 0,
        }),
    }),
);

const DrawerHeader = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'left',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
    justifyContent: 'flex-end',
}));

const DrawerMenu = (props) => {
    const [isDrawerOpen, setIsDrawerOpen] = useState(false);
    const theme = useTheme();

    const handleDrawerOpen = () => {
        setIsDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
    };

    return (
        <>
            <Chip
                icon={<FilterAltIcon sx={{
                    color: "#ffff00"
                }} />}
                label="Filter"
                variant="outlined"
                onClick={handleDrawerOpen}
                sx={{
                    backgroundColor: '#10456F',
                    color: 'white'
                }}
            />
            <SwipeableDrawer
                anchor='left'
                open={isDrawerOpen}
                variant="persistent"
                onClose={() => setIsDrawerOpen(false)}
                onOpen={() => setIsDrawerOpen(true)}
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    '& .MuiDrawer-paper': {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Box
                    p={2}
                    backgroundColor='#10456F'
                >

                    <DrawerHeader>
                        {/*                        <Stack> */}
                        <Typography mt={1.3} variant='h6' component='div'>
                            Filter Panel
                        </Typography>
                        <IconButton onClick={handleDrawerClose}>
                            {theme.direction === 'ltr' ?
                                <ChevronLeftIcon sx={{
                                    color: "white"
                                }} /> :
                                <ChevronRightIcon sx={{
                                    color: "white"
                                }} />}
                        </IconButton>
                        {/*                         </Stack>
 */}                    </DrawerHeader>
                    <DirectoryFilter />
                </Box>
            </SwipeableDrawer>
        </>
    )
}

export default DrawerMenu
