import React from 'react'
import { Container, Stack, Grid, Box, Typography } from '@mui/material';
import EmbeddedVideo from './memos/EmbeddedVideo';
import ImageCenter from './memos/ImageCenter';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { fetchMemo } from "../utils/http-common";
import { useQuery } from '@tanstack/react-query';
import { CMSHOST } from '../utils/http-common';

const Memo = ({ memoid }) => {
    //console.log(`memo id: ${memoid}`);
    const { isLoading, isError, error, data } = useQuery(['memo', memoid], () => fetchMemo(memoid));
    if (isLoading) {
        console.log('loading memo request...')
        return [];
    }
    if (isError) {
        console.log(`Error loading memo: ${error.message}`);
        return [];
    } else {
        const memodata = data.data.data;
        const memo = memodata.attributes;
        const imageLoc = memo.imageloc;
        const image = memo.image.data;
        const imageSmall = image ? image.attributes.formats?.small : null;
        const imageFormat = imageSmall ? imageSmall :
            image ? image.attributes.formats?.thumbnail : null;
        const imageUrl = imageFormat ? `${CMSHOST + imageFormat.url}` : null;
        console.log(`image URL: ${imageUrl}`);
        const imageAlt = memo.image.data?.attributes.alternativeText;
        const imageHeight = imageFormat ? imageFormat.height : 0;
        const imageWidth = imageFormat ? imageFormat.width : 0;
        const backgroundImage = memo.backgroundimage.data;
        const backgroundImageUrl = `${CMSHOST + memo.backgroundimage.data?.attributes.formats?.large.url}`;
        const backgroundImageAlt = memo.backgroundimage.data?.attributes.alternativeText;
        const backgroundImageHeight = memo.backgroundimage.data?.attributes.formats?.large.height;
        const backgroundImageWidth = memo.backgroundimage.data?.attributes.formats?.large.width;
        const body1 = memo.body1;
        const body2 = memo.body2;
        const embeddedVideoLoc = memo.embeddedvideoloc;
        const embeddedVideo = JSON.parse(memo.embeddedvideo);
        const embeddedVideoStrLoc = memo.embeddedvideostrloc;
        const embeddedVideoStr = memo.embeddedvideostr;
        let videohtml = null;
        let videoloc = 'Top';
        if (embeddedVideoStr === undefined) {
            videohtml = embeddedVideo?.rawData === undefined ? null : embeddedVideo?.rawData.html;
            videoloc = embeddedVideoLoc;
            //console.log(`embeddedVideo: ${JSON.stringify(embeddedVideo)}`);
        } else {
            videohtml = embeddedVideoStr;
            videoloc = embeddedVideoStrLoc;
        }
        console.log(`embeddedVideo html: ${videohtml}`);

        return (
            <Container maxWidth={false} mb={10}
                sx={{
                    maxWidth: '1000px',
                    display: 'flex',
                    flexDirection: 'row',
                    backgroundImage: `url(${backgroundImageUrl})`,
                    backgroundRepeat: "no-repeat",
                    backgroundColor: "white",
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    height: '75vh',
                    width: "100%",
                    overflow: "hidden",
                    overflowY: "scroll",
                    color: 'white',

                }}
            >
                {/*   <Grid container mt={10} spacing={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}> */}
                <Stack >
                    <Box mt={2}>
                        <Typography variant="title" color="black" >{memo.title}</Typography>
                    </Box>

                    {/*                     <Grid item xs={12} mb={3} sx={{ maxHeight: '14px', zIndex: 10 }}>
                        <Box>
                            <Typography variant="title" color="black" >{memo.title}</Typography>
                        </Box>
                    </Grid>
                    <Grid item xs={12} mt={2}> */}
                    {/*                     {backgroundImage !== null ? <ImageCenter
            height={backgroundImageHeight}
            width={backgroundImageWidth}
            maxHeight={backgroundImageHeight}
            maxWidth={backgroundImageWidth}
            alt={backgroundImageAlt}
            src={backgroundImageUrl}
            sx={{ position: 'relative' }}
        /> : <></>} */}
                    {image !== null && imageLoc === 'Top' ? <ImageCenter
                        height={imageHeight}
                        width={imageWidth}
                        maxHeight={imageHeight}
                        maxWidth={imageWidth}
                        alt={imageAlt}
                        src={imageUrl}
                    /> : <></>}
                    {videohtml !== null && videoloc === 'Top' ?
                        <EmbeddedVideo videohtml={videohtml} /> :
                        <></>}
                    {/* <Typography variant="h3" mt={3} >{props.slide.title}</Typography> */}
                    {
                        body1 === undefined ?
                            <></> :
                            <Typography variant="body" mt={3} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body1} renderers={{ paragraph: props => <div {...props} />, }} /></Typography>
                    }
                    {image !== null && imageLoc === 'Center' ? <ImageCenter
                        height={imageHeight}
                        width={imageWidth}
                        maxHeight={imageHeight}
                        maxWidth={imageWidth}
                        alt={imageAlt}
                        src={imageUrl}
                    /> : <></>}
                    {videohtml !== null && videoloc === 'Center' ?
                        <EmbeddedVideo videohtml={videohtml} /> :
                        <></>}
                    {
                        body2 === undefined ?
                            <></> :
                            <Typography variant="body" mt={3} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body2} renderers={{ paragraph: props => <div {...props} />, }} /></Typography>
                    }
                    {image !== null && imageLoc === 'Bottom' ? <ImageCenter
                        height={imageHeight}
                        width={imageWidth}
                        maxHeight={imageHeight}
                        maxWidth={imageWidth}
                        alt={imageAlt}
                        src={imageUrl}
                    /> : <></>}
                    {videohtml !== null && videoloc === 'Bottom' ?
                        <EmbeddedVideo videohtml={videohtml} /> :
                        <></>}
                    {/* </Grid> */}
                    {/*  <Grid item xs={12} mt={5} >
                        <Grid  container direction="column" >
                            <Box>
                               
                            </Box>
                        </Grid>
                    </Grid> */}
                    {/* </Grid> */}
                </Stack>
            </Container>
        )
    }
}

export default Memo
