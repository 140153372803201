import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import useAxiosPrivate from '../hooks/useAxiosPrivate';
import axios from '../api/axios';

const DEBUG_ON = true;

const REGCHK_URL = '/regchk';
const REGISTER_URL = '/register';
const NOTIFICATIONS_URL = '/notifications';
const SETPWD = './newpwd';



//export const CMSHOST = 'http://192.168.1.62:1337';
//export const UIHOST = 'http://192.168.1.62:3210';

//export const CMSHOST = 'http://127.0.0.1:3500';
//export const UIHOST = 'http://127.0.0.1:3210';

//const { auth } = useAuth();

/* const apiClient = axios.create({
    baseURL: CMSHOST,
    headers: {
        //Authorization: `Bearer ${auth.accessToken}`,
        //'Content-type': "application/json"
    },
    withCredentials: true
}); */

export const validateRegister = async (email, cid) => {
    let person = null;
    let passed = false;
    let errMsg = '';
    let errCode = '';
    try {
        const response = await axios.post(REGCHK_URL,
            JSON.stringify({ email, cid }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: false
            }
        );
        console.log(`reg check response: ${JSON.stringify(response?.data)}`);
        person = response?.data;
        errCode = response?.data.errorCode;
        errMsg = response?.data.errorMsg;
        if (errCode === 201) passed = true;
    } catch (err) {
        console.log(err?.response.data.message);
        console.log(err?.response.status);
        //console.log(err?.response.headers);
        if (!err?.response) {
            //navigate('/login', { state: { from: location }, replace: true });

            errMsg = 'No Server Response';
            errCode = 500;
        } else if (err.response?.status === 400) {
            errCode = 400;
            errMsg = 'Could not register. The information was incomplete.';
        } else if (err.response?.status === 401) {
            errCode = 401;
            errMsg = 'Unauthorized';
        } else if (err.response?.status === 403) {
            // calling does not match
            errCode = 403;
            errMsg = `Registration information submitted does not match our records.`;
        } else if (err.response?.status === 404) {
            // email doesn't exist
            errCode = 404;
            errMsg = `Registration information submitted does not match our records.`;
        } else if (err.response?.status === 405) {
            // already registered
            errCode = 404;
            errMsg = `Our records indicate you are already registered.`;
        } else {
            errMsg = `Registration Failed: ${err.message}`;
        }
    }
    return { person, passed, errCode, errMsg };
}

export const registerNewUser = async (uid, pwd) => {
    let success = false;
    let errMsg = '';
    let errCode = '';
    try {
        const response = await axios.post(REGISTER_URL,
            JSON.stringify({ uid, pwd }),
            {
                headers: { 'Content-Type': 'application/json' },
                withCredentials: false
            }
        );
        console.log(`register response: ${JSON.stringify(response.data)}`);
        errCode = response?.data.errorCode;
        errMsg = response?.data.errorMsg;
        if (errCode === 201) success = true;
    } catch (err) {
        errMsg = err?.response.data.message;
        errCode = err?.response.data.status;
        console.log(errMsg);
        console.log(errCode);
    }
    return { success, errCode, errMsg };
}

export const useFetchPersonWithEmail = async (email) => {
    const axiosPrivate = useAxiosPrivate();
    DEBUG_ON && console.log(`fetching person with email...${email}`);
    return axiosPrivate.get(`/people/email/${email}`)
}

export const useFetchNotifications = async () => {
    const axiosPrivate = useAxiosPrivate();
    DEBUG_ON && console.log(`fetching notifications...`);
    return axiosPrivate.get(`/notifications`)
}