import { useContext } from 'react'
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';
import '@fontsource/lato/700.css';
import Carousel from 'nuka-carousel/lib/carousel';
import Doc from './Doc';
import {
    Container, Grid, Button, Typography,
    Card, CardMedia, CardContent, CardActions,
    Stack
} from '@mui/material'
import { Link } from 'react-router-dom';
import { Box } from '@mui/system'
import DocList from './DocList';
import DataContext from '../../context/DataContext';
import { CMSHOST } from '../../utils/http-common';
import { fetchResourceDocs } from '../../utils/http-common';
import { useQuery } from '@tanstack/react-query';


//import logo from '../logo-white_512.png';

function ResourceDocViewer(props) {
    const { selectedDocIndex, setSelectedDocIndex, docViewRef } = useContext(DataContext);
    const lessonTitle = 'Giving Re-entry Help';
    const lessonSubtitle = 'Getting started - The Key Success Factors';
    const listTitle = 'Getting Started';
    const imgHeight = '120';
    const image1url = CMSHOST + '/uploads/parole_probation2_14ae369ba5.png';
    const image2url = CMSHOST + '/uploads/resources1_110d9750b7.jpg';

    console.log(`props: ${JSON.stringify(props)}`);
    //docViewRef.current = props.no ? props.no : 0;
    //setSelectedDocIndex(props.no ? props.no : 0);
    console.log(`Doc select: ${selectedDocIndex}`);
    const { isLoading, isError, data } = useQuery(['docviewer', props.id], () => fetchResourceDocs(props.id));
    if (isLoading) {
        return <h2>Loading...</h2>
    }
    if (isError) {
        console.log('Error loading doc viewer');
        return <></>
    } else {
        const title = data?.data.data.attributes.title;
        const summary = data?.data.data.attributes.summary;
        const label = data?.data.data.attributes.label;

        //console.log(`curricula title: ${title}`);
        const docArray = data?.data.data.attributes.docs;
        const viewerSize = docArray.length;
        //console.log(`docs: ${docArray}`)

        return (
            <Box sx={{ p: 5, height: '95svh' }}>
                <Grid container rowSpacing={2}
                    columnSpacing={2}>
                    <Grid item xs={12} mt={3.5} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant='h4' >{title}</Typography></Grid>
                    {/*                     <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant='h6'>{summary}</Typography></Grid> */}
                    <Grid container item xs={12} rowSpacing={1}
                        columnSpacing={1}>
                        <Grid item tm={4} sx={{ display: { xs: 'none', md: 'flex' } }} lg={2}>
                            <Box width='300px' sx={{ maxWidth: '300px', overflow: "hidden", overflowY: "scroll", }}>
                                <DocList listtitle={label} list={docArray} />
                            </Box>
                        </Grid>
                        <Grid item md={12} lg={10}>
                            <Box >
                                <Container fixed maxWidth='lg'
                                    sx={{ backgroundColor: "#7a9e70" }}>
                                    <Carousel
                                        height={800}
                                        width={1200}
                                        autoPlay={false}
                                        animation={'slide'}
                                        speed={500}
                                        slideIndex={docViewRef.current}
                                        afterSlide={(index) => setSelectedDocIndex(index + 1)}
                                        navButtonsAlwaysVisible={true}
                                        next={(next, active) => {
                                            setSelectedDocIndex(next + 1);
                                            console.log(`we left ${active}, and are now at ${next}`)
                                        }}
                                        prev={(prev, active) => {
                                            setSelectedDocIndex(prev + 1);
                                            console.log(`we left ${active}, and are now at ${prev}`)
                                        }}
                                    >
                                        {
                                            docArray.map((doc, i) => <Doc key={i} doc={doc} />)
                                        }
                                    </Carousel>
                                    {/* <Button onClick={setSlide(1)}>Test</Button> */}
                                </Container>
                            </Box>
                        </Grid>
                        {/* <Grid item tm={4} sx={{ display: { xs: 'none', md: 'flex' } }} lg={2}>
                            <Stack spacing={2}>
                                <Box width='300px' height='270px'>
                                    <Card sx={{ maxWidth: 240 }}>
                                        <CardMedia
                                            component='img'
                                            height={imgHeight}
                                            alt='Inmate Technology photo'
                                            sx={{ padding: "0em 0em 0 0em", objectFit: "contain" }}
                                            image={image2url}
                                            title="Inmate Technology"
                                        />
                                        <CardContent sx={{ height: 100 }}>
                                            <Typography variant="h6" component="div" color='black'>
                                                Resources
                                            </Typography>
                                                                                <Typography variant="subtitle2" component="div" color='black'>
                                        Enriching the Incarcerated
                                    </Typography>
                                  <Typography variant="body2" color="text.secondary">
                                                Pretium quam vulputate dignissim suspendisse in.
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button component={Link} to="/docview/4" size="small" color='primary'>Details</Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                                <Box width='300px' height='230px'>
                                    <Card sx={{ maxWidth: 240 }}>
                                        <CardMedia
                                            component='img'
                                            height={imgHeight}
                                            alt='Inmate Technology photo'
                                            sx={{ padding: "0em 0em 0 0em", objectFit: "contain" }}
                                            image={image1url}
                                            title="Inmate Technology"
                                        />
                                        <CardContent sx={{ height: 100 }}>
                                            <Typography variant="h6" component="div" color='black'>
                                                Parole Offices
                                            </Typography>
                                            <Typography variant="body2" color="text.secondary">
                                                Pretium quam vulputate dignissim suspendisse in.
                                            </Typography>
                                        </CardContent>
                                        <CardActions>
                                            <Button size="small" color='primary'>Details</Button>
                                        </CardActions>
                                    </Card>
                                </Box>
                            </Stack>
                        </Grid> */}
                    </Grid>
                </Grid>
            </Box>
        )
    }
}

export default ResourceDocViewer
