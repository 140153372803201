import { useContext } from 'react'
//import { useParams, Navigate } from 'react-router-dom';
import { useNavigate, useLocation } from "react-router-dom";
import DataContext from '../../context/DataContext';
import RegisterSuccess from './RegisterSuccess';
import useGetUser from '../../hooks/useGetUser';

const DEBUG_ON = true;

const RegisterChk = (id, email, pwd, ts) => {
    //const { id, email, pwd, ts } = useParams();
    console.log(`email: ${email}`)
    const { user, setUser, setAuthState } = useContext(DataContext);
    const navigate = useNavigate();
    const location = useLocation();
    const { isLoading, isError, data } = useGetUser(email);
    if (isLoading) {
        console.log(`Loading ${email}'s data...`)
        return <></>;
    }
    if (isError) {
        console.log('Error loading person');
        return <></>;
    } else {
        const currentDate = Date.now();
        DEBUG_ON && console.log(JSON.stringify(data))
        const personObj = data?.data.data[0];
        const personAttr = personObj.attributes;
        const firstLogin = personAttr.firstlogin;
        const emailConfirmed = personAttr.dateEmailConfirmed;
        const callingId = personAttr.calling.data.id;
        DEBUG_ON && console.log(`calling id: ${callingId}`);
        if ((currentDate - ts) > 20000) {
            console.log(`current: ${currentDate} - tx: ${ts}`);
            setAuthState('Login');
            navigate('/login', { state: { from: location }, replace: true });
        }
        if (callingId !== id) {
            DEBUG_ON && console.log('Calling does not match!');
            setAuthState('RegFailed');
            navigate('/login', { state: { from: location }, replace: true });
        }
        if (emailConfirmed) {
            DEBUG_ON && console.log('Registration already exists');
            setAuthState('RegExists');
            navigate('/login', { state: { from: location }, replace: true });
        }

        setUser(personObj);

        DEBUG_ON && console.log(`user: ${JSON.stringify(user)}`)
        return (
            <>
                <RegisterSuccess id={id} pwd={pwd} ts={ts} />
            </>
        )
    }
}

export default RegisterChk