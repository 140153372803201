import { useState, useRef, useContext } from 'react'
import Button from '@mui/material/Button';
import { Grid, Stack, Popover, Typography } from '@mui/material';
import { Box, Card, CardMedia, CardContent, CardActions } from '@mui/material';
//import IconButton from '@mui/material/IconButton';
import MoreHorizIcon from "@mui/icons-material/MoreHoriz";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Link } from 'react-router-dom';

import { popoverClasses } from "@mui/material/Popover"

import { fetchSubmenu } from "../../utils/http-common";
import { useQuery } from '@tanstack/react-query';
import MenuPanel from './MenuPanel';
import SectionMenu from './SectionMenu';
import PushMenu from './PushMenu';
import useAuth from '../../hooks/useAuth';
import MenuImageGrid from './MenuImageGrid';
import LessonList from '../LessonList';

const DEBUG_ON = true;

const ButtonPanelMenu = ({ menuid, name, panel }) => {
    const dummyMenuItems = [
        {
            title: "Add Item"
        },
        {
            title: "Move Item"
        },
        {
            title: "Delete Item"
        }
    ];

    const { auth } = useAuth();

    const [anchorEl, setAnchorEl] = useState(null);
    //const entries = useRef([]);
    let currentlyHovering = false;

    function handleClick(event) {
        if (anchorEl !== event.currentTarget) {
            setAnchorEl(event.currentTarget);
        }
    }

    function handleSingleItemClick(event) {
        //<Link to={`/page/${entries.current[0]?.id}`}></Link>
        console.log(event)
    }

    function handleHover() {
        currentlyHovering = true;
    }

    function handleClose() {
        setAnchorEl(null);
    }

    function handleCloseHover() {
        currentlyHovering = false;
        setTimeout(() => {
            if (!currentlyHovering) {
                handleClose();
            }
        }, 50);
    }

    const itemClick = e => {
        console.log("Item Clicked " + e.detail);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'panel-popover' : undefined;
    let totalItems = 0;
    const sections = panel?.sections;
    if (sections) {
        DEBUG_ON && console.log("sections " + JSON.stringify(sections));
        totalItems += sections.length;
    }
    const pushes = panel?.pushes;
    if (pushes) {
        //console.log("pushes " + pushes);
        totalItems += pushes.length;
    }
    const links = panel?.links;
    if (links) {
        //console.log("links " + JSON.stringify(links));
        //totalItems += links.length;
    }
    /*     const totalItems =
            sections ? sections.length : 0 +
                pushes ? pushes.length : 0 +
                    links ? links.length : 0; */
    //console.log(`Total items: ${name}-${totalItems}`);

    if (totalItems > 0) {
        return (
            <>
                <Button
                    sx={{
                        maxHeight: 50,
                        my: 2,
                        mx: 2,
                    }}
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    endIcon={
                        open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                    onClick={handleClick}
                /*  onMouseOver={handleClick}
                 onMouseLeave={handleCloseHover} */
                >
                    {name}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClick={handleClose}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Grid container mt={0.0} spacing={2} sx={{ padding: /* '1em' */'0', maxWidth: '800px', backgroundColor: '#0f3b5f' }} >
                        <Grid container item xs={12} direction='row'>
                            {(sections) && <Box mx={4} mb={2} sx={{
                                border: 1, borderColor: '#fff', borderRadius: "10px", backgroundColor: '#0f456f'
                            }}><LessonList /></Box>
                                /* sections.map((section) => {
                                    if (section.accesslevel >= auth.accessLevel)
                            return (
                            <SectionMenu key={section.id} section={section} />
                            )
                                }) */
                            }
                        </Grid>
                        {/*                         <Grid container item xs={3} mt={3} direction='column'
                            sx={{
                                padding: '1em',
                                backgroundColor: '#999999',
                                ...(10 >= auth.accessLevel && { background_transparency: 0 })
                            }}>
                            {(pushes) &&
                                pushes.map((push) => {
                                    if (10 >= auth.accessLevel)
                                        return (
                                            <PushMenu key={push.id} push={push} />
                                        )
                                })
                            }
                        </Grid>
 */}                        <Grid item xs={12}>
                            {(links) &&
                                links.map((link) => {
                                    return (
                                        <Link key={link.id} to={link.href}>
                                            <Typography color='white' sx={{ p: 2 }}>{link.label}</Typography>
                                        </Link>
                                    )
                                })
                            }

                        </Grid>
                    </Grid>
                </Popover>
            </>
        )
    } else if (links && links.length > 0) {
        //console.log(`links array: ${JSON.stringify(links[0].href)}`);
        const link1 = panel?.link.href;
        const link1name = panel?.link.label;
        return (
            <>
                <Button
                    sx={{
                        maxHeight: 50,
                        my: 2,
                        mx: 2,
                    }}
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    endIcon={
                        open ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                    onClick={handleClick}
                /*  onMouseOver={handleClick}
                 onMouseLeave={handleCloseHover} */
                >
                    {link1name}
                </Button>
                <Popover
                    id={id}
                    open={open}
                    anchorEl={anchorEl}
                    onClick={handleClose}
                    onClose={handleClose}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                >
                    <Stack sx={{ padding: '1em', backgroundColor: '#0f3b5f' }}>
                        {links.map((link) => {
                            return (
                                <Link key={link.id} to={link.href} style={{ textDecoration: 'none' }}>
                                    <Typography color='white' sx={{ pt: 1, pr: 2, pl: 2 }}>{link.label}</Typography>
                                </Link>
                            )
                        })
                        }
                    </Stack>
                </Popover>
            </>
        )
    } else {
        const link1 = panel?.link.href;
        const link1name = panel?.link.label;
        return (
            <>
                <Button
                    component={Link}
                    to={link1}
                    sx={{
                        maxHeight: 50,
                        my: 2,
                        mx: 2,
                        display: 'block'
                    }}
                    aria-owns={anchorEl ? "simple-menu" : undefined}
                    aria-haspopup="true"
                    aria-describedby={id}
                >
                    {link1name}
                </Button>
            </>
        )
    }
}

export default ButtonPanelMenu
