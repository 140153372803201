import { Grid, Box, Stack, Card, Typography, Container } from '@mui/material'
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import Profile from './surfaces/Profile';
import profile from '../profile-placeholder-silhouette.png';
import profile_female from '../profile-placeholder-silhouette-female.png'
import profile_kjh from '../profile-kjh.png';

const CardGrid = () => {
    const width = '300px';
    const height = '430px';
    const maxWidth = 300;
    const imgHeight = '140';
    const contentHeight = 240;
    const description = 'The Arizona Correctional Committee is one of many faith and community organizations that work together to help those affected by incarceration. This committee works to support the needs and resources of the many branches throughout the state of Arizona and provide help where appropriate.'
    return (
        <Container maxWidth='lg'>
            <Grid container spacing={2} mt={6}>
                <Grid item xs={12}>
                    <Typography gutterBottom variant="h3" component="div" >
                        Committee Members
                    </Typography>
                </Grid>
                <Grid container item xs={12} spacing={2} >
                    <Grid item xs={12} md={6} lg={4}>
                        {/* <Stack >
                            <Typography gutterBottom variant="h4" component="div" >
                                Committee Members
                            </Typography>
                            <Typography variant="subtitle1" component="div" >
                                {description}
                            </Typography>
                        </Stack> */}
                        <Profile
                            width={width}
                            height={height}
                            maxWidth={maxWidth}
                            image={profile}
                            imgHeight={imgHeight}
                            alt='Dave LeSueur photo'
                            name='Dave LeSueur'
                            title='Committee Chairman'
                            body='Lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus viverra'
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box width='300px' height='430px'>
                            <Card sx={{ maxWidth: 300 }}>
                                <CardMedia
                                    component='img'
                                    height={imgHeight}
                                    alt='Nancy LeSueur photo'
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                    image={profile_female}
                                    title=" Nancy LeSueur"
                                />
                                <CardContent sx={{ height: 240 }}>
                                    <Typography variant="h6" component="div" color='black'>
                                        Nancy LeSueur
                                    </Typography>
                                    <Typography variant="subtitle2" component="div" color='black'>
                                        Committee Member
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus viverra
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <Box width='300px' height='430px'>
                            <Card sx={{ maxWidth: 300 }}>
                                <CardMedia
                                    component='img'
                                    height={imgHeight}
                                    alt='Del Alvarez photo'
                                    sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                    image={profile}
                                    title="Del Alvarez"
                                />
                                <CardContent sx={{ height: 240 }}>
                                    <Typography variant="h6" component="div" color='black'>
                                        Del Alvarez
                                    </Typography>
                                    <Typography variant="subtitle2" component="div" color='black'>
                                        Vice Committee Chairman
                                    </Typography>
                                    <Typography variant="body2" color="text.secondary">
                                        Has served in the church prison ministry for 9 years as a branch president and committee chairman. Pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla facilisi morbi.
                                    </Typography>
                                </CardContent>
                                <CardActions>
                                    <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                                </CardActions>
                            </Card>
                        </Box>
                    </Grid>
                    <Grid item xs={4}></Grid>
                </Grid>
                {/* <Grid container item xs={12} spacing={2} > */}
                <Grid item xs={12} md={6} lg={4}>
                    <Box width='300px' height='430px'>
                        <Card sx={{ maxWidth: 300 }}>
                            <CardMedia
                                component='img'
                                height={imgHeight}
                                alt='Shawn Van Haren photo'
                                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                image={profile}
                                title="Shawn Van Haren"
                            />
                            <CardContent sx={{ height: 240 }}>
                                <Typography variant="h6" component="div" color='black'>
                                    Shawn Van Haren
                                </Typography>
                                <Typography variant="subtitle2" component="div" color='black'>
                                    Committee Member
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div" color='black'>
                                    Executive Secretary
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Lobortis scelerisque fermentum dui faucibus in ornare quam viverra orci sagittis eu volutpat odio facilisis mauris sit amet massa vitae tortor condimentum lacinia quis vel eros donec ac odio tempor orci dapibus ultrices in iaculis nunc sed augue lacus viverra
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Box width='300px' height='430px'>
                        <Card sx={{ maxWidth: 300 }}>
                            <CardMedia
                                component='img'
                                height={imgHeight}
                                alt='Derek Dietze photo'
                                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                image={profile}
                                title="Derek Dietze"
                            />
                            <CardContent sx={{ height: 240 }}>
                                <Typography variant="h6" component="div" color='black'>
                                    Derek Dietze
                                </Typography>
                                <Typography variant="subtitle2" component="div" color='black'>
                                    Committee Member
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div" color='black'>
                                    Clerk
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Pretium quam vulputate dignissim suspendisse in est ante in nibh mauris cursus mattis molestie a iaculis at erat pellentesque adipiscing commodo elit at imperdiet dui accumsan sit amet nulla facilisi morbi tempus iaculis urna id volutpat lacus laoreet non.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Grid>
                <Grid item xs={12} md={6} lg={4}>
                    <Box width='300px' height='430px'>
                        <Card sx={{ maxWidth: 300 }}>
                            <CardMedia
                                component='img'
                                height={imgHeight}
                                alt='Kevin Hancock photo'
                                sx={{ padding: "1em 1em 0 1em", objectFit: "contain" }}
                                image={profile_kjh}
                                title="Kevin Hancock"
                            />
                            <CardContent sx={{ height: 240 }}>
                                <Typography variant="h5" component="div" color='black'>
                                    Kevin Hancock
                                </Typography>
                                <Typography variant="subtitle2" component="div" color='black'>
                                    Committee Member
                                </Typography>
                                <Typography gutterBottom variant="subtitle2" component="div" color='black'>
                                    Transitions, Re-entry Specialist
                                </Typography>
                                <Typography variant="body2" color="text.secondary">
                                    Has served in the church prison ministry for 5 years as a branch first councilor, transitions and committee member. Loves serving his Savior Jesus Christ and helping the formally incarcerated re-enter society successfully. Prior church callings include bishop, scoutmaster, teacher.
                                </Typography>
                            </CardContent>
                            <CardActions>
                                <Button size="small" color='primary'><MailOutlinedIcon />Contact</Button>
                            </CardActions>
                        </Card>
                    </Box>
                </Grid>
                {/*   </Grid> */}
            </Grid>
        </Container>
    )
}

export default CardGrid
