import React from 'react'
import { Grid, Box, Paper, Stack, Typography, Container } from '@mui/material'
import EmbeddedVideo from '../memos/EmbeddedVideo';
import ImageCenter from '../memos/ImageCenter';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { CMSHOST } from '../../utils/http-common';

const Slide = (props) => {
    //console.log(`Slide props: ${JSON.stringify(props.slide)}`);
    const memo = props.slide.memo.data.attributes;
    const imageLoc = memo.imageloc;
    const image = memo.image.data;
    const imageUrl = memo.image.data?.attributes.formats.small.url === undefined ? '' : `${CMSHOST}${memo.image.data?.attributes.formats.small.url}`;
    //console.log(imageUrl);
    const imageAlt = memo.image.data?.attributes.alternativeText;
    const imageHeight = memo.image.data?.attributes.formats.small.height;
    const imageWidth = memo.image.data?.attributes.formats.small.width;
    const backgroundImage = memo.backgroundimage.data;
    const backgroundImageUrl = memo.backgroundimage.data?.attributes.formats.large.url === undefined ? '' : `${CMSHOST}${memo.backgroundimage.data?.attributes.formats.large.url}`;
    //console.log(backgroundImageUrl);
    const backgroundImageAlt = memo.backgroundimage.data?.attributes.alternativeText;
    const backgroundImageHeight = memo.backgroundimage.data?.attributes.formats.large.height;
    const backgroundImageWidth = memo.backgroundimage.data?.attributes.formats.large.width;
    const body1 = memo.body1;
    const body2 = memo.body2;
    const embeddedVideoLoc = memo.embeddedvideoloc;
    const embeddedVideo = JSON.parse(memo.embeddedvideo);
    const embeddedVideoStrLoc = memo.embeddedvideostrloc;
    const embeddedVideoStr = memo.embeddedvideostr;
    let videohtml = null;
    let videoloc = 'Top';
    if (embeddedVideoStr === undefined) {
        videohtml = embeddedVideo?.rawData === undefined ? null : embeddedVideo?.rawData.html;
        videoloc = embeddedVideoLoc;
        //console.log(`embeddedVideo: ${JSON.stringify(embeddedVideo)}`);
    } else {
        videohtml = embeddedVideoStr;
        videoloc = embeddedVideoStrLoc;
    }
    console.log(`embeddedVideo html: ${videohtml}`);


    //console.log();

    return (
        <Paper
            sx={{
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "white",
                backgroundPosition: "center",
                backgroundSize: "contain",
                height: '77svh',
                width: "96%",
                overflow: "hidden",
                overflowY: 'auto',
                color: memo.theme,
                marginLeft: '30px',
                paddingLeft: '8px',
                paddingRight: '8px'
            }}
        >
            <div>
                <Typography variant="title" mt={3} color="black" sx={{ lineHeight: '1.3' }} >{memo.title}</Typography>
            </div>
            {image !== null && imageLoc === 'Top' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Top' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
            {/* <Typography variant="h3" mt={3} >{props.slide.title}</Typography> */}
            {
                body1 === undefined ?
                    <></> :
                    <Typography variant="body" mt={5} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body1} /></Typography>
            }
            {image !== null && imageLoc === 'Center' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Center' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
            {
                body2 === undefined ?
                    <></> :
                    <Typography variant="body" mt={2} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body2} /></Typography>
            }
            {image !== null && imageLoc === 'Bottom' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Bottom' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
        </Paper>
        /*         <Container maxWidth={false} mb={10}
                    sx={{
                        maxWidth: '1000px',
                        display: 'flex',
                        flexDirection: 'row',
                        backgroundImage: `url(${backgroundImageUrl})`,
                        backgroundRepeat: "no-repeat",
                        backgroundColor: "white",
                        backgroundPosition: "center",
                        backgroundSize: "contain",
                        height: 768,
                        width: "100%",
                        overflow: "hidden",
                        overflowY: "scroll",
                        color: memo.theme,
        
                    }}
                > */
        /*             <Grid container mt={4} spacing={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
                        <Grid item xs={12} sx={{ maxHeight: '14px' }}>
                            <div>
                                <Typography variant="title" mt={3} color="black" >{memo.title}</Typography>
                            </div>
                        </Grid>
                        <Grid item xs={12}>
                            {/*                     {backgroundImage !== null ? <ImageCenter
                                height={backgroundImageHeight}
                                width={backgroundImageWidth}
                                maxHeight={backgroundImageHeight}
                                maxWidth={backgroundImageWidth}
                                alt={backgroundImageAlt}
                                src={backgroundImageUrl}
                                sx={{ position: 'relative' }}
                            /> : <></>} }
        
                        </Grid>
                        <Grid item xs={12} mt={5} >
                            <Grid /* xs={12}   container direction="column" >
                                <Box>
                                    {/* <Button color="primary" variant="contained" className="CheckButton">
                                        {props.slide.link_title}
                                    </Button> }
                                </Box>
                            </Grid>
                        </Grid>
                    </Grid>
                </Container> */
    )

}

export default Slide
