import { Outlet } from "react-router-dom";
import { useState, useEffect } from 'react';
import useRefreshToken from "../../hooks/useRefreshToken";
import useAuth from "../../hooks/useAuth";
import CircularProgress from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

const DEBUG_ON = true;

const PersistLogin = () => {
    const [isLoading, setIsLoading] = useState(true);
    const refresh = useRefreshToken();
    const { auth } = useAuth();

    useEffect(() => {
        const verifyRefreshToken = async () => {
            DEBUG_ON && console.log('verifing refresh token...');
            try {
                await refresh();
                DEBUG_ON && console.log('refresh token verified.');
            } catch (err) {
                console.log('error refreshing token');
                console.error(err);
            } finally {
                setIsLoading(false);
            }
        }
        DEBUG_ON && console.log(`persist auth: ${JSON.stringify(auth)}`);
        !auth?.accessToken ? verifyRefreshToken() : setIsLoading(false);

    }, [])

    useEffect(() => {
        console.log(`isLoading: ${isLoading}`);
        console.log(`accTok: ${JSON.stringify(auth?.accessToken)}`);
    }, [isLoading])

    return (
        <>
            {isLoading
                ? <Box sx={{ display: 'flex' }}>
                    <CircularProgress color="secondary" />
                </Box>
                : <Outlet />
            }
        </>
    )
}

export default PersistLogin
