import { useParams } from "react-router-dom";
import Memo from "./Memo";

const PageMemo = () => {
    const { id } = useParams();
    return (
        <Memo memoid={id} />
    )
}

export default PageMemo
