import { useQuery } from "@tanstack/react-query";
import axios from "../api/axios";
import useAuth from "./useAuth";
//import { useCookies } from "react-cookie";

const DEBUG_ON = true;

const useRefreshToken = () => {
    const { setAuth } = useAuth();
    /* const [cookies, setCookie, removeCookie] = useCookies(['cookie-name']);
    let d = new Date();
    d.setTime(d.getTime() + (15 * 60 * 1000));
    setCookie('test', ';ldsomdl;;sodkkdmc,99078idjj', { expires: d, secure: true });
 */
    const refresh = async () => {

        const response = await axios.get('/refresh', {
            withCredentials: true
        });
        setAuth(prev => {
            console.log('Refreshing token...');
            DEBUG_ON && console.log(JSON.stringify(prev));
            DEBUG_ON && console.log(`email: ${response.data.email}`);
            DEBUG_ON && console.log(`access level: ${response.data.accessLevel}`);
            DEBUG_ON && console.log(`access token: ${response.data.accessToken}`);
            return {
                ...prev,
                email: response.data.email,
                accessLevel: response.data.accessLevel,
                accessToken: response.data.accessToken
            }
        });
        return response.data.accessToken;
    }
    return refresh;
}

export default useRefreshToken
