import {
    Box,
    Checkbox,
    Radio,
    RadioGroup,
    Divider,
    Chip,
    FormControl,
    FormLabel,
    FormGroup,
    FormHelperText,
    FormControlLabel,
} from "@mui/material";
import { useState, useEffect, useContext } from 'react';
import DataContext from '../context/DataContext';


const DirectoryFilter = (props) => {
    const { directory, setDirectory, filteredDir, setFilteredDir } = useContext(DataContext);

    const [regionFilter, setRegionFilter] = useState([])
    const [corrTypeFilter, setCorrTypeFilter] = useState([])
    const [housingTypeFilter, setHousingTypeFilter] = useState([])
    const [supportStakeFilter, setSupportStakeFilter] = useState('all')
    const [prisonBranchFilter, setPrisonBranchFilter] = useState('all')
    console.log({ regionFilter })
    console.log({ corrTypeFilter })
    console.log({ housingTypeFilter })
    console.log({ supportStakeFilter })

    const handleRegionFilterChange = (event) => {
        const index = regionFilter.indexOf(event.target.value)
        if (index === -1) {
            setRegionFilter([...regionFilter, event.target.value])
        } else {
            setRegionFilter(regionFilter.filter((filter) => filter !== event.target.value))
        }
    }

    useEffect(() => {
        function filterbyName(branch) {
            //console.log(`filter value: ${JSON.stringify(branch)}`);
            let count = 0;
            let passesFilters = true;

            // Region Filter
            if ((regionFilter !== undefined) && (regionFilter.length > 0)) {
                passesFilters = passesFilters && regionFilter.some((name) => {
                    //console.log(`Region: ${branch.attributes.region.data.attributes.name} / ${name}`)
                    return name === branch.attributes.region.data?.attributes.name;
                });
            }
            //console.log(`${branch.attributes.name} - made it to step 1`);

            // Correctional Type Filter
            if ((corrTypeFilter !== undefined) && (corrTypeFilter.length > 0)) {
                passesFilters = passesFilters && corrTypeFilter.some((name) => {
                    return branch.attributes.correctionFacilities.data.some((facility) => {
                        //console.log(`Corr Type:${facility.attributes.facility_type.data.attributes.name}/${name}`)
                        return name === facility.attributes.facility_type.data.attributes.name;
                    })
                });
            }

            // Housing Type Filter
            if ((housingTypeFilter !== undefined) && (housingTypeFilter.length > 0)) {
                passesFilters = passesFilters && housingTypeFilter.some((gender) => {
                    return branch.attributes.correctionFacilities.data.some((facility) => {
                        return facility.attributes.housing_types.data.some((type) => {
                            console.log(`Housing Type:${type.attributes.name}/${gender}`)
                            return gender === type.attributes.name;
                        })
                    })
                });
            }

            // Stake Support Filter

            switch (supportStakeFilter) {
                case 'withSupport':
                    //console.log(`Supporting Stakes? ${branch.attributes.agentStake.data.attributes.supportingStakes.data.length}`)
                    passesFilters = passesFilters && ((branch.attributes.agentStake.data.attributes.supportingStakes.data !== undefined) &&
                        branch.attributes.agentStake.data.attributes.supportingStakes.data.length > 0)
                    break;
                case 'withoutSupport':
                    //console.log(`No Supporting Stakes? ${((branch.attributes.agentStake.data.attributes.supportingStakes.data === undefined) ||
                    //    branch.attributes.agentStake.data.attributes.supportingStakes.data.length === 0)}`)
                    passesFilters = passesFilters && ((branch.attributes.agentStake.data.attributes.supportingStakes.data === undefined) ||
                        branch.attributes.agentStake.data.attributes.supportingStakes.data.length === 0)
                    break;
                default:
                //console.log('Include all branches')
                // no changes to filter
            }

            // prison branch Filter

            switch (prisonBranchFilter) {
                case 'onlyPrisonBranches':
                    passesFilters = passesFilters && (branch.attributes.prisonBranch === true)
                    break;
                case 'notPrisonBranches':
                    passesFilters = passesFilters && (branch.attributes.prisonBranch === false)
                    break;
                default:
                //console.log('Include all branches')
                // no changes to filter
            }

            return passesFilters;
        }

        setFilteredDir(
            directory.filter(filterbyName)
        )
    }, [regionFilter, corrTypeFilter, housingTypeFilter, supportStakeFilter, prisonBranchFilter])

    const handleCorrectionalTypeFilterChange = (event) => {
        const index = corrTypeFilter.indexOf(event.target.value)
        if (index === -1) {
            setCorrTypeFilter([...corrTypeFilter, event.target.value])
        } else {
            setCorrTypeFilter(corrTypeFilter.filter((filter) => filter !== event.target.value))
        }
    }

    const handleHousingTypeFilterChange = (event) => {
        const index = housingTypeFilter.indexOf(event.target.value)
        if (index === -1) {
            setHousingTypeFilter([...housingTypeFilter, event.target.value])
        } else {
            setHousingTypeFilter(housingTypeFilter.filter((filter) => filter !== event.target.value))
        }
    }

    const handleSupportStakeFilterChange = (event) => {
        //console.log(`support stake event:`)
        //console.log(event.target.value + ': ' + event.target.checked);
        setSupportStakeFilter(event.target.value); // Radio choice with 1 value, a string
    }

    const handlePrisonBranchFilterChange = (event) => {
        //console.log(`support stake event:`)
        //console.log(event.target.value + ': ' + event.target.checked);
        setPrisonBranchFilter(event.target.value); // Radio choice with 1 value, a string
    }

    return (

        <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <FormControl>
                <Divider>
                    <Chip label="District" />
                </Divider>
                <FormGroup>
                    <FormControlLabel
                        label='North'
                        value='North'
                        control={<Checkbox checked={regionFilter.includes('North')} onChange={handleRegionFilterChange} />}
                    />
                    <FormControlLabel
                        label='Central'
                        value='Central'
                        control={<Checkbox checked={regionFilter.includes('Central')} onChange={handleRegionFilterChange} />}
                    />
                    <FormControlLabel
                        label='South'
                        value='South'
                        control={<Checkbox checked={regionFilter.includes('South')} onChange={handleRegionFilterChange} />}
                    />
                </FormGroup>
            </FormControl>
            <FormControl>
                <Divider>
                    <Chip label="Correctional Type" />
                </Divider>
                <FormGroup>
                    <FormControlLabel
                        label='ADCRR'
                        value='ADCRR'
                        control={<Checkbox checked={corrTypeFilter.includes('ADCRR')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='AOC-Juvenile'
                        value='AOC'
                        control={<Checkbox checked={corrTypeFilter.includes('AOC')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='County Jail'
                        value='County Jail'
                        control={<Checkbox checked={corrTypeFilter.includes('County Jail')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='FCI'
                        value='FCI'
                        control={<Checkbox checked={corrTypeFilter.includes('FCI')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='Private'
                        value='Private'
                        control={<Checkbox checked={corrTypeFilter.includes('Private')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='BIA'
                        value='BIA'
                        control={<Checkbox checked={corrTypeFilter.includes('BIA')} onChange={handleCorrectionalTypeFilterChange} />}
                    />
                </FormGroup>
            </FormControl>
            <FormControl>
                <Divider>
                    <Chip label="Housing Type" />
                </Divider>
                <FormGroup>
                    <FormControlLabel
                        label='Men'
                        value='Men'
                        control={<Checkbox checked={housingTypeFilter.includes('Men')} onChange={handleHousingTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='Women'
                        value='Women'
                        control={<Checkbox checked={housingTypeFilter.includes('Women')} onChange={handleHousingTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='Boys'
                        value='Boys'
                        control={<Checkbox checked={housingTypeFilter.includes('Boys')} onChange={handleHousingTypeFilterChange} />}
                    />
                    <FormControlLabel
                        label='Girls'
                        value='Girls'
                        control={<Checkbox checked={housingTypeFilter.includes('Girls')} onChange={handleHousingTypeFilterChange} />}
                    />
                </FormGroup>
            </FormControl>
            <FormControl>
                <Divider>
                    <Chip label="Supporting Stakes" />
                </Divider>
                <RadioGroup
                    name="supportstakes-radio-buttons-group"
                    value={supportStakeFilter}
                    onChange={handleSupportStakeFilterChange}>
                    <FormControlLabel
                        label='All'
                        value='all'
                        control={<Radio />}
                    />
                    <FormControlLabel
                        label='With Supporting Stakes'
                        value='withSupport'
                        control={<Radio />}
                    />
                    <FormControlLabel
                        label='Without Supporting Stakes'
                        value='withoutSupport'
                        control={<Radio />}
                    />
                </RadioGroup>
            </FormControl>
            <FormControl>
                <Divider>
                    <Chip label="Correctional Branches" />
                </Divider>
                <RadioGroup
                    name="prisonbranch-radio-buttons-group"
                    value={prisonBranchFilter}
                    onChange={handlePrisonBranchFilterChange}>
                    <FormControlLabel
                        label='All'
                        value='all'
                        control={<Radio />}
                    />
                    <FormControlLabel
                        label='Only Correctional Branches'
                        value='onlyPrisonBranches'
                        control={<Radio />}
                    />
                    <FormControlLabel
                        label='Not Correctional Branches'
                        value='notPrisonBranches'
                        control={<Radio />}
                    />
                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export default DirectoryFilter
