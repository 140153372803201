import { useContext } from 'react'
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import { Typography } from '@mui/material';
import DataContext from '../../context/DataContext';

const DocListItem = (props) => {
    //console.log(`list item: ${JSON.stringify(doc)}`);
    //const index = doc.doc.sequenceNo;
    const { index, doc } = props;
    //console.log(`index: ${index}`);
    //console.log(`doc: ${JSON.stringify(doc)}`);
    //const index = key;
    const label = doc.doc.data?.attributes.title;
    const author = doc.doc.data?.attributes.author;
    //const size = doc.doc.doc.data?.attributes.file.data?.attributes.size + 'k';
    const { selectedDocIndex, setSelectedDocIndex, docViewRef } = useContext(DataContext);

    //console.log(`Docinfo: ${JSON.stringify(doc)}`)
    console.log(`index: ${index}`);
    console.log(`title: ${label}`);
    console.log(`author: ${author}`);
    //console.log(`size: ${size}`);

    const handleListItemClick = (event, index) => {
        console.log(`clicked: ${index}`);
        setSelectedDocIndex(index);
        docViewRef.current = index - 1;
    };

    return (
        <ListItem key={index} component="div" disablePadding>
            <ListItemButton
                selected={selectedDocIndex === index + 1}
                onClick={(event) => handleListItemClick(event, index + 1)}
            >
                <ListItemText primary={`${index + 1}. ${label}`}
                    secondary={<Typography variant="body2" style={{ color: '#bbbbbb', fontStyle: 'italic' }}>Source: {author}</Typography>} />
            </ListItemButton>
        </ListItem>
    );
}

export default DocListItem
