import { Box, Typography } from '@mui/material';
import List from '@mui/material/List';
import NotificationListItem from './NotificationListItem';
import ListSubheader from '@mui/material/ListSubheader';


const NotificationList = (props) => {
    const { listtitle, list } = props;

    //console.log(`list title: ${JSON.stringify(listtitle)}`);
    //console.log(`slide list: ${JSON.stringify(list[0])}`);

    return (
        <Box
            sx={{ width: '100%', height: 770, maxWidth: 360, bgcolor: '#42648b' }}
        >
            <List
                disablePadding
                sx={{
                    width: '100%',
                    maxWidth: 360,
                    bgcolor: '#42648b',
                    position: 'relative',
                    overflow: 'auto',
                    maxHeight: 770
                }}
            >
                <ListSubheader disableGutters={true} sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    bgcolor: '#42648b',
                    color: 'ffffff'
                }}>
                    <Box height={55} sx={{ display: 'flex', alignItems: 'center' }}>
                        <Typography variant='h6' >{`${listtitle}`}</Typography>
                    </Box>
                </ListSubheader>
                {list.map((notification) => <NotificationListItem key={notification.rank} notification={notification} />)}
            </List>
        </Box >
    );
}

export default NotificationList
