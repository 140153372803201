import React from 'react'
import { Paper, Box, Grid, Stack, Typography, Button, Checkbox, FormControlLabel } from '@mui/material';
import NavigateNextOutlinedIcon from '@mui/icons-material/NavigateNextOutlined';
import SectionMenu from '../navigation/SectionMenu';
import { Link } from 'react-router-dom';

const WelcomeMsg = () => {
    const accessLevel = 60;
    const fullname = 'Brother John Doe';
    const callingName = 'Letterwriter';
    const body1 = 'You are beginning your service in a great work! It is a joy and a blessing to enrich the lives of the incarcerated using the Gospel of Jesus Christ.'
    const body2 = `As a ${callingName}, you will be an instrument in the Lord's hands to instill hope, inspire faith and repentance in His Atonement, and cultivate self-worth.`
    const menu = [{
        "id": 6,
        "title": "Letterwriting",
        "accesslevel": 60,
        "links": [
            {
                "id": 33,
                "href": null,
                "label": "First Steps",
                "target": null,
                "rank": 1
            },
            {
                "id": 34,
                "href": null,
                "label": "Writing your first letter",
                "target": null,
                "rank": 2
            },
            {
                "id": 35,
                "href": null,
                "label": "Do's and Dont's",
                "target": null,
                "rank": 3
            },
            {
                "id": 36,
                "href": null,
                "label": "Providing encouragement",
                "target": null,
                "rank": 4
            }
        ]
    }
    ]

    return (
        <>
            <section>
                <Paper elevation={3} sx={{ backgroundColor: '#0f3b5f', maxWidth: 395, marginRight: "15%", marginLeft: "15%" }}>
                    <Box mt={6} mb={6} sx={{
                        padding: 5,
                        pb: 2,
                        backgroundColor: '#eee',
                        borderRadius: '13px',
                        borderStyle: 'solid',
                        borderWidth: '2px'
                    }}>
                        <Stack spacing={2}>
                            <Typography variant="h6" gutterBottom sx={{ color: '#000', paddingBottom: 0 }}>
                                {`Welcome ${fullname}!`}
                            </Typography>
                            <Typography variant="subtext2" sx={{ color: '#000' }}>
                                {`${body1}`}
                            </Typography>
                            <Typography variant="subtext2" sx={{ color: '#000' }}>
                                {`${body2}`}
                            </Typography>
                            <Typography variant="subtext2" sx={{ color: '#000' }}>
                                {`Let's get started!`}
                            </Typography>
                        </Stack>
                        <Stack ml={2.5}>
                            {(menu) &&
                                menu.map((section) => {
                                    if (section.accesslevel >= accessLevel)
                                        return (
                                            <SectionMenu key={section.id} section={section} />
                                        )
                                })
                            }
                        </Stack>
                        <Box p={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: '#eee' }}>
                            <Stack direction='row' spacing={2}>
                                <FormControlLabel
                                    control={<Checkbox className={'control-light'} value="stop" color="secondary" sx={{
                                        "&.Mui-checked": {
                                            color: '#777'
                                        }
                                    }} />}
                                    label={<Typography variant={'footnote'} color='#777'>Do not show this again</Typography>}
                                />
                                {/*                                 <Button variant={'outlined'}
                                    component={Link} to={`/`}
                                    sx={{ color: '#333' }}>
                                    Continue
                                </Button> */}
                                <Button variant={'outlined'}
                                    component={Link} to={`/`}
                                    sx={{ color: '#333' }}>
                                    Skip<NavigateNextOutlinedIcon />
                                </Button>
                            </Stack>
                        </Box>

                    </Box>
                </Paper>
            </section>
        </>
    )
}

export default WelcomeMsg
