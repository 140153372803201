import { Box, Typography } from '@mui/material';
import React from 'react'
import AZCCBottomNavigator from './navigation/AZCCBottomNavigator';

const Footer = () => {
    const ITEM_HEIGHT = 36;
    const MOBILE_ITEM_HEIGHT = 48;

    const ITEM_PADDING_TOP = 8;
    const MENU_ITEMS = 3; // change this to your preference.
    const today = new Date();
    return (
        <>
            <Box sx={{ display: { xs: 'none', lg: 'block' } }}>
                <Typography align='center' paragraph={true}>
                    Copyright &copy; 2020-{today.getFullYear()}
                </Typography>
            </Box>
            <Box sx={{
                maxHeight: {
                    xs: 40,
                    lg: 8
                },
                display: { xs: 'block', lg: 'none' }
            }}>
                <AZCCBottomNavigator />
            </Box>
        </>
    )
}

export default Footer
