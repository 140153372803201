import { Box, Grid, Button, Paper } from '@mui/material';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { NativeSelect, InputLabel } from '@mui/material';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import DirectoryFilterPanel from './DirectoryFilterPanel';
import DirectoryContent from './DirectoryContent';
import { Stack } from '@mui/system';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import { useState, useEffect, useContext } from 'react'
import DataContext from '../context/DataContext';
import useAuth from '../hooks/useAuth';
import { fetchDirectory } from '../utils/http-common';
import { useQuery } from '@tanstack/react-query';
import BranchInfo from './directory/BranchInfo';
//import { Link } from "react-router-dom";

const DEBUG_ON = true;

const bkgSelectColor = '#aaa';
const bkgHoverColor = '#ccc';
const viewStyle = {
    menu: {
        "&& .Mui-selected": {
            backgroundColor: bkgSelectColor
        },
        "& .MuiMenuItem-root:hover": {
            backgroundColor: bkgHoverColor
        },
        "& .MuiList-root.Mui-selected, .MuiMenuItem-root.Mui-selected:hover": {
            backgroundColor: bkgHoverColor
        },
    },
    menuItem: {
        color: '#333',
        backgroundColor: 'white',
    }
}

function compareName(a, b) {
    if (a.attributes.name < b.attributes.name) {
        return -1;
    }
    if (a.attributes.name > b.attributes.name) {
        return 1;
    }
    return 0;
}
function compareOrg(a, b) {
    if (a.attributes.agentStake.data?.attributes.name < b.attributes.agentStake.data?.attributes.name) {
        return -1;
    }
    if (a.attributes.agentStake.data?.attributes.name > b.attributes.agentStake.data?.attributes.name) {
        return 1;
    }
    return 0;
}

function findBranch(id, branches) {
    for (let i = 0; i < branches.length; i++) {
        if (branches[i].id === id) {
            return branches[i];
        }
    }
}

export default function DirectoryList() {
    const auth = useAuth();
    const { directory, setDirectory, setFilteredDir, filteredDir, setSmallTitle, user, setBranch } = useContext(DataContext);
    const [list, setList] = useState('');
    const [sort, setSort] = useState('name');
    const title = 'Directory';
    const subtitle = 'Branch List';
    const orgtype = 'branch';
    const orgadmin = 'Branch and Stake Leaders';
    const commentversion = `Version: January 15, 2023.`
    const commentrequest = `Please send any corrections or updates to Derek Dietze, Clerk, Arizona Correctional Committee,
    derek.dietze@gmail.com, 480‐292‐4214`
    const { isLoading, isError, data } = useQuery(['directory'], () => fetchDirectory());
    if (isLoading) {
        return <h2>Loading...</h2>;
    }
    if (isError) {
        console.log('Error loading directory');
        return <></>;
    } else {
        setSmallTitle(title);
        const branchesArray = data?.data.data;
        DEBUG_ON && console.log(branchesArray);
        if (auth?.accessLevel && auth.accessLevel > 30) {
            //console.log(`User: ${JSON.stringify(user)}`);
            //console.log(`Branch id: ${user.attributes.branch.data?.id}`);
            const branch = findBranch(user.attributes.branch.data?.id, branchesArray);
            setBranch(branch);
            //console.log(`Branch: ${JSON.stringify(branch)}`);
            return <BranchInfo />;
        }
        if (sort === 'name') branchesArray.sort(compareName);
        if (sort === 'org') branchesArray.sort(compareOrg);

        setDirectory(branchesArray);
        if (filteredDir === null || filteredDir.length === 0) setFilteredDir(directory);
        /* setFilteredDir(branchesArray); */
        console.log('directory loaded');

    }

    const directoryViewListArray = [
        'Branches',
        'Branch Presidents',
        'Agent Stake Presidents',
        'Correctional Facilities'
    ];

    const handleListChange = (event) => {
        setList(event.target.value);
    };

    const handleSortChange = (event) => {
        setSort(event.target.value);
        if (event.target.value === 'name') setFilteredDir(filteredDir.sort(compareName));
        if (event.target.value === 'org') setFilteredDir(filteredDir.sort(compareOrg));
        console.log(`sorting...${event.target.value}`)
    };
    //console.log(`branches: ${branchesArray.length}`)
    //console.log(`branches2: ${directory.length}`)
    return (
        <Grid container mt={8} >
            <Box mt={1} sx={{ p: 1, display: { xs: 'none', lg: 'block' } }}>
                <Grid container >
                    <Grid item xs={3} sx={{ minWidth: '275px' }} >
                        {/*                     <Box className='WithRoundBorder'
                        sx={{
                            '& > :not(style)': {
                                m: 1,
                                width: 428,
                                height: 118,
                            },
                        }}
                        >   */}
                        <Stack
                            sx={{
                                margin: 2,
                                padding: 2,
                                border: '1px solid white',
                                borderRadius: '12px',
                            }}>
                            <Typography variant='h6'>{orgadmin}:</Typography>
                            <Typography variant='body1'><Button ><MailOutlinedIcon />Contact us </Button> with any changes within your {orgtype}.</Typography>
                        </Stack>
                    </Grid>
                    <Grid item xs={9} /* sx={{ minWidth: '660px' }} */ >
                        <Grid container >
                            <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant='h3' >{title}</Typography>
                            </Grid>
                            <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
                                <Stack direction="row" px={2} spacing={2}>
                                    <FormControl variant='standard' sx={{ ml: 5, minWidth: 300 }}>
                                        <InputLabel id="suffix-simple-select-label" sx={{ color: '#fff' }}>View</InputLabel>
                                        <Select
                                            labelId="suffix-simple-select-label"
                                            id="view"
                                            value={'Branches'}
                                            label="View"
                                            onChange={handleListChange}
                                            style={{ backgroundColor: "white" }}
                                            MenuProps={{ sx: viewStyle.menu }}
                                        >
                                            {directoryViewListArray.map((view, i) => (
                                                <MenuItem key={i} value={view}
                                                    sx={viewStyle.menuItem}>
                                                    {view}
                                                </MenuItem>
                                            ))}
                                        </Select>
                                    </FormControl>
                                    {/* <FormControl variant="standard" sx={{ ml: 5, minWidth: 300 }}>
                                        <InputLabel id="demo-simple-select-standard-label" sx={{ color: 'white' }}>View</InputLabel>
                                        <NativeSelect

                                            defaultValue={10}
                                            inputProps={{
                                                name: 'view',
                                                id: 'uncontrolled-native',
                                            }}
                                            sx={{ color: 'white', backgroundColor: '#fff' }}
                                        >
                                            <option value={10}>Branch List</option>
                                            <option value={20}>Leader List</option>
                                            <option value={30}>Correctional Facility List</option>
                                        </NativeSelect>
                                    </FormControl> */}
                                    <Stack direction='row' pt={2} pl={2} spacing={1} >
                                        {/*  <Typography variant='subtitle2'>{subtitle}</Typography> */}
                                        <Typography variant='subtitle2'>Sort by</Typography>
                                        <FormControl>
                                            {/* <FormLabel id="sort-controlled-radio-buttons-group" labelPlacement="start"></FormLabel> */}
                                            <RadioGroup
                                                row
                                                aria-labelledby="sort-controlled-radio-buttons-group"
                                                name="controlled-radio-buttons-group"
                                                value={sort}
                                                onChange={handleSortChange}
                                            >
                                                <FormControlLabel value="name" control={<Radio />} label="Branch" />
                                                <FormControlLabel value="org" control={<Radio />} label="Agent Stake" />
                                            </RadioGroup>
                                        </FormControl>
                                    </Stack>
                                </Stack>
                            </Grid>
                            {/* <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant='body' color="common.white" sx={{ mt: 2 }}>{commentversion}</Typography>
                            </Grid> */}
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
            <Box sx={{ display: { xs: 'block', lg: 'none' } }}>
                <Grid item xs={12} /* sx={{ minWidth: '960px' }} */>
                    <Grid container >
                        {/* <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant='h5' >{title}</Typography>
                            </Grid> */}
                        <Grid item xs={0} md={3}>
                            <Stack direction='row' spacing={1}>
                                <DirectoryFilterPanel list={directory} />
                                <Typography mt={0.5} variant='subtitle3'>{subtitle}</Typography>
                            </Stack>
                        </Grid>
                        <Stack direction="row" px={1} spacing={1.8}>
                            <Typography pt={0.5} variant='subtitle3'>Sort by</Typography>
                            <FormControl>
                                {/* <FormLabel id="sort-controlled-radio-buttons-group" labelPlacement="start"></FormLabel> */}
                                <RadioGroup
                                    row
                                    aria-labelledby="sort-controlled-radio-buttons-group"
                                    name="controlled-radio-buttons-group"
                                    value={sort}
                                    onChange={handleSortChange}
                                >
                                    <FormControlLabel value="name" control={<Radio />} label="Branch" />
                                    <FormControlLabel value="org" control={<Radio />} label="Agent Stake" />
                                </RadioGroup>
                            </FormControl>
                        </Stack>
                        {/* <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                                <Typography variant='body' color="common.white" sx={{ mt: 0 }}>{commentversion}</Typography>
                            </Grid> */}
                    </Grid>
                </Grid>
            </Box>
            <Box className='Content' sx={{ px: 4, display: { xs: 'none', lg: 'block' } }}>
                <Grid container spacing={2}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={0} md={3}>
                        <DirectoryFilterPanel list={directory} />
                    </Grid>
                    <Grid item xs={12} md={9}>
                        <DirectoryContent />
                    </Grid>
                    <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
                        <Typography variant='subtitle1' color="common.white" sx={{ mt: 3 }}>{commentrequest}</Typography>
                    </Grid>
                </Grid>
            </Box>
            <Box pb={7} sx={{ px: 1, display: { xs: 'block', lg: 'none' } }}>
                <Grid container className='TEst1' spacing={2}>
                    {/* <Grid item xs={1}></Grid> */}
                    <Grid item xs={12} md={9}>
                        <DirectoryContent />
                    </Grid>
                    {/* <Grid item xs={12} display="flex" justifyContent="left" alignItems="center">
                            <Typography variant='subtitle1' color="common.white" sx={{ mt: 3 }}>{commentrequest}</Typography>
                            </Grid> */}
                </Grid>
            </Box>
        </Grid>
    );
}
