import { useState, useEffect, useContext } from 'react';
import useGetUser from '../hooks/useGetUser';
import { Grid, Box } from '@mui/material';
import Showcase from "./Showcase";
import Memo from "./MemoCard";
//import SimpleMenu from "./SimpleMenu";
//import Map from './Map';
//import TestMap from './TestMap';
import TestMap2 from './TestMap2';
import CardGridSingle from './CardGridSingle';
import DataContext from '../context/DataContext';
import useAuth from '../hooks/useAuth';
import SignIn from './authentication/SignIn';

const Home = () => {
    const { auth } = useAuth();
    const { setSmallTitle, user } = useContext(DataContext);
    console.log(`Accesslevel: ${auth?.accessLevel}`);
    //useGetUser(email);
    function PropChangeWatch({ a, b }) {
        useEffect(() => {
            console.log("value of 'a' changed to", a);
        }, [a]);
        return (
            <div>
                I've got 2 props: a={a} and b={b}
            </div>
        );
    }

    const [count1, setCount1] = useState(0);
    const [count2, setCount2] = useState(0);

    //const firstLogin = personAttr?.firstlogin;
    //const emailConfirmed = personAttr?.dateEmailConfirmed;
    console.log(auth);
    if (Object.keys(auth).length === 0) return <SignIn />
    else {
        setSmallTitle('');
        return (


            <Box mt={8.5} sx={{ maxHeight: { xs: 'auto', lg: 860 } }}>
                <Showcase />
                <CardGridSingle />
                {/*             <Memo memoid={3} />
            <Memo memoid={2} />
            <PropChangeWatch a={count1} b={count2} />
            <button onClick={() => setCount1(count1 + 1)}>Increment count1</button>
            <button onClick={() => setCount2(count2 + 1)}>Increment count2</button> */}

            </Box>

        )
    }
}

export default Home
