import { useState, useEffect, useContext } from 'react';
import IconButton from '@mui/material/IconButton';
import { Menu, MenuItem } from '@mui/material';
import AccountBoxIcon from '@mui/icons-material/AccountBox';
import useLogout from '../../hooks/useLogout';
import { CMSHOST } from '../../utils/http-common';
import useAuth from '../../hooks/useAuth';
import DataContext from '../../context/DataContext';
import { useNavigate, useLocation } from "react-router-dom";

const DEBUG_ON = true;

const ProfileMenu = () => {
    const { auth, setAuth } = useAuth();
    const { setAuthState, user, setUser } = useContext(DataContext);
    const [anchorElSub, setAnchorElSub] = useState(null);
    const [isShowSubmenu, setShowSubmenu] = useState(true);

    const logout = useLogout();
    const navigate = useNavigate();
    const location = useLocation();

    /*        if (isSuccess) {
                handleCloseSubMenu();
                navigate('/login', { state: { from: location }, replace: true });
            }
     */

    const signOut = async () => {
        await logout();
        navigate('/linkpage');
    }

    const handleSubmenuToggle = (event) => {
        setShowSubmenu(!isShowSubmenu);
        if (isShowSubmenu) setAnchorElSub(event.currentTarget)
    };

    const handleCloseSubMenu = () => {
        setAnchorElSub(null);
    };

    return (
        <>
            <IconButton variant='contained' onClick={handleSubmenuToggle}
                sx={{ position: "fixed", top: 10, right: 15, zIndex: 1 }}>
                <AccountBoxIcon fontSize='large' sx={{ color: "#FFFFFF" }} />
            </IconButton>
            <Menu
                id="account-menu"
                anchorEl={anchorElSub}
                open={Boolean(anchorElSub)}
                onClose={handleCloseSubMenu}
                MenuListProps={{
                    'aria-labelledby': 'basic-button',
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
            >
                <MenuItem onClick={handleCloseSubMenu}>Profile</MenuItem>
                <MenuItem onClick={handleCloseSubMenu}>My account</MenuItem>
                <MenuItem component="a" href={`${CMSHOST + '/admin/content-manager/collectionType/api::branch.branch?page=1&pageSize=10&sort=name:ASC'}`} target='_blank' onClick={handleCloseSubMenu}>Administration</MenuItem>
                <MenuItem onClick={signOut}>Logout</MenuItem>
            </Menu>
        </>
    )
}

export default ProfileMenu
