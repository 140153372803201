import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';

const Missing = () => {
    return (
        <Container maxWidth="md" sx={{ minHeight: '100vh' }} >
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant="h3" color="#ddd">Page Not Found</Typography>
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body1" color="#ddd">
                        Well, that's disappointing.
                    </Typography>
                    <Link to='/'>Visit Our Homepage</Link>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Missing
