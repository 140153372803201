import Header from './Header';
import Footer from './Footer';
import { Outlet } from 'react-router-dom';
import { Box } from '@mui/material';

const Layout = () => {
    return (
        <Box className="App" >
            <Header title='AZCC' />
            <Outlet />
            <Footer />
        </Box>
    )
}

export default Layout