import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query';
import axios from "axios";

//export const CMSHOST = 'http://192.168.1.62:1337';
//export const UIHOST = 'http://192.168.1.62:3210';

export const CMSHOST = 'https://cms.azcrm.org';
export const UIHOST = 'http://127.0.0.1:3210';


const apiClient = axios.create({
    baseURL: CMSHOST + '/api',
    headers: {
        Authorization: `Bearer ${process.env.REACT_APP_CMS_TOKEN}`,
        //'Content-type': "application/json"
    }
});

const registerPerson = (id, person) => {
    console.log(`put id: ${id}`);
    console.log(`put person: ${JSON.stringify(person)}`);
    return apiClient.put(`/people/${id}`, person)
}

export const useRegisterPerson = (id) => {
    const client = useQueryClient();
    return useMutation({
        mutationFn: (person) => registerPerson(id, person),
        onSuccess: (person) => {
            console.log('successfull person update');
            client.setQueryData(['personfromemail', id], person);
        },
        onError: () => {
            console.log('failed update of person');
        }
    })
}

export const fetchMenubar = () => {
    return apiClient.get("/menubar?populate=deep");
}

export const fetchMenus = () => {
    return apiClient.get("/menu-categories");
}

export const fetchSubmenu = (id) => {
    return apiClient.get(`/menu-categories/${id}?populate=*`);
}

export const fetchCard = (id) => {
    return apiClient.get(`/cards/${id}?populate=deep`);
}

export const fetchDirectory = () => {
    const data = apiClient.get(`/branches?populate=deep`);
    console.log("Getting directory data...")
    console.log(JSON.stringify(data));
    return data;
}

export const fetchCallingList = () => {
    const data = apiClient.get(`/callings`);
    console.log("Getting calling list...")
    return data;
}

export const fetchPersonWithEmail = (email) => {
    const data = apiClient.get(`/people?filters[email][$eq]=${email}&populate=*`)
    console.log(`getting person...${data?.data}`);
    return data;
}

export const useGetPersonByEmail = (email) => {
    return useQuery({
        queryKey: ['personfromemail'],
        queryFn: () => fetchPersonWithEmail(email),
        enabled: true,
    });
}

export const fetchTutorial = (id) => {
    return apiClient.get(`/tutorials/${id}?populate=deep`);
}

export const fetchCurriculaDocs = (id) => {
    return apiClient.get(`/curricula/${id}?populate=deep`);
}

export const fetchResourceDocs = (id) => {
    return apiClient.get(`/doc-resources/${id}?populate=deep`);
}

export const fetchNotifications = () => {
    return apiClient.get(`/notification-viewer?populate=deep`);
}

export const fetchMemos = () => {
    return apiClient.get("/memos");
}

export const fetchMemo = (memoid) => {
    const url = `/memos/${memoid}?populate=*`;
    console.log(`memo url: ${url}`)
    return apiClient.get(url);
}

export const fetchBranches = () => {
    return apiClient.get("/branches?populate=*");
}

export const fetchBranch = (branchid) => {
    const url = `/branches/${branchid}?populate=deep`;
    console.log(`branch url: ${url}`)
    return apiClient.get(url);
}

export const useGetBranchById = (id) => {
    return useQuery({
        queryKey: ['branchfromid'],
        queryFn: () => fetchBranch(id),
        enabled: true,
    });
}

export const fetchStakes = () => {
    return apiClient.get("/stakes?populate=*");
}

export const fetchStake = (stakeid) => {
    const url = `/stakes/${stakeid}?populate=*`;
    console.log(`stake url: ${url}`)
    return apiClient.get(url);
}

export default apiClient;
