import { useContext } from 'react'
import { Container, Grid, Box, Typography } from '@mui/material';
import DataContext from '../../context/DataContext';

const BranchInfo = (props) => {
    const { branch } = useContext(DataContext);
    if (!branch) return <></>;
    return (
        <Container maxWidth="lg">
            <Box mt={8.5} sx={{ backgroundColor: '#fff', p: 5 }}>
                <Grid container spacing={2}>
                    <Grid item xs={12} display="flex" justifyContent="center" alignItems="center">
                        <Typography variant='h4' sx={{ color: "black" }}>{branch.attributes.name}</Typography>
                    </Grid>
                    <Grid item xs={12} lg={4}>
                        <Grid container direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "110px" }}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' color="common.black">Branch Presidency:</Typography>
                            </Grid>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Typography variant='body' color="common.black">Pres: {branch.attributes.president.data?.attributes.fullname}, {branch.attributes.president.data?.attributes.phone}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body' color="common.black">1st: {branch.attributes.firstCounselor.data?.attributes.fullname}, {branch.attributes.firstCounselor.data?.attributes.phone}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body' color="common.black">2nd: {branch.attributes.secondCounselor.data?.attributes.fullname}, {branch.attributes.secondCounselor.data?.attributes.phone}</Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Box sx={{ display: { xs: 'block', lg: 'none' } }} >
                            <Grid container mt={2} direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "60px" }}>
                                <Grid item xs={12}>
                                    <Typography variant='subtitle1' color="common.black">Agent Stake:</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body' color="common.black">{branch.attributes.agentStake.data.attributes.name}</Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <Typography variant='body' color="common.black">President  - {branch.attributes.agentStake.data.attributes.president.data?.attributes.fullname}</Typography>
                                </Grid>
                            </Grid>
                        </Box>
                        <Grid container mt={2} direction="column" sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "60px" }}>
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' color="common.black">Supporting Stakes:</Typography>
                            </Grid>
                            <Grid container>
                                {branch.attributes.agentStake.data.attributes.supportingStakes.data.map((stake) => (
                                    <Grid key={stake.id} item xs={12}>
                                        <Typography variant='body' color="common.black">{stake.attributes.name}</Typography>
                                    </Grid>
                                ))
                                }
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12} lg={8}>
                        <Grid container sx={{ padding: '6px', border: '1px solid green', borderRadius: '13px', minHeight: "110px" }} >
                            <Grid item xs={12}>
                                <Typography variant='subtitle2' color="common.black">Correctional Facilities:</Typography>
                            </Grid>
                            {branch.attributes.correctionFacilities.data.map((facility) => (
                                <Box key={facility.id}>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start", backgroundColor: "#ddd" }}>
                                        <Typography variant='subtitle2' color="common.black">{facility.attributes.name} - {facility.attributes.address}, {facility.attributes.city}, AZ {facility.attributes.zipcode}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Typography variant='body' color="common.black">Description: {facility.attributes.description}</Typography>
                                    </Grid>
                                    <Grid item xs={12} sx={{ display: "flex", justifyContent: "flex-start" }}>
                                        <Typography variant='body' color="common.black">Housing Type: {' '}</Typography>
                                        {facility.attributes.housing_types.data.map((housing, index) => (
                                            <Typography key={housing.id} variant='body' color="common.black">{
                                                (facility.attributes.housing_types.data).length > (index + 1) ? housing.attributes.name + "," : housing.attributes.name} </Typography>
                                        ))
                                        }
                                    </Grid>
                                </Box>
                            ))
                            }
                        </Grid>
                    </Grid>
                </Grid>
            </Box>
        </Container>
    )
}

export default BranchInfo
