import React from 'react'
import { Button, Typography } from '@mui/material';
import { Box, Card, CardMedia, CardContent, CardActions } from '@mui/material';
import { Link } from 'react-router-dom';
import { UIHOST } from '../../utils/http-common';

const MAX_WIDTH = 245;

const PushMenu = (props) => {
    return (
        <Box mt={3.5}>
            <Card sx={{ maxWidth: MAX_WIDTH }}>
                <CardMedia
                    component="img"
                    alt={props.push.image.data.attributes.alternativeText}
                    height={props.push.image.data.attributes.formats.thumbnail.height}
                    image={UIHOST + props.push.image.data.attributes.formats.thumbnail.url}
                    sx={{ padding: "1em 0em 0 0em", objectFit: "contain" }}
                />
                <CardContent>
                    <Typography variant="h6" component="div" color='black' mt={1} mb={-3} sx={{ p: 0 }}>
                        {props.push.title}
                    </Typography>
                </CardContent>
                <CardActions>
                    <Button variant='outlined' component={Link} size="small" to={props.push.link.href} color='primary' sx={{ color: 'black' }}>{props.push.link.label}</Button>
                </CardActions>
            </Card>
        </Box>
    )
}

export default PushMenu
