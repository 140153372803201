import { useContext, useEffect } from "react";
import MenuImageGrid from "./navigation/MenuImageGrid";
import DataContext from "../context/DataContext";

export default function LessonList() {
    const { setSmallTitle } = useContext(DataContext);
    // TODO: replace with a fetch to the datastore once that is figured out.
    useEffect(() => {
        setSmallTitle('Learn Center');
    }, []);

    let imageList = [
        {
            src: '/man-presses-start2.jpg',
            title: 'Getting Started',
            redirectUrl: '/lessonview/3',
        },
        {
            src: '/images/jesus-teaching.jpg',
            title: 'What Should I Focus On?',
            redirectUrl: '/lessonview/4',
        },
        {
            src: '/administration1.png',
            title: 'Administration',
            redirectUrl: 'http://www.churchofjesuschrist.org',
        },
        {
            src: '/images/policy.jpg',
            title: 'Policy',
            redirectUrl: 'http://www.google.com',
        },
        {
            src: '/images/glossary-book-in-hand.jpg',
            title: 'Glossary',
            redirectUrl: '/docview/11',
        },
        {
            src: '/scriptures-study3.jpg',
            title: 'Instruction',
            redirectUrl: 'http://www.google.com',
        },
        {
            src: '/transition-man-fence2.png',
            title: 'Transition / Reentry',
            redirectUrl: 'http://www.google.com',
        },
        {
            src: '/mentor-letterwrite.png',
            title: 'Mentor / Letterwritting',
            redirectUrl: 'http://www.google.com',
        },
        {
            src: '/12-steps.png',
            title: 'Addiction Recovery',
            redirectUrl: 'http://www.google.com',
        }
    ];

    return (
        <MenuImageGrid imageList={imageList} />
    );
}