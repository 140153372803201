import { AppBar, Toolbar, Container, Box } from '@mui/material';
//import Menus from './Menus';
import Menubar from './navigation/Menubar';
//import { styled } from '@mui/material';
//import { Paper } from '@mui/material';
//import DataContext from '../context/DataContext';

/* const StyledPaper = styled(Paper, {
  name: "StyledPaper",
  slot: "Wrapper"
})({
  color: "#6B8068",
  //backgroundImage: `url("https://picsum.photos/200/300")`,
  backgroundColor: "#0e3b5f",
  margin: "auto",
  borderRadius: 2,
  height: 500,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  ".MuiButton-root": { color: "#FF0000" }
});

 */
//const pages = ['Branches', 'Learning Center', 'Official Links', 'Re-entry Resources', 'Refer an Inmate'];
//const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];

const Header = () => {
  return (
    <>
      <Box className="Top-header" >
        <AppBar position="fixed" >
          <Container maxWidth="xl" display='flex' className="App-header">
            <Toolbar disableGutters>
              {/* <Menus key='main-menu' /> */}
              <Menubar />
            </Toolbar>
            {/*        <StyledPaper>
          <Button variant="outlined" color="secondary">Text</Button>
        </StyledPaper> */}
          </Container>
        </AppBar>
      </Box>
      {/* <Box className='Hidden' height='0px' sx={{ display: { xs: 'block', lg: 'none' } }}></Box> */}
    </>
  );
};

export default Header;
