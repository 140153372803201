import { useState, useRef, useEffect, useContext } from 'react';
import { Container, Grid, Paper, Box, Stack, Typography, TextField, Button } from '@mui/material';
import Avatar from '@mui/material/Avatar';
import CssBaseline from '@mui/material/CssBaseline';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl, { useFormControl } from "@mui/material/FormControl";
import { FormLabel, FormHelperText, Checkbox, InputLabel, OutlinedInput, InputAdornment } from '@mui/material';
import { IconButton } from "@mui/material";
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import axios from '../../api/axios';
import useAuth from '../../hooks/useAuth';
import DataContext from '../../context/DataContext';
import { Link, useNavigate, useLocation } from 'react-router-dom';

const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;
const LOGIN_URL = '/auth';

const styles = {
    email: {
        color: '#000',
        "& .MuiFormHelperText-root": {
            color: 'red'
        },
    },
    password: { width: '35ch' }
}

const Login = () => {
    const { setAuthState, user, setUser } = useContext(DataContext);
    const { setAuth } = useAuth();

    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";

    const emailRef = useRef();
    const errRef = useRef();

    const [email, setEmail] = useState('');
    const [validEmail, setValidEmail] = useState(false);
    const [pwd, setPwd] = useState('');
    const [validPwd, setValidPwd] = useState(false);
    const [pwdFocus, setPwdFocus] = useState(false);
    const [showPwd, setShowPwd] = useState(false);
    const [errMsg, setErrMsg] = useState('');


    useEffect(() => {
        emailRef.current.focus();
    }, [])

    useEffect(() => {
        //const result = EMAIL_REGEX.test(email);
        //console.log(`Valid Email: ${result}`);
        //console.log(email);
        setValidEmail(EMAIL_REGEX.test(email));
    }, [email])

    useEffect(() => {
        setErrMsg('');
    }, [user, pwd])

    //password mgmt
    const handleClickShowPwd = () => setShowPwd((show) => !show);
    const handleMouseDownPwd = (event) => {
        event.preventDefault();
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        console.log({
            email: data.get('email'),
            password: data.get('password'),
        });
        console.log(`Email before fetch: ${email}`);
        try {
            console.log(JSON.stringify({ email, pwd }));
            const response = await axios.post(LOGIN_URL,
                JSON.stringify({ email, pwd }),
                {
                    withCredentials: false,
                    headers: { 'Content-Type': 'application/json' }
                }
            );
            console.log(JSON.stringify(response?.data));
            const accessToken = response?.data?.accessToken;
            const accessLevel = response?.data?.accessLevel;

            setAuth({ email, accessToken, accessLevel });

            setEmail('');
            setPwd('');
            navigate(from, { replace: true });
        } catch (err) {
            if (!err?.response) {
                setErrMsg('No Server Response');
            } else if (err.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (err.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(`Login Failed : ${err.message}`);
            }
            errRef.current.focus();
        }
        //refetch();
    };

    return (
        <>
            <Box
                sx={{
                    marginTop: { xs: -2, lg: -2 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Typography variant="h6" ref={errRef} className={errMsg ? "errmsg" : "offscreen"} aria-live="assertive">{errMsg}</Typography>
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" color="common.white">
                    Sign in
                </Typography>
                <Box component="form" p={3} onSubmit={handleSubmit} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                    <Stack spacing={2}>
                        <TextField
                            required
                            id="email"
                            name="email"
                            label="Email"
                            inputRef={emailRef}
                            fullWidth
                            size="small"
                            variant="outlined"
                            onChange={(e) => setEmail(e.target.value)}
                            error={!validEmail}
                            helperText={email !== '' && !validEmail ? "Invalid email address" : ""}
                            sx={styles.email}
                        />
                        <FormControl sx={styles.password} size="small" variant="outlined">
                            <InputLabel htmlFor="outlined-adornment-password">Password</InputLabel>
                            <OutlinedInput
                                id="password"
                                name="password"
                                type={showPwd ? 'text' : 'password'}
                                onChange={(e) => setPwd(e.target.value)}
                                onFocus={() => setPwdFocus(true)}
                                onBlur={() => setPwdFocus(false)}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label="toggle password visibility"
                                            onClick={handleClickShowPwd}
                                            onMouseDown={handleMouseDownPwd}
                                            edge="end"
                                        >
                                            {showPwd ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                                label="Password"
                            />
                        </FormControl>
                        {/* <FormControlLabel
                            control={<Checkbox className={'control-light'} value="remember" color="secondary" sx={{
                                "&.Mui-checked": {
                                    color: '#777'
                                }
                            }} />}
                            label={<Typography color='#777'>Remember me</Typography>}
                        /> */}
                        {/* <Grid container>
                            <Grid item> */}
                        <Stack direction={'row'} spacing={2} >
                            <Typography ml={1} variant='subtitle2' color='#777' display="inline">Need an account? </Typography>
                            <Link
                                component="button"
                                variant='subtitle2'
                                onClick={() => { { setAuthState('Register') } }}
                                color='primary'
                            >Register here</Link>
                        </Stack>
                        {/* <Link to='/register' variant="footnote" color='#f00'>
                                    {"Sign Up"}
                                </Link> */}
                        {/* </Grid>
                            <Grid item xs> */}
                        <Box pt={3}>
                            <Link
                                component="button"
                                variant="footnote"
                                onClick={() => { setAuthState('ForgotPwd') }}
                                color='#ff0000'>
                                Forgot password?
                            </Link>
                        </Box>
                        {/*  </Grid>
                        </Grid> */}
                        <Button
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}
                        >
                            Sign In
                        </Button>
                    </Stack>
                </Box>
            </Box>
        </>
    )
}

export default Login
