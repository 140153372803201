import { useContext } from 'react';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemText from '@mui/material/ListItemText';
import DataContext from '../../context/DataContext';

const DEBUG_ON = true;

const NotificationListItem = (notification) => {
    const notif = notification.notification
    //DEBUG_ON && console.log(`listitem: ${JSON.stringify(notif)}`);
    const index = notif.rank;
    const label = notif.memo.data.attributes.label !== null ?
        notif.memo.data.attributes.label :
        notif.memo.data.attributes.title;
    const { selectedNotificationIndex, setSelectedNotificationIndex, notificationRef } = useContext(DataContext);

    //DEBUG_ON && console.log(`Memoinfo: ${JSON.stringify(notification)}`)
    DEBUG_ON && console.log(`index: ${index}`);
    DEBUG_ON && console.log(`title: ${label}`);

    const handleListItemClick = (event, index) => {
        DEBUG_ON && console.log(`clicked: ${index}`);
        setSelectedNotificationIndex(index);
        try {
            notificationRef.current = index - 1;
        } catch (err) {

        }
    };

    return (
        <ListItem key={index} component="div" disablePadding>
            <ListItemButton
                selected={selectedNotificationIndex === index}
                onClick={(event) => handleListItemClick(event, index)}
            >
                <ListItemText primary={`${index}. ${label}`} />
            </ListItemButton>
        </ListItem>
    );
}

export default NotificationListItem
