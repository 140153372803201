import React from 'react'
import { Grid, Button, Typography, Paper, Container } from '@mui/material'
import { Box } from '@mui/system'
import EmbeddedVideo from '../memos/EmbeddedVideo';
import ImageCenter from '../memos/ImageCenter';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';
import { CMSHOST } from '../../utils/http-common';


const SmSlide = (props) => {
    //console.log(`Slide props: ${JSON.stringify(props.slide)}`);
    const memo = props.slide.memo.data.attributes;
    const imageLoc = memo.imageloc;
    const image = memo.image.data;
    const imageUrl = memo.image.data?.attributes.formats.small.url === undefined ? '' : `${CMSHOST}${memo.image.data?.attributes.formats.small.url}`;
    //console.log(imageUrl);
    const imageAlt = memo.image.data?.attributes.alternativeText;
    const imageHeight = memo.image.data?.attributes.formats.small.height;
    const imageWidth = memo.image.data?.attributes.formats.small.width;
    const backgroundImage = memo.backgroundimage.data;
    const backgroundImageUrl = memo.backgroundimage.data?.attributes.formats.small.url === undefined ? '' : `${CMSHOST}${memo.backgroundimage.data?.attributes.formats.small.url}`;
    //console.log(backgroundImageUrl);
    const backgroundImageAlt = memo.backgroundimage.data?.attributes.alternativeText;
    const backgroundImageHeight = memo.backgroundimage.data?.attributes.formats.large.height;
    const backgroundImageWidth = memo.backgroundimage.data?.attributes.formats.large.width;
    const body1 = memo.body1;
    const body2 = memo.body2;
    const embeddedVideoLoc = memo.embeddedvideoloc;
    const embeddedVideo = JSON.parse(memo.embeddedvideo);
    const embeddedVideoStrLoc = memo.embeddedvideostrloc;
    const embeddedVideoStr = memo.embeddedvideostr;
    let videohtml = null;
    let videoloc = 'Top';
    if (embeddedVideoStr === undefined) {
        videohtml = embeddedVideo?.rawData === undefined ? null : embeddedVideo?.rawData.html;
        videoloc = embeddedVideoLoc;
        //console.log(`embeddedVideo: ${JSON.stringify(embeddedVideo)}`);
    } else {
        videohtml = embeddedVideoStr;
        videoloc = embeddedVideoStrLoc;
    }
    //console.log(`embeddedVideo html: ${videohtml}`);


    //console.log();

    return (
        <Paper
            sx={{
                backgroundImage: `url(${backgroundImageUrl})`,
                backgroundRepeat: "no-repeat",
                backgroundColor: "white",
                backgroundPosition: "center",
                backgroundSize: "contain",
                height: '77svh',
                width: "96%",
                overflow: "hidden",
                overflowY: 'auto',
                color: memo.theme,
                paddingLeft: '8px',
                paddingRight: '8px'
            }}
        >
            <div>
                <Typography variant="title" mt={3} color="black" sx={{ lineHeight: '1.3' }} >{memo.title}</Typography>
            </div>
            {image !== null && imageLoc === 'Top' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Top' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
            {/* <Typography variant="h3" mt={3} >{props.slide.title}</Typography> */}
            {
                body1 === undefined ?
                    <></> :
                    <Typography variant="body" mt={2} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body1} /></Typography>
            }
            {image !== null && imageLoc === 'Center' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Center' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
            {
                body2 === undefined ?
                    <></> :
                    <Typography variant="body" mt={3} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body2} /></Typography>
            }
            {image !== null && imageLoc === 'Bottom' ? <ImageCenter
                height={imageHeight}
                width={imageWidth}
                maxHeight={imageHeight}
                maxWidth={imageWidth}
                alt={imageAlt}
                src={imageUrl}
            /> : <></>}
            {videohtml !== null && videoloc === 'Bottom' ?
                <EmbeddedVideo videohtml={videohtml} /> :
                <></>}
        </Paper>
    )

}

export default SmSlide
