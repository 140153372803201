import { useState, useRef, useEffect } from 'react';
import Map from 'ol/Map';
import View from 'ol/View';
import OSM from 'ol/source/OSM';
import GeoJSON from 'ol/format/GeoJSON';
import TileLayer from 'ol/layer/Tile';
import VectorLayer from 'ol/layer/Vector';
import VectorSource from 'ol/source/Vector';
import { Fill, Stroke, Style } from 'ol/style';
import 'ol/ol.css';
import azMapCenter from '../map/azMapCenter';
import { Container, Grid, Typography, Box } from '@mui/material';
import { gilbertsantandata, gilbertgatewaydata } from '../map/data/eymanmeadows';

let styles = {
    'MultiPolygon': new Style({
        stroke: new Stroke({
            color: 'blue',
            width: 2,
        }),
        fill: new Fill({
            color: 'rgba(0, 0, 255, 0.1)',
        }),
    }),
};

const eymanmeadows_geojson = { "type": "FeatureCollection", "features": [{ "type": "Feature", "properties": { "note": "Gilbert Gateway Stake", "stroke": "#525c51", "stroke-width": 4, "stroke-opacity": 1, "fill": "#8af4a5", "fill-opacity": 0.5 }, "geometry": { "coordinates": [[[-111.72122560377379, 33.314237536232994], [-111.72066851255863, 33.27757588907609], [-111.70106903073543, 33.27791460885996], [-111.69833421931872, 33.279057778429035], [-111.69580198652488, 33.27926947485386], [-111.69195299267852, 33.2782533273305], [-111.68612885725314, 33.27829566704638], [-111.68668594846781, 33.30124363253637], [-111.68734432899433, 33.302259512363506], [-111.68911689194995, 33.304418217709326], [-111.68871173470268, 33.30712710568113], [-111.69539682927795, 33.30721175707433], [-111.6956500525577, 33.31127492730732], [-111.69600456514861, 33.31296785902134], [-111.69797970672764, 33.31427985848866], [-111.72122560377379, 33.314237536232994]]], "type": "Polygon" } }, { "type": "Feature", "properties": { "note": "Gilbert San Tan Stake", "stroke": "#555555", "stroke-width": 4, "stroke-opacity": 1, "fill": "#28c342", "fill-opacity": 0.5 }, "geometry": { "coordinates": [[[-111.72079823917854, 33.27760672710565], [-111.72056331368687, 33.248386915396324], [-111.70323755866575, 33.24823956385055], [-111.69102143309172, 33.248927202274146], [-111.6857356095259, 33.24941836926419], [-111.68620546050956, 33.27824503403468], [-111.69196113505906, 33.27824503403468], [-111.69571994292805, 33.27922703558292], [-111.69830412333773, 33.27912883592529], [-111.70094703512065, 33.27799953192229], [-111.72079823917854, 33.27760672710565]]], "type": "Polygon" } }] };

const TestMap2 = () => {
    //const [map, setMap] = useState();
    const mapElement = useRef();
    //const mapRef = useRef();
    useEffect(() => {
        const view = new View({
            center: azMapCenter,
            zoom: 7.44,
            minZoom: 6.84,
            padding: [100, 30, 30, 30]
        });

        const tile1 = new TileLayer({
            source: new OSM(),
        });

        var vSource1 = new VectorSource({
            format: new GeoJSON(),
            features: new GeoJSON().readFeatures(gilbertsantandata,
                {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
        });

        var vSource2 = new VectorSource({
            format: new GeoJSON(),
            features: new GeoJSON().readFeatures(gilbertgatewaydata,
                {
                    dataProjection: 'EPSG:4326',
                    featureProjection: 'EPSG:3857'
                })
        });


        const vector1 = new VectorLayer({
            source: vSource1,
            style: new Style({
                fill: new Fill({
                    color: '#ef6c0050'
                }),
                stroke: new Stroke({
                    color: 'rgba(0,0,0,0.8)',
                    width: 1
                })
            })
            //style: styles.MultiPolygon
        })

        const vector2 = new VectorLayer({
            source: vSource2,
            style: new Style({
                fill: new Fill({
                    color: '#ffa73350'
                }),
                stroke: new Stroke({
                    color: 'rgba(0,0,0,0.8)',
                    width: 1
                })
            })
            //style: styles.MultiPolygon
        })

        const initialMap = new Map({
            target: 'map',
            //layers: [tile1],
            view: view,
        });
        initialMap.addLayer(tile1);
        initialMap.addLayer(vector1);
        initialMap.addLayer(vector2);
        //setMap(initialMap);
        mapElement.current = initialMap;
        console.log('Map is loading...');
    }, []);

    return (
        <Box sx={{ p: 5 }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Typography variant='h3' color="common.white" sx={{ mt: 2 }}>Map Directory Test</Typography>
                </Grid>
                <Grid item xs={8}>
                    <Container maxWidth='md' id='map' ref={mapElement} className="map-container" />
                </Grid>
                <Grid item xs={4}><h2>Labels</h2></Grid>
            </Grid>
        </Box>
    );
}

export default TestMap2
