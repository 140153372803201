import { useContext } from 'react';
import { Container, Grid, Box, Typography } from '@mui/material';
import { Link } from 'react-router-dom';
import DataContext from '../../context/DataContext';
import Login from './Login';
import Register from './Register';
import RegisterExists from './RegisterExists';
import RegisterFailed from './RegisterFailed';
import RegisterError from './RegisterError';
import RegisterSuccess from './RegisterSuccess';
import ForgotPwd from './ForgotPwd';


const EMAIL_REGEX = /^[A-z0-9._%+-]+@[A-z0-9.-]+\.[A-z]{2,}$/;
const LOGIN_URL = '/auth';

function Copyright(props) {
    return (
        <Typography variant="body2" color="white" align="center" {...props}>
            {'azcrm.org - Copyright © 2021-'}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}


export default function SignIn() {
    const { authState } = useContext(DataContext);
    const title = 'Arizona Correctional & Reentry Ministry';
    const purpose = `The Arizona Correctional & Reentry Ministry Committee is one of many faith and community organizations that work together to help those affected by incarceration. We follow the example of Jesus Christ by ministering to and caring for those who are in prison or jail and their successful re-entry back into society. This committee works to support the needs and resources of the many ministry branches throughout the state of Arizona.`;

    return (
        <>
            <Grid container sx={{ height: '88vh', mt: { xs: 0, md: 2 } }}>
                <Grid item xs={12} sx={{ display: 'flex', flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                    <Box sx={{ p: 2, mt: { xs: 0, md: 3 } }}>
                        <img src={'/images/acrm-title.png'}
                            style={{
                                display: 'block',
                                marginLeft: 'auto',
                                marginRight: 'auto',
                                width: '80%'
                            }} />
                        {/* <Typography sx={{ typography: { xs: 'h6', md: 'h4' } }}>{title}</Typography> */}
                    </Box>
                </Grid>
                <Grid item xs={12} md={7} sx={{ display: { xs: 'none', md: 'flex' }, flexDirection: "column", justifyContent: "left", alignItems: "center" }}>
                    <Box sx={{ px: 10, py: 10 }}>
                        <Typography variant='subtitle1' paragraph={true} sx={{ lineHeight: "36px" }}>{purpose}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} md={5} sx={{ display: { xs: 'flex', md: 'flex' }, flexDirection: "column", justifyContent: "left", alignItems: "center" }}>
                    <Container component="main" maxWidth="xs">
                        {
                            authState === 'Login' ? <Login /> :
                                authState === 'RegExists' ? <RegisterExists /> :
                                    authState === 'RegError' ? <RegisterError /> :
                                        authState === 'RegSuccess' ? <RegisterSuccess /> :
                                            authState === 'ForgotPwd' ? <ForgotPwd /> :
                                                <Register />}
                    </Container>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'none', sm: 'flex', md: 'none' }, flexDirection: "column", justifyContent: "left", alignItems: "center" }}>
                    <Box px={4} /* sx={{ px: 4 }} */>
                        <Typography variant='subtitle2' paragraph={true} sx={{ lineHeight: "24px" }}>{purpose}</Typography>
                    </Box>
                </Grid>
                <Grid item xs={12} sx={{ display: { xs: 'flex', sm: 'none' }, flexDirection: "column", justifyContent: "left", alignItems: "center" }}>
                    <Box px={4} /* sx={{ px: 4 }} */>
                        <Typography variant='body2' paragraph={true} sx={{ mt: 2, lineHeight: "16px" }}>{purpose}</Typography>
                    </Box>
                </Grid>
            </Grid>
            <Box>
                <Copyright sx={{ mt: { xs: 2, lg: 8 }, mb: 4 }} />
            </Box>
        </>
    );
}