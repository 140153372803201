export const gilbertgatewaydata = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "note": "Gilbert Gateway Stake",
                "stroke": "#525c51",
                "stroke-width": 4,
                "stroke-opacity": 1,
                "fill": "#8af4a5",
                "fill-opacity": 0.5
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -111.72122560377379,
                            33.314237536232994
                        ],
                        [
                            -111.72066851255863,
                            33.27757588907609
                        ],
                        [
                            -111.70106903073543,
                            33.27791460885996
                        ],
                        [
                            -111.69833421931872,
                            33.279057778429035
                        ],
                        [
                            -111.69580198652488,
                            33.27926947485386
                        ],
                        [
                            -111.69195299267852,
                            33.2782533273305
                        ],
                        [
                            -111.68612885725314,
                            33.27829566704638
                        ],
                        [
                            -111.68668594846781,
                            33.30124363253637
                        ],
                        [
                            -111.68734432899433,
                            33.302259512363506
                        ],
                        [
                            -111.68911689194995,
                            33.304418217709326
                        ],
                        [
                            -111.68871173470268,
                            33.30712710568113
                        ],
                        [
                            -111.69539682927795,
                            33.30721175707433
                        ],
                        [
                            -111.6956500525577,
                            33.31127492730732
                        ],
                        [
                            -111.69600456514861,
                            33.31296785902134
                        ],
                        [
                            -111.69797970672764,
                            33.31427985848866
                        ],
                        [
                            -111.72122560377379,
                            33.314237536232994
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        },
    ]
};

export const gilbertsantandata = {
    "type": "FeatureCollection",
    "features": [
        {
            "type": "Feature",
            "properties": {
                "note": "Gilbert San Tan Stake",
                "stroke": "#555555",
                "stroke-width": 4,
                "stroke-opacity": 1,
                "fill": "#28c342",
                "fill-opacity": 0.5
            },
            "geometry": {
                "coordinates": [
                    [
                        [
                            -111.72079823917854,
                            33.27760672710565
                        ],
                        [
                            -111.72063939238045,
                            33.23387928129709
                        ],
                        [
                            -111.71471534783417,
                            33.23351890553676
                        ],
                        [
                            -111.71042194842558,
                            33.23308448648308
                        ],
                        [
                            -111.70567207685625,
                            33.233795512415384
                        ],
                        [
                            -111.69003241007702,
                            33.234101456074455
                        ],
                        [
                            -111.68565953083271,
                            33.234592709950945
                        ],
                        [
                            -111.68620546050956,
                            33.27824503403468
                        ],
                        [
                            -111.69196113505906,
                            33.27824503403468
                        ],
                        [
                            -111.69571994292805,
                            33.27922703558292
                        ],
                        [
                            -111.69830412333773,
                            33.27912883592529
                        ],
                        [
                            -111.70094703512065,
                            33.27799953192229
                        ],
                        [
                            -111.72079823917854,
                            33.27760672710565
                        ]
                    ]
                ],
                "type": "Polygon"
            }
        }
    ]
};

