import { Grid, Box, Container, Typography } from '@mui/material';
import ReactMarkdown from 'react-markdown';
import gfm from 'remark-gfm';

const DEBUG_ON = false;

const Notification = (props) => {
    const notif = props.notification;
    DEBUG_ON && console.log(`Notification props: ${JSON.stringify(notif)}`);
    const memo = notif.memo.data.attributes;
    const body1 = memo.body1;
    DEBUG_ON && console.log(`subject: ${JSON.stringify(notif.subject)}`);
    DEBUG_ON && console.log(`author: ${JSON.stringify(notif.author)}`);
    DEBUG_ON && console.log(`date: ${JSON.stringify(notif.date)}`);
    //DEBUG_ON && console.log(`body: ${JSON.stringify(notif.memo.data.attributes.body1)}`);


    //console.log();

    return (
        <Container maxWidth={false} mb={10}
            sx={{
                maxWidth: '1000px',
                display: 'flex',
                backgroundColor: '#fff',
                flexDirection: 'row',
                height: 768,
                width: "100%",
                overflow: "hidden",
                overflowY: "scroll",
            }}
        >
            <Grid container mt={4} spacing={0} sx={{ display: 'flex', flexDirection: 'row', alignItems: 'top' }}>
                <Grid item xs={12} sx={{ maxHeight: '14px' }}>
                    <div>
                        <Typography variant="title" mt={3} color="black" >{notif.subject}</Typography>
                    </div>
                </Grid>
                <Grid item xs={12}>
                    {
                        body1 === undefined ?
                            <></> :
                            <Typography variant="body" mt={5} color="black" paragraph={true}><ReactMarkdown remarkPlugins={[gfm]} children={body1} /></Typography>
                    }
                </Grid>
                <Grid item xs={12} mt={5} >
                    <Grid /* xs={12}  */ container direction="column" >
                        <Box>
                            {/* <Button color="primary" variant="contained" className="CheckButton">
                                {props.slide.link_title}
                            </Button> */}
                        </Box>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default Notification
