import { useContext } from 'react'
import '@fontsource/lato/300.css';
import '@fontsource/lato/400.css';

import '@fontsource/lato/700.css';
import Carousel from 'react-material-ui-carousel'
import { Container, Grid, Button, Typography } from '@mui/material'
import { Box } from '@mui/system'
import { Link } from 'react-router-dom';
import useAuth from '../hooks/useAuth';

//import logo from '../logo-white_512.png';

function Showcase(props) {
  const { auth } = useAuth();
  var items = [
    {
      name: "Upcoming Conference",
      description: "Semi-annual conference November 2nd at 7PM",
      body: "Announcing our upcoming conference on November 2, 2023. Elder John Lewis will be presiding. Selected speakers from various branch leaders will inspire us in improving our prison ministry efforts!",
      image: `url("https://www.whamcast.com/assets/images/prisonbg1_mirror.jpg")`,
      color: "white",
      background_color: '#000',
      background_transparency: 0,
      link_title: "Details",
      link: `/page/59`
    },
    {
      name: "Giving Reentry Help",
      description: auth.accessLevel > 10 ? "How to help your inmate prepare." : "Getting started. The 3 key success factors.",
      image: `url("https://www.whamcast.com/assets/images/prisonbg2_fade.jpg")`,
      color: "white",
      background_color: '#000',
      background_transparency: 0,
      link_title: "Learn more",
      link: `/lesson`
    },
    {
      name: auth.accessLevel > 10 ? "Your Branch Addiction Recovery Services" : "Addiction Recovery",
      description: auth.accessLevel > 10 ? "Bringing the joy of healing and recovery" : "How to implement a 12-Step program",
      image: `url("https://www.whamcast.com/assets/images/prison12-steps_fade.jpg")`,
      color: '#0f3b5f',
      background_color: 'rgba(0,0,0,0)', //#B5E009',
      background_transparency: 1,
      link_title: "Learn more",
      link: `/`

    }
  ]

  return (
    <>
      <Box sx={{ width: '100%', display: { xs: 'flex', lg: 'none' } }}>
        <Container fixed maxWidth='lg'>
          <Carousel
            height={325}
            width={400}
            interval={6000}
            animation={'slide'}
            duration={1000}
            navButtonsAlwaysVisible={true}
            next={(next, active) => console.log(`we left ${active}, and are now at ${next}`)}
            prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
          >
            {
              items.map((item, i) => <SmItem key={i} item={item} />)
            }
          </Carousel>
        </Container>
      </Box>
      <Box sx={{ display: { xs: 'none', lg: 'flex' } }}>
        <Container fixed maxWidth='lg'>
          <Carousel
            height={400}
            width={1300}
            interval={6000}
            animation={'slide'}
            duration={1000}
            navButtonsAlwaysVisible={true}
            next={(next, active) => console.log(`we left ${active}, and are now at ${next}`)}
            prev={(prev, active) => console.log(`we left ${active}, and are now at ${prev}`)}
          >
            {
              items.map((item, i) => <Item key={i} item={item} />)
            }
          </Carousel>
        </Container>
      </Box>
    </>
  )
}

function Item(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        backgroundImage: props.item.image,
        backgroundRepeat: "no-repeat",
        //backgroundColor: "black",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: 500,
        width: "100%",
        color: props.item.color,

      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} md={6}></Grid>
        <Grid item xs={10} md={6} mt={4} pr={1}>
          <Grid /* xs={12}  */ container direction="column" >
            <Box height={300}>
              <Typography variant="h3" pt={1} mt={6} color={props.item.color}>{props.item.name}</Typography>
              <Typography variant="h5" pb={4} mt={7} paragraph={true} color={props.item.color}>{props.item.description}</Typography>
            </Box>
            <Box>
              <Button
                component={Link}
                to={props.item.link}
                color="primary"
                variant="contained"
                className="CheckButton">
                {props.item.link_title}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

function SmItem(props) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'row-reverse',
        backgroundImage: props.item.image,
        backgroundRepeat: "no-repeat",
        //backgroundColor: "black",
        backgroundPosition: "center",
        backgroundSize: "cover",
        height: 325,
        width: "100%",
        color: props.item.color,

      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={2} sm={4} lg={6}></Grid>
        <Grid item xs={10} sm={8} lg={6} mt={2} pr={1}>
          <Grid /* xs={12}  */ container direction="column" >
            <Box height={250}>
              <Typography variant="h5" mt={5} gutterBottom={true} color={props.item.color}>{props.item.name}</Typography>
              <Box sx={{ backgroundColor: props.item.backgroundColor, opacity: props.item.background_transparency }}>
                <Typography variant="h6" pt={4} pb={1} mt={0} mr={5} paragraph={true} color={props.item.color}>{props.item.description}</Typography>
              </Box>
            </Box>
            <Box>
              <Button
                component={Link}
                to={props.item.link}
                color="primary"
                variant="contained"
                className="CheckButton">
                {props.item.link_title}
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  )
}

export default Showcase
