import { useQuery } from "@tanstack/react-query";
import useAxiosPrivate from "./useAxiosPrivate";

const DEBUG_ON = true;

const useGetNotifications = () => {
    const axiosPrivate = useAxiosPrivate();
    DEBUG_ON && console.log(`Getting notifications...`);
    return useQuery({
        queryKey: ['getnotifications'],
        queryFn: () => axiosPrivate.get(`/notifications`),
        enabled: true,
    });
}

export default useGetNotifications
