import React from 'react'
import parse from "html-react-parser";
import { Box } from '@mui/material'

const EmbeddedVideo = (props) => {
    return (
        <Box display="flex" justifyContent={'center'} alignItems={'center'} >
            {parse(props.videohtml)}
        </Box>
    )
}

export default EmbeddedVideo
