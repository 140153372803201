import { Grid, Box, Card, Typography, Container } from '@mui/material';
import PushCard from './PushCard';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import MailOutlinedIcon from '@mui/icons-material/MailOutlined';
import profile from '../profile-placeholder-silhouette.png';
import profile_kjh from '../profile-kjh.png';
import { CMSHOST } from '../utils/http-common';

const MAX_WIDTH = 300;
const CONTENT_HEIGHT = 140;

const CardGridSingle = () => {
    const imgHeight = '204';
    const image3url = CMSHOST + ''
    return (
        <Container maxWidth={false}
            sx={{ maxWidth: '1050px' }}>
            <Grid container spacing={2} mt={3}>
                <Grid container item xs={12} spacing={0} >
                    <Grid item xs={12} md={4}>
                        <PushCard
                            imgUrl={CMSHOST + '/uploads/medium_pexels_rodnae_productions_6069608_a6f229c43c.jpg'}
                            imgHeight='204'
                            imgAlt='Enriching the Incarcerated photo'
                            imgTitle='Enriching the Incarcerated'
                            title='Cultivating Deep Faith in God'
                            /* subtitle='A Lifeline of Hope and Connection' */
                            subtitle=''
                            body='The Church’s Prison Ministry impacts lives of the incarcerated bringing a second chance with a belief in Jesus Christ’s Atonement.'
                            actionLabel='Learn more'
                            link='/page/58'
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PushCard
                            imgUrl={CMSHOST + '/uploads/inmate_using_tablet_61899491fb.jpg'}
                            imgHeight='204'
                            imgAlt='Inmate Technology photo'
                            imgTitle='Inmate Technology'
                            title='What We Know About Inmate Technology'
                            subtitle=''
                            body='Tablets offer specialized content and services for inmates to use during their stay at correctional facilities (where available). They allow access to a...'
                            actionLabel='Details'
                            link='/page/57'
                        />
                    </Grid>
                    <Grid item xs={12} md={4}>
                        <PushCard
                            imgUrl={CMSHOST + '/uploads/fellowship1_8e473ccd11.jpg'}
                            imgHeight='204'
                            imgBackgroundColor='#fff'
                            imgAlt='How Love Scales Prison Walls photo'
                            imgTitle='How Love Scales Prison Walls'
                            title='How Love Scales Prison Walls'
                            subtitle=''
                            body='...Let the penitentiaries be turned into seminaries of learning, where intelligence, like the angels of heaven, would banish such fragments of barbarism.” —Joseph Smith, Jr.'
                            actionLabel='Read'
                            link='/page/56'
                        />
                    </Grid>
                    <Grid item pb={4} xs={12} md={0}>

                    </Grid>
                </Grid>
            </Grid>
        </Container>
    )
}

export default CardGridSingle
