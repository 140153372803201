import { useContext } from 'react';
import Avatar from '@mui/material/Avatar';
import AppRegistrationOutlinedIcon from '@mui/icons-material/AppRegistrationOutlined';
import { Box, Stack, Typography, Button } from '@mui/material'
import DataContext from "../../context/DataContext";

const RegisterError = () => {
    const { setAuthState, errorMsg } = useContext(DataContext);

    return (
        <>
            <Box
                sx={{
                    marginTop: { xs: -2, lg: 8 },
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <Avatar sx={{ m: 1, bgcolor: 'secondary.main' }}>
                    <AppRegistrationOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5" color="common.white">
                    Registration Failed
                </Typography>
                <Box component="form" p={3} noValidate sx={{ mt: 1, backgroundColor: '#fff' }}>
                    <Stack spacing={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <Box minHeight={180} p={2} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', backgroundColor: '#fff' }}>
                            <Stack direction={'column'}>
                                <img src={'/file-corrupt.jpg'} style={{ borderRadius: "3px", width: 170, marginLeft: '60px' }} />
                                <Typography variant="h6" color='primary'>
                                    {errorMsg}
                                </Typography>
                            </Stack>
                        </Box>
                        <Box spacing={4} sx={{ display: 'flow', justifyContent: 'center', alignContent: 'center' }}>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Register') } }}
                                sx={{ mt: 3, mb: 2 }}
                            >
                                Retry
                            </Button>
                            <Button
                                variant="contained"
                                onClick={() => { { setAuthState('Login') } }}
                                sx={{ mt: 3, ml: 6, mb: 2 }}
                            >
                                Cancel
                            </Button>
                        </Box>
                    </Stack>
                </Box>
            </Box>
            <Box p={3} sx={{ display: 'flex', justifyContent: 'center', alignContent: 'center', backgroundColor: '#0f456f' }}>
            </Box>
        </>
    )
}

export default RegisterError