import React from 'react'
import { MenuItem, ListItemText, Typography } from '@mui/material'
import { Link } from 'react-router-dom'

const DEBUG_ON = true;

const SectionMenuItem = (props) => {
    DEBUG_ON && console.log(`section menu item props: ${JSON.stringify(props)}`);
    return (
        <MenuItem component={Link} to={props.link.href} sx={{ backgroundColor: "white" }}>
            <ListItemText disableTypography
                primary={<Typography
                    variant="body2"
                    color='black'
                    sx={{ wordBreak: 'break-word' }}>
                    {props.link.label}
                </Typography>}
            />
        </MenuItem>
    )
}

export default SectionMenuItem
