import DataContext from '../../context/DataContext'
import BranchInfo from './BranchInfo'
import { useGetBranchById } from '../../utils/http-common'
import { useContext } from 'react'
import { useParams } from 'react-router-dom'

const Branch = () => {
    const { setBranch } = useContext(DataContext);
    const { id } = useParams();

    const { isInitialLoading, isError, data, error, refetch, isFetching } = useGetBranchById(id);
    if (isInitialLoading) {
        console.log(`Loading ${id}'s data...`)
        return <></>
    }
    if (isError) {
        console.log(`Error loading person ${error}`);
        return <></>;
    } else {
        console.log(JSON.stringify(data?.data.data))
        setBranch(data?.data.data);
        return (<BranchInfo />)
    }
}

export default Branch
